import styled from '@emotion/styled';
import React from 'react';


import { IUserSignup } from 'store/user-profile/reducer';
import SignupPromo from './SignupPromo';
import SignupCard from './SignupCard';

interface IProps {
  signups: IUserSignup[];
}

const SignupsWrapper = styled.div`
padding: 0 16px;
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 15px;

@media (max-width: 800px) {
grid-template-columns: 1fr;

}
`


export const SignupsTable: React.FC<IProps> = ({ signups }) => (
  <div>
    <SignupsWrapper>
        {signups.map((signup, i) => (
          <SignupCard key={signup.wave_time} signup={signup} order={i + 1} />
        ))}
      </SignupsWrapper>
    <SignupPromo />
  </div>
);
