/**
 * Joins array using ',' and using separator for last item.
 * @param arr Array for strings to join together
 * @param separator string to be placed with last item
 */
export function formattedJoinArray<T extends Array<string>>(arr: T, separator = 'and'): string {
  if (arr.length === 1) {
    return arr[0];
  }

  if (arr.length === 2) {
    return arr.join(` ${separator} `);
  }

  return `${arr.slice(0, -1).join(', ')} ${separator} ${arr.slice(-1)}`;
}
