import { GroupActionsEnum } from './actions';

export interface ISignup {
  id: number;
  userId: string;
  username: string;
  email: string;
  photo: string;
  leader: number;
  status: number;
  codeId: number;
  codeTitle: string;
  codeDiscount: number;
  codeQuota: false;
  data: true;
}

export interface IGroupParticipant {
  id: number;
  user_id: number;
  name: string;
  email: string;
  is_captain: boolean;
  status: boolean;
  payment_id: number;
  avatar?: string;
}

export interface IGroup {
  id: number;
  verifyCode: string;
  created: number;
  name: string;
  wave_id: number;
  wave_time: string;
  distance: number;
  category_id: number;
  category_name: string;
  city_name: string;
  event_name: string;
  event_date: string;
  signups: IGroupParticipant[];
  is_kids: boolean;
}

export interface IGroupState {
  [key: number]: IGroup;
}

const initialState: IGroupState = {};

export function groupReducer(state = initialState, action) {
  switch (action.type) {
    case GroupActionsEnum.FETCH_GROUP_DATA_SUCCESS: {
      const { payload } = action;

      return {
        ...state,
        [payload.data.id]: {
          ...payload.data
        }
      };
    }

    default:
      return state;
  }
}
