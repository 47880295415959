import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import CardText from 'material-ui/Card/CardText';
import { Link } from 'react-router-dom';

const SignupPromo = () => (
  <CardText className="info-signups-info">
    <Link to="/">
      <div className="info-signups-cta">
        <div className="info-signups-cta-icons">
          <FontIcon className="material-icons" color="#FFFFFF">
            chevron_right
          </FontIcon>
          <FontIcon className="material-icons" color="#FFFFFF">
            chevron_right
          </FontIcon>
        </div>
        <div>
          <h3>Kolejne wydarzenia czekają na Ciebie!</h3>
          <h4>Podejmij wyzwanie i zarezerwuj udział już teraz!</h4>
        </div>
        <div className="info-signups-cta-icons">
          <FontIcon className="material-icons" color="#FFFFFF">
            chevron_left
          </FontIcon>
          <FontIcon className="material-icons" color="#FFFFFF">
            chevron_left
          </FontIcon>
        </div>
      </div>
    </Link>
  </CardText>
);

export default SignupPromo;
