import { isDev } from 'config';

export const trackEvent = (eventName, props) => {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({ event: eventName, ...props });
  window.fbq('track', eventName, {
    ...props
  });

  // window.ga('send', 'event', eventName, ...props);

  console.info('facebok', eventName, {
    ...props
  });
  console.info('analytics', 'event', eventName, JSON.stringify(props));

  const amplitude = window.amplitude.getInstance();

  amplitude.logEvent(eventName, props);
};

export const trackEcommerce = ({ price, coupon, id, city, distance, hour, seats }) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          revenue: price,
          coupon
        },
        products: [
          {
            id,
            price,
            brand: city,
            category: distance,
            variant: hour,
            quantity: seats,
            coupon
          }
        ]
      }
    }
  });
};

export function sendPageview(pageID) {
  window.fbq('track', 'PageView', { pageID, url: window.location.pathname });
  window.ga('send', 'pageview', window.location.pathname);
}

export function setUserProperties(userProperties) {
  const amplitude = window.amplitude.getInstance();
  amplitude.setUserProperties(userProperties);
}

export function trackEngagement(eventName, eventPlace, eventProperties) {
  const amplitude = window.amplitude.getInstance();
  amplitude.logEvent(`${eventPlace}: ${eventName}`, eventProperties);

  if (isDev) {
    console.log('[APP_EVENT]', `${eventPlace}: ${eventName}`, eventProperties ? JSON.stringify(eventProperties) : '');
  }
}

export function trackPayments(paymentId, total, runName, runId, price, quantity) {
  const dataLayer = window.dataLayer || [];

  dataLayer.push({
    ecommerce: {
      purchase: {
        actionField: {
          id: paymentId,
          affiliation: 'zapisy.survivalrace.pl',
          revenue: total,
          shipping: 0
        },

        products: [
          {
            name: runName,
            id: runId,
            price,
            quantity
          }
        ]
      }
    },
    event: 'transactionComplete'
  });
}
