import React from 'react';
import styled from '@emotion/styled';
import { LoaderCircle } from '../Loader/LoaderCircle';

export enum ButtonSize {
  Slim = 'slim',
  Large = 'large'
}

const getButtonSize = size => {
  if (size === ButtonSize.Slim) {
    return `
      min-width: 32px;
      min-height: 32px;
      line-height: 32px;

      font-size: 14px;
    `;
  }

  if (size === ButtonSize.Large) {
    return `
      min-width: 40px;
      min-height: 40px;
      line-height: 40px;
      font-size: 15px;
    `;
  }

  return `
    min-height: 36px;
    min-width: 36px;
    line-height: 36px;
    font-size: 15px;
  `;
};

interface IStyledButtonProps {
  fullWidth: boolean;
  primary: boolean;
  renderIcon: boolean;
  size?: ButtonSize;
  isText: boolean;
  loading?: boolean;
  isDestructive?: boolean;
}

const StyledButton = styled.button<IStyledButtonProps>`
  font-family: 'Video', sans-serif;

  position: relative;
  display: ${props => (props.fullWidth ? 'flex' : 'inline-flex')};
  width: ${props => (props.fullWidth ? '100%' : '')};
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.64, 0, 0.35, 1);
  border: 1px solid ${props => (props.primary ? '#f60' : '#bcc6d0')};
  color: ${props => (props.primary ? '#000' : 'rgba(66,77,87,1)')};
  font-weight: 600;
  padding: ${props => (!props.renderIcon ? '0 16px' : '0 16px 0 12px')};
  ${props => getButtonSize(props.size)} background-color: ${props => (props.primary ? '#f60' : '#fff')};

  ${props => `

    ${
      props.isText
        ? `
      background: transparent; 
      border-color: transparent; 
      color: #fff; `
        : ''
    }

    ${
      props.isText && props.loading
        ? `      
        color: transparent; 
      `
        : ''
    }

    ${props.isDestructive ? `background: #d64646; border-color: #d64646; color:#fff;` : ''}

  `}

  ${props =>
    props.loading
      ? `
      color: transparent;
      transition: all 0.2s cubic-bezier(0.64, 0, 0.35, 1); 
      pointer-events: none;
    `
      : ''}

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.8;
    outline: 0;
    box-shadow: 0 0 0 1px #427fe1;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }

  .icon {
    color: #4384f5;
    margin-right: 4px;
    display: flex;
  }
`;

const Loader = styled(LoaderCircle)`
  width: 20px;
  height: 20px;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export interface IProps {
  children?: React.ReactNode;
  id?: string;
  type?: 'button' | 'submit' | 'reset';
  primary?: boolean;
  destructive?: boolean;
  disabled?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  outline?: boolean;
  fullWidth?: boolean;
  icon?: any;
  submit?: boolean;
  accessibilityLabel?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  onFocus?(): void;
  onBlur?(): void;
  renderIcon?: string | React.ReactNode;
  textButton?: boolean;
}

export type Ref = HTMLButtonElement;

export const Button = React.forwardRef<Ref, IProps>((props, ref) => {
  const {
    children,
    primary,
    destructive,
    disabled,
    loading,
    size,
    outline,
    fullWidth,
    icon,
    submit,
    accessibilityLabel,
    ariaControls,
    ariaExpanded,
    onClick,
    onFocus,
    onBlur,
    textButton,
    renderIcon,
    ...rest
  } = props;
  const isDisabled = disabled;
  const type = submit ? 'submit' : 'button';

  return (
    <StyledButton
      ref={ref}
      primary={primary}
      type={type}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={isDisabled}
      aria-label={accessibilityLabel}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      role={loading ? 'alert' : undefined}
      aria-busy={loading ? true : undefined}
      fullWidth={fullWidth}
      size={size}
      renderIcon={!!renderIcon}
      loading={loading}
      isDestructive={destructive}
      isText={textButton}
      {...rest}
    >
      {renderIcon && <span className="icon">{renderIcon}</span>}
      {children}
      {loading && <Loader />}
    </StyledButton>
  );
});
