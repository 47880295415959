import React from 'react';
import { isEmpty } from 'lodash';
import Dialog from 'material-ui/Dialog';
import { ButtonGroup, Button } from 'components/Button';

class GroupChangeNameDialog extends React.Component {
  state = {
    name: '',
    nameMaxLength: 45
  };

  onNameChange = ev => {
    this.setState({ name: ev.target.value.substr(0, this.state.nameMaxLength) });
  };

  onSubmitDialog = () => {
    if (!isEmpty(this.state.name)) {
      this.props.onSubmitDialog(this.state.name);
      this.setState({ name: '' });
    }
  };

  render() {
    let customActions = [
      <ButtonGroup right>
        <Button onClick={this.props.onHideDialog}>Wróć</Button>
        <Button primary onClick={this.onSubmitDialog}>
          Zmień nazwę
        </Button>
      </ButtonGroup>
    ];

    const length = this.state.nameMaxLength - this.state.name.length;

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Zmień nazwę grupy"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p>Wprowadź poniżej nową nazwę grupy.</p>
        <div className="group-input">
          <label>Nazwa grupy</label>
          <em>
            Pozostało znaków <strong>{length}</strong>
          </em>
          <input
            type="text"
            placeholder="Wprowadź nową nazwę grupy"
            value={this.state.name}
            onChange={this.onNameChange}
          />
        </div>
      </Dialog>
    );
  }
}

export default GroupChangeNameDialog;
