import styled from '@emotion/styled';

interface ISelectableProps {
  icon?: boolean;
  selected: boolean;
  onClick?(): void;
}

export const Selectable = styled.div<ISelectableProps>`
  opacity: 0.7;
  transition: all 0.1s ease-in;
  cursor: pointer;
  position: relative;

  ${props => !props.icon && 'border: 3px solid #bcc6d0;'};

  &:hover {
    opacity: 0.95;
  }

  ${props =>
    props.selected
      ? `
        border-color: #f60;
        opacity: 1;
        transform: scale(1.2);

        @media (max-width: 800px) {
          transform: scale(1.05);
        }


        &::before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='%23000' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");            background-repeat: no-repeat;
            background-position: center;
            background-size: 75%;
            position: absolute;
            top: ${props.icon ? ' 0' : '-12.5px'};
            right: ${props.icon ? ' 0' : '-12.5px'};
            background-color: #f60;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

        }
    `
      : ''};
`;

export const EventCard = styled(Selectable)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 120px;
  max-width: 200px;
  width: 100%;

  background: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 600;

  padding: 12px;
  text-align: center;
  margin: 0 auto;
`;
