import { css } from '@emotion/react';

export const gridWrapper = css`
  display: flex;
  max-width: 1100px;
  margin: 0 auto;
`;

export const userProfileWraperStyles = css`
  flex: 0 0 300px;
  margin-right: 15px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const mainContentWrapperStyles = css`
  flex: 1 1 100%;
`;
