import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { SignupActions } from '../../../store/ui/signup/actions';
import { PlacesSelect } from '../../../components/Form/PlacesSelect';
import { ISignupState } from 'store/ui/signup/interfaces';
import { IStoreStateInterface } from 'store/reducers';
import { Api } from 'services';
import { debounce } from 'helpers/debounce';
import { SinglePrice } from './Components';

const StyledInput = styled('input')`
  padding-right: 0;
  font-size: 6rem;
  max-width: 220px;
  text-align: center;
  color: #424d57;
  margin-bottom: 16px;
`;

const PlacesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const StyledTextInput = styled(StyledInput)`
  font-size: 1rem;
  text-align: left;
  max-width: 100%;
  color: #333;
`;

const NameWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  text-align: left;

  label {
    font-size: 1rem;
    padding: 0 0 4px;
  }
  p {
    font-size: 14px;
    line-height: 1.3;
    opacity: 0.8;
  }
`;

interface IOwnProps {
  signup: ISignupState;
}
interface IStateProps {}

interface IDispatchProps {
  onChangePlaces(places: number): void;
  onChangeGroupname(newName: string): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const GroupMetadata: React.FC<IProps> = ({ onChangePlaces, onChangeGroupname, signup }) => {
  const [singlePrice, setPrice] = React.useState({ initialPrice: 0, finalPrice: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setError] = React.useState(false);

  React.useEffect(() => {
    onChangePlaces(signup.places);

    async function getSinglePrice() {
      const { result } = (await Api.signupApi.getSinglePrice(signup.event_category_id, signup.places)) as any;

      if (result) {
        setPrice(result.data);
        setIsLoading(false);
      }
    }

    getSinglePrice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRecalculateSinglePrice = React.useCallback(
    places => {
      async function getSinglePrice() {
        setIsLoading(true);

        const { result, error } = (await Api.signupApi.getSinglePrice(signup.event_category_id, places)) as any;

        if (result) {
          setPrice(result.data);
          setIsLoading(false);
        }

        if (error) {
          setError(true);
        }
      }

      getSinglePrice();
    },
    [setPrice, signup.event_category_id]
  );

  const debouncedChangePlaces = debounce(handleRecalculateSinglePrice, 500);

  return (
    <PlacesWrapper>
      <PlacesSelect
        value={signup.places}
        onChangeValue={places => {
          debouncedChangePlaces(places);
          onChangePlaces(places);
        }}
        maxValue={signup.wave.free_places >= 50 ? 50 : signup.wave.free_places}
      >
        <p>
          &nbsp;
          {!isLoading ? (
            <>
              Cena za osobę: <SinglePrice {...singlePrice} />{' '}
            </>
          ) : isError ? (
            'Możliwe, że miejsca na tym dystansie się skończyły. Napisz na info@survivalrace.pl by zapytać czy możemy powiększyć falę?'
          ) : (
            ''
          )}
        </p>
      </PlacesSelect>

      <NameWrapper>
        <label htmlFor="groupName">Nazwa grupy</label>
        <StyledTextInput
          type="text"
          id="groupName"
          value={signup.groupName}
          onChange={e => onChangeGroupname(e.target.value)}
          maxLength={45}
        />
        <p>
          Jeśli biegniesz solo wymyśl swój pseudonim, jak rezerwujesz więcej miejsc to będzie nazwa Waszej Hordy. Możesz
          zostawić to pole puste, bo nazwę możesz później edytować.
        </p>
      </NameWrapper>
    </PlacesWrapper>
  );
};

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => ({});

const mapDispatch = {
  onChangePlaces: SignupActions.places,
  onChangeGroupname: SignupActions.groupname
};

export default connect(
  mapState,
  mapDispatch
)(GroupMetadata);
