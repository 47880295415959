import React from 'react';

export const GroupSocialComponent = () => (
  <div className="w-full bg-srOrange text-white text-xs p-3 hover:opacity-75">
    <a
      className="text-white flex flex-row items-center justify-center"
      href="https://www.facebook.com/SurvivalRace/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <svg className="w-3 mr-4 text-white fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512">
        <path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229" />
      </svg>
      <div>Sprawdź kto z Twoich znajomych jest już zapisany! Dopisz się, by śledzić aktualności!</div>
    </a>
  </div>
);
