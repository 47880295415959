/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { css } from '@emotion/react';

const wrapper = css`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoadingState = () => (
  <div css={wrapper}>
    <CircularProgress mode="indeterminate" size={60} color="#f60" />
  </div>
);
