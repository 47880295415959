import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { ButtonGroup } from 'components/Button/ButtonGroup';
import { Button } from 'components/Button/Button';

import { SignupActions } from 'store/ui/signup/actions';
import { getIsCheckingCoupons } from 'store/ui/signup/selectors';
import { ISignupState, ICouponCode } from 'store/ui/signup/interfaces';

const Link = styled('span')`
  color: #f60;
  cursor: pointer;
`;

const StyledInput = styled('input')`
  color: #333;

  &::placeholder {
    color: #777;
  }
`;

const StyledWrapper = styled.div`
  margin-bottom: 16px;
`;

const StyledTable = styled('table')`
  width: 100%;
  margin-bottom: 16px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  input {
    flex: 3;
  }
`;

const CouponTip = styled.div`
  font-size: 13px;
`;

interface IProps {
  signup: ISignupState;
  isSending: boolean;
  handleAddCoupon(data: { codeName: string; event_id: number }): void;
}

interface IState {
  opened: boolean;
  coupon: string;
}

class Coupons extends React.Component<IProps, IState> {
  state = { opened: false, coupon: '' };

  handleOpenInput = () => {
    this.setState(prevState => ({ opened: !prevState.opened }));
  };

  handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ coupon: e.target.value });
  };

  renderCode = (code: ICouponCode, i) => {
    const { value, name, coupon_type } = code;
    if (this.props.signup.places < i + 1) return;

    return (
      <tr key={i}>
        <td>{i + 1}</td>
        <td>{name}</td>
        <td>{value && coupon_type === 'percent' ? `-${value}%` : `-${value} zł`}</td>
      </tr>
    );
  };

  renderTable = () => {
    return (
      <StyledTable>
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th>Lp.</th>
            <th>Kod</th>
            <th>Wysokość zniki</th>
          </tr>
        </thead>
        <tbody>{this.props.signup.codes.map(this.renderCode)}</tbody>
      </StyledTable>
    );
  };

  handleCouponCheck = e => {
    e.preventDefault();
    this.props.handleAddCoupon({ codeName: this.state.coupon, event_id: this.props.signup.event_id });
  };

  render() {
    const { signup, isSending } = this.props;
    return (
      <>
        {!!signup.codes.length ? this.renderTable() : false}

        <StyledWrapper>
          Masz kod rabatowy? <Link onClick={this.handleOpenInput}>Zrealizuj go tutaj</Link>
          {this.state.opened && (
            <>
              <form onSubmit={this.handleCouponCheck}>
                <StyledButtonGroup fullWidth>
                  <StyledInput
                    type="text"
                    onChange={this.handleCouponChange}
                    value={this.state.coupon}
                    placeholder="Wpisz kod kuponu"
                    autoFocus
                  />
                  <Button onClick={this.handleCouponCheck} loading={isSending} type="submit">
                    Sprawdź
                  </Button>
                </StyledButtonGroup>
              </form>
              <CouponTip>Masz więcej kuponów? Dodaj wszystkie rozdzielając je przecinkami!</CouponTip>
            </>
          )}
        </StyledWrapper>
      </>
    );
  }
}

const mapState = (state, ownProps) => ({
  events: state.event.events,
  isSending: getIsCheckingCoupons(state)
});

const mapDispatch = {
  handleAddCoupon: SignupActions.codeRequest
};

export default connect(
  mapState,
  mapDispatch
)(Coupons);
