import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import IconButton from 'material-ui/IconButton';
import CardText from 'material-ui/Card/CardText';

import { isEmpty, orderBy } from 'lodash';
import { getWave } from '../../store/events/selectors';
import { IWave, IEvent, ICategory } from 'store/events/interfaces';
import { IGroup, IGroupParticipant } from 'store/groups/reducer';
import { getEventById } from 'store/ui/signup/selectors';
import { IStoreStateInterface } from 'store/reducers';
import { IProfileState } from 'store/user-profile/reducer';
import { BuyAdditionalPlace } from './BuyAdditionalPlace';
import { KidsView } from './KidsView';
import PaymentModal from './PaymentModal';
import { Header, Subheader } from './styles';

import defaultAvatar from '../../assets/mr-blank.png';
import { GroupSocialComponent } from './GroupSocialComponent';

const StyledAvatar = styled.div`
  max-width: 50px;
  max-height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const GroupTableOverflow = styled.div`
  max-width: 100vw;
  overflow: auto;
  padding: 0 15px 20px;

  @media (max-width: 600px) {
    padding: 0;
  }
`;

interface IProps extends IOwnProps, IStateProps {}

interface IOwnProps {
  user: IProfileState;
  group: IGroup;
  isCaptain: boolean;

  onSignupChangeUser(userId: number): void;
  onSignupChangeGroup(groupId: string): void;
  onSignupChangeCaptain(newCapitain: string): void;
  onSignupDelete(id: string): void;
  onSignupSendInvite(): void;
  onSignupDeleteSelf(id: string): void;
  onGroupChallange(id: string): void;
}

interface IStateProps {
  wave: IWave;
  event: IEvent;
  category: ICategory;
}

interface IState {
  isModalShown: boolean;
  paymentId: number;
}

class GroupTable extends React.Component<IProps, IState> {
  state: IState = {
    isModalShown: false,
    paymentId: 0
  };

  renderSignup = (signup: IGroupParticipant, i) => {
    const user = !isEmpty(signup.email) ? signup : {};

    return (
      <tbody key={i}>
        <tr>
          <td>
            <span>{i + 1}</span>
          </td>
          <td>
            <StyledAvatar>
              <img src={signup.avatar || defaultAvatar} alt={signup.name} />
            </StyledAvatar>
          </td>
          <td>
            {!isEmpty(user) ? (
              <div className="group-table__user">
                <div>
                  <strong>{!isEmpty(signup.name) ? signup.name : signup.email}</strong>
                  <span>{!isEmpty(signup.name) ? signup.email : 'Uzupełnij dane!'}</span>
                  <small>ID płatności: {signup.payment_id}</small>
                </div>
              </div>
            ) : (
              <div className="group-table__user">
                <em>Miejsce wolne</em>
                <br />
                <div>
                  <small>ID płatności: {signup.payment_id}</small>
                </div>
              </div>
            )}
          </td>
          <td className="table-no-padding">
            {signup.status ? (
              <IconButton
                iconClassName="material-icons"
                tooltipPosition="top-center"
                iconStyle={styles.actionIconActive}
                tooltip="Zapis opłacony"
              >
                check_circle
              </IconButton>
            ) : (
              <IconButton
                iconClassName="material-icons"
                tooltipPosition="top-center"
                iconStyle={styles.actionIconInactive}
                tooltip="NIE OPŁACONO / jeśli już opłaciłeś, poczekaj 5 min i odśwież stronę"
              >
                cancel
              </IconButton>
            )}
          </td>
          <td className="table-no-padding">{this.renderActions(signup)}</td>
        </tr>
      </tbody>
    );
  };

  renderActions = (signup: IGroupParticipant) => {
    if (!signup) {
      return null;
    }

    const { user, group, isCaptain } = this.props;

    const paidParticipants = group.signups.reduce((acc, val) => (val.status ? acc + 1 : acc), 0);
    const isGroupActive = paidParticipants > 0;

    // Jeśli kapitan i uczestnik jest kapitanem
    if (isGroupActive && isCaptain && signup.user_id === user.id) {
      return (
        <div>
          <IconButton
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIconActive}
            tooltip="Kapitan grupy"
          >
            verified_user
          </IconButton>

          {group.signups.length < 2 ? (
            <IconButton
              onClick={this.props.onSignupChangeGroup.bind(null, signup.id)}
              iconClassName="material-icons"
              tooltipPosition="top-center"
              iconStyle={styles.actionIcon}
              tooltip="Przenieś się do innej grupy"
            >
              swap_vertical_circle
            </IconButton>
          ) : (
            false
          )}
          <IconButton
            onClick={this.props.onSignupChangeUser.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-left"
            iconStyle={styles.actionIcon}
            tooltip="Oddaj swój udział innemu użytkownikowi"
          >
            supervisor_account
          </IconButton>
        </div>
      );
    }

    // Jest uczestnik i kapitan
    if (!!signup.is_captain) {
      return (
        <div>
          <IconButton
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIconActive}
            tooltip="Kapitan grupy"
          >
            verified_user
          </IconButton>
        </div>
      );
    }
    // Jeśli kapitan i zapis
    if (isGroupActive && this.props.isCaptain && signup.user_id && signup.status) {
      return (
        <div>
          <IconButton
            onClick={this.props.onSignupChangeCaptain.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Mianuj nowego kapitana"
          >
            verified_user
          </IconButton>
          <IconButton
            onClick={this.props.onSignupDelete.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Zwolnij miejsce"
          >
            remove_circle_outline
          </IconButton>
        </div>
      );
    }

    // Jeśli kapitan i miejce wolne
    if (isGroupActive && this.props.isCaptain && !signup.user_id) {
      return (
        <div>
          <IconButton
            onClick={this.props.onSignupSendInvite}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Wyślij zaproszenie"
          >
            send
          </IconButton>
        </div>
      );
    }
    // Jeśli uczestnik i swój wpis i nieopłacony
    if (isGroupActive && !isCaptain && signup.user_id === user.id && !signup.status) {
      return (
        <div>
          <IconButton
            onClick={() => this.togglePaymentModal(signup.payment_id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Opłać udział"
          >
            attach_money
          </IconButton>
          <IconButton
            onClick={this.props.onSignupDeleteSelf.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Usuń rezerwację"
          >
            remove_circle_outline
          </IconButton>
        </div>
      );
    }

    // Jeśli uczestnik i swój wpis i opłacony
    if (isGroupActive && !this.props.isCaptain && signup.user_id === user.id && signup.status) {
      return (
        <div>
          <IconButton
            onClick={this.props.onSignupDeleteSelf.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Zwolnij miejsce"
          >
            remove_circle_outline
          </IconButton>
          <IconButton
            onClick={this.props.onSignupChangeGroup.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-center"
            iconStyle={styles.actionIcon}
            tooltip="Przenieś się do innej grupy"
          >
            swap_vertical_circle
          </IconButton>
          <IconButton
            onClick={this.props.onSignupChangeUser.bind(null, signup.id)}
            iconClassName="material-icons"
            tooltipPosition="top-left"
            iconStyle={styles.actionIcon}
            tooltip="Oddaj swój udział innemu użytkownikowi"
          >
            supervisor_account
          </IconButton>
        </div>
      );
    }
    // Jeśli pozostałe
    return;
  };

  renderGroupChallenge = () => (
    <div className="group__challenge" onClick={() => this.props.onGroupChallange}>
      <div className="group__challenge-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z" />
        </svg>
      </div>
      <div className="group__challenge-info">
        <h2>Rzuć komuś wyzwanie!</h2>
        <span>Wyślij „zaproszenie” do tej grupy na miejsce nieopłacone</span>
      </div>
    </div>
  );

  renderGroupTable = (group: IGroup) => {
    const paidParticipants = group.signups.reduce((acc, val) => (val.status ? acc + 1 : acc), 0);

    const isGroupActive = paidParticipants > 0;
    return (
      <GroupTableOverflow>
        <table className="group-table">
          <thead>
            <tr>
              <th>Lp.</th>
              <th></th>
              <th>Uczestnik</th>
              <th className="table-no-padding">Płatność</th>
              <th className="table-no-padding">Opcje</th>
            </tr>
          </thead>
          {orderBy(group.signups, ['is_captain', 'email'], ['desc', 'asc']).map(this.renderSignup)}
          {isGroupActive ? <BuyAdditionalPlace nextItemId={group.signups.length + 1} groupId={group.id} /> : null}
        </table>
      </GroupTableOverflow>
    );
  };

  togglePaymentModal = (paymentId = 0) => {
    this.setState(prevState => ({
      isModalShown: !prevState.isModalShown,
      paymentId
    }));
  };

  render() {
    const { group, category } = this.props;

    const { isModalShown, paymentId } = this.state;

    if (group.is_kids) {
      return <KidsView group={group} category={category} />;
    }

    return (
      <>
        {isModalShown && <PaymentModal paymentId={paymentId} show onHideDialog={this.togglePaymentModal} />}

        <Header>{group.name}</Header>
        <Subheader>Lista uczestników</Subheader>
        {!isEmpty(group) ? (
          this.renderGroupTable(group)
        ) : (
          <CardText>
            <p>Brak zapisów</p>
          </CardText>
        )}
        <GroupSocialComponent />
      </>
    );
  }
}

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => ({
  wave: getWave(state, ownProps.group.wave_id),
  event: getEventById(state, ownProps.group.category_id),
  category: {} as ICategory
});

export default connect(mapState)(GroupTable);

const styles = {
  actionIcon: {
    color: '#CCCCCC'
  },
  actionIconActive: {
    color: '#AADEAD'
  },
  actionIconInactive: {
    color: '#d64646'
  }
};
