import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';
import { Modal, ModalPortal, ModalContent, ModalFooter } from '../Modals';
import { PlacesSelect } from '../Form/PlacesSelect';
import { ButtonGroup, Button } from '../Button';
import { getGroupInfo } from '../../store/groups/selectors';
import { getWave, ISignupPrices, getEventPriceBySinglePrices } from '../../store/events/selectors';
import { SummaryTable, SummaryRow, ImportantRow } from '../summary/SummaryTable';
import { Strike } from 'routes/signup/components/Strike';
import { IStoreStateInterface } from 'store/reducers';
import { IWave } from 'store/events/interfaces';
import { IGroup } from 'store/groups/reducer';
import { Api } from 'services';
import { debounce } from 'helpers/debounce';
import { Loader } from 'components/Loader/Loader';

const PlacesWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WaveAvailablePlaces = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;


interface IStateProps {
  wave: IWave;
  group: IGroup;
}

interface IOwnProps {
  handleClose(): void;
  groupId: number;
}

const delay = (time = 700) => new Promise(resolve => setTimeout(resolve, time));

interface IProps extends IStateProps, IOwnProps {}

const UnconnectedAddMorePlacesModal: React.FC<IProps> = ({ wave, handleClose, groupId, group }) => {
  const [places, setPlaces] = React.useState(1);
  const [singlePrice, setPrice] = React.useState({ initialPrice: 0, finalPrice: 0 });
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getSinglePrice() {
      const { result } = (await Api.signupApi.getSinglePrice(group.category_id, places)) as any;

      if (result) {
        setPrice(result.data);
        setIsLoading(false);
      }
    }

    getSinglePrice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prices: ISignupPrices = getEventPriceBySinglePrices(singlePrice, places, []);

  const handleRecalculateSinglePrice = React.useCallback(
    places => {
      async function getSinglePrice() {
        setIsLoading(true);

        const { result } = (await Api.signupApi.getSinglePrice(group.category_id, places)) as any;

        if (result) {
          setPrice(result.data);
          await delay();
          setIsLoading(false);
        }
      }

      getSinglePrice();
    },
    [setPrice, group.category_id]
  );

  const debouncedChangePlaces = debounce(handleRecalculateSinglePrice, 500);

  const handleAddParticipants = React.useCallback(() => {
    async function fetchData() {
      const { result } = (await Api.signupApi.addNewParticipants(groupId, places)) as any;

      if (result && result.data) {
        window.location.assign(result.data.payment_url);
      }
    }

    fetchData();
  }, [groupId, places]);

  return (
    <ModalPortal>
      <Modal title="Wykup dodatkowe miejsca do grupy" width="500px" closeModal={handleClose}>
        <ModalContent>
          {wave.free_places > 0 ? (
            <>
              <WaveAvailablePlaces>Dostępne miejsca w fali: {wave.free_places}.</WaveAvailablePlaces>
              <PlacesWrapper>
                <PlacesSelect
                  value={places}
                  onChangeValue={places => {
                    setPlaces(places);
                    debouncedChangePlaces(places);
                  }}
                  minValue={1}
                  maxValue={wave.free_places >= 50 ? 50 : wave.free_places}
                />
              </PlacesWrapper>
            </>
          ) : (
            <div>W tej fali nie ma już więcej wolnych miejsc.</div>
          )}

          <SummaryTable>
            {isLoading ? (
              <Loader />
            ) : (
              <tbody>
                <SummaryRow
                  label={<span>Cena jednostkowa</span>}
                  description="Cena za jedno miejsce / powyżej 5 osób uwzględniająca potencjalne rabaty"
                >
                  {prices.initialSinglePrice !== prices.singlePrice && <Strike>{prices.initialSinglePrice} zł</Strike>}{' '}
                  {prices.singlePrice}&nbsp;zł
                </SummaryRow>
                <SummaryRow
                  label={<span>Cena sumaryczna</span>}
                  description="Podsumowanie kosztu wszystkich wybranych biletów"
                >
                  {prices.totalPrice}&nbsp;zł
                </SummaryRow>
                {places >= 5 ? (
                  <SummaryRow
                    label={<span>Rabat za ilość miejsc</span>}
                    description="Wysokość udzielonego rabatu, ze względu na wykupioną liczbę miejsc"
                  >
                    {prices.placeDiscount}&nbsp;zł
                  </SummaryRow>
                ) : null}

                <SummaryRow
                  label={<span>Opłata manipulacyjna 1,5%</span>}
                  description="Prowizje banków / obsługa płatności"
                >
                  {prices.manipilation}&nbsp;zł
                </SummaryRow>
                <SummaryRow
                  label={<ImportantRow>Kwota do zapłaty</ImportantRow>}
                  description="Pozostała kwota do zapłaty"
                >
                  <ImportantRow>{prices.toPay}&nbsp;zł</ImportantRow>
                </SummaryRow>
              </tbody>
            )}
          </SummaryTable>
        </ModalContent>
        <ModalFooter sticky>
          <ButtonGroup>
            <Button onClick={handleClose}>Anuluj</Button>
            <Button primary disabled={isLoading || wave.free_places === 0} onClick={handleAddParticipants}>
              Przejdź do płatności
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </ModalPortal>
  );
};

const mapStateToProps = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => {
  const group = getGroupInfo(state, ownProps.groupId);

  return {
    group,
    wave: getWave(state, group.wave_id) || ({} as IWave)
    // getPrice: (places: number) => getEventPrices(state, group.category_id, places, [])
  };
};

export const AddMorePlacesModal = connect(
  mapStateToProps,
  null
)(UnconnectedAddMorePlacesModal);
