import { createAction } from '../helpers';

export enum GroupActionsEnum {
  FETCH_GROUP_DATA_REQUEST = 'GROUPS/FETCH_GROUP_REQUEST',
  FETCH_GROUP_DATA_SUCCESS = 'GROUPS/FETCH_GROUP_SUCCESS',
  FETCH_GROUP_DATA_FAILURE = 'GROUPS/FETCH_GROUP_FAILURE',
  CHANGE_CAPTAIN_REQUEST = 'GROUPS/CHANGE_CAPTAIN_REQUEST',
  CHANGE_CAPTAIN_SUCCESS = 'GROUPS/CHANGE_CAPTAIN_SUCCESS',
  CHANGE_CAPTAIN_FAILURE = 'GROUPS/CHANGE_CAPTAIN_FAILURE',
  DELETE_ONE_SIGNUP_REQUEST = 'GROUPS/DELETE_ONE_SIGNUP_REQUEST',
  DELETE_ONE_SIGNUP_SUCCESS = 'GROUPS/DELETE_ONE_SIGNUP_SUCCESS',
  DELETE_ONE_SIGNUP_FAILURE = 'GROUPS/DELETE_ONE_SIGNUP_FAILURE',
  CHANGE_WAVE_REQUEST = 'GROUPS/CHANGE_WAVE_REQUEST',
  CHANGE_WAVE_SUCCESS = 'GROUPS/CHANGE_WAVE_SUCCESS',
  CHANGE_WAVE_FAILURE = 'GROUPS/CHANGE_WAVE_FAILURE',
  CHANGE_GROUP_NAME_REQUEST = 'GROUPS/CHANGE_GROUP_NAME_REQUEST',
  CHANGE_GROUP_NAME_SUCCESS = 'GROUPS/CHANGE_GROUP_NAME_SUCCESS',
  CHANGE_GROUP_NAME_FAILURE = 'GROUPS/CHANGE_GROUP_NAME_FAILURE',
  DELETE_GROUP_REQUEST = 'GROUPS/DELETE_GROUP_REQUEST',
  DELETE_GROUP_SUCCESS = 'GROUPS/DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE = 'GROUPS/DELETE_GROUP_FAILURE'
}

export const GroupActions = {
  fetchGroupDataRequest: groupId => createAction(GroupActionsEnum.FETCH_GROUP_DATA_REQUEST, groupId),
  fetchGroupDataSuccess: (data, groupId) => createAction(GroupActionsEnum.FETCH_GROUP_DATA_SUCCESS, { data, groupId }),
  fetchGroupDataFailure: error => createAction(GroupActionsEnum.FETCH_GROUP_DATA_FAILURE, { error }),

  changeCaptainRequest: (newUser, groupId) =>
    createAction(GroupActionsEnum.CHANGE_CAPTAIN_REQUEST, { newUser, groupId }),
  changeCaptainSuccess: () => createAction(GroupActionsEnum.CHANGE_CAPTAIN_SUCCESS),
  changeCaptainFailure: error => createAction(GroupActionsEnum.CHANGE_CAPTAIN_FAILURE, { error }),

  deleteOneSignupRequest: (userId, groupId) =>
    createAction(GroupActionsEnum.DELETE_ONE_SIGNUP_REQUEST, { groupId, userId }),
  deleteOneSignupSuccess: () => createAction(GroupActionsEnum.DELETE_ONE_SIGNUP_SUCCESS),
  deleteOneSignupFailure: error => createAction(GroupActionsEnum.DELETE_ONE_SIGNUP_FAILURE, { error }),

  changeWaveRequest: (newWave, groupId) => createAction(GroupActionsEnum.CHANGE_WAVE_REQUEST, { groupId, newWave }),
  changeWaveSuccess: () => createAction(GroupActionsEnum.CHANGE_WAVE_SUCCESS),
  changeWaveFailure: error => createAction(GroupActionsEnum.CHANGE_WAVE_FAILURE, { error }),

  changeNameRequest: (newName, groupId) =>
    createAction(GroupActionsEnum.CHANGE_GROUP_NAME_REQUEST, { groupId, newName }),
  changeNameSuccess: () => createAction(GroupActionsEnum.CHANGE_GROUP_NAME_SUCCESS),
  changeNameFailure: error => createAction(GroupActionsEnum.CHANGE_GROUP_NAME_FAILURE, { error })
};
