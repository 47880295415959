import { createAction } from '../../helpers';
import { INotificationPayload } from './interfaces';

export const NOTIFICATION = {
  CREATE: 'UI/NOTIFICATION/CREATE',
  DISPLAY: 'UI/NOTIFICATION/DISPLAY',
  DISMISS: 'UI/NOTIFICATION/DISMISS'
};

interface IDismissPayload {
  id: string;
}

interface IDisplayPayload extends INotificationPayload {
  id: string;
}

export const NotificationActions = {
  create: (payload: INotificationPayload) => createAction(NOTIFICATION.CREATE, payload),
  display: (payload: IDisplayPayload) => createAction(NOTIFICATION.DISPLAY, payload),
  dismiss: (payload: IDismissPayload) => createAction(NOTIFICATION.DISMISS, payload)
};
