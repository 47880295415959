import { IKeyValue } from 'helpers/url';
import { IApiClient, IRequestResult, IKeyAny } from '../api-client';

export default abstract class BaseApi {
  private readonly api: IApiClient;
  protected readonly prefix: string;

  constructor(api: IApiClient) {
    this.api = api;
  }

  private combineHeaders(headers: IKeyValue) {
    if (!headers) {
      return null;
    }

    return { ...headers };
  }

  private getPath(path: string, prefixOverride?: string) {
    const prefix = prefixOverride || this.prefix || '';

    return [prefix, path].filter(Boolean).join('/');
  }

  protected getCurrentUserLogin(): string {
    return this.api.getCurrentUserLogin();
  }

  protected get<TResult>(
    path: string = '',
    params?: IKeyValue,
    headers?: IKeyValue,
    prefixOverride?: string
  ): Promise<IRequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride);
    const combinedHeaders = this.combineHeaders(headers);

    if (!params && !combinedHeaders) {
      return this.api.get(combinedPath);
    }

    return !combinedHeaders ? this.api.get(combinedPath, params) : this.api.get(combinedPath, params, combinedHeaders);
  }

  protected post<TResult>(
    path: string = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string
  ): Promise<IRequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }
    return this.api.post.call(this.api, ...args);
  }

  protected delete<TResult>(
    path: string = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string
  ): Promise<IRequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }
    return this.api.delete.call(this.api, ...args);
  }

  protected put<TResult>(
    path: string = '',
    body?: IKeyAny,
    headers?: IKeyValue,
    prefixOverride?: string
  ): Promise<IRequestResult<TResult>> {
    const combinedPath = this.getPath(path, prefixOverride);
    const combinedHeaders = this.combineHeaders(headers);
    let args = [combinedPath, body];
    if (combinedHeaders) {
      args = [...args, combinedHeaders];
    }
    return this.api.put.call(this.api, ...args);
  }
}
