import React from 'react';
import { connect } from 'react-redux';
import { captureMessage } from '@sentry/browser';

import { withRouter, Route, RouteComponentProps, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';

import { LoadingState } from './components/Loader/LoadingState';
import NavigationContainer from './components/navigation/NavigationContainer';

import AdditionsView from './components/additions/AdditionsView';
import GroupIndex from './components/group/GroupView';
import UserProfile from './components/Profile/UserProfile';
import SignupContainer from './routes/signup/SignupContainer';

import { isAppReady } from './store/app/selectors';
import { ProfileActions } from './store/user-profile/actions';

import 'react-tippy/dist/tippy.css';
import '@livechat/design-system/dist/design-system.css';

import { IStoreStateInterface } from 'store/reducers';
import { Dispatch, AnyAction } from 'redux';
import { getCookie } from 'components/helpers/cookies';
import LoginContainer from 'routes/auth/LoginContainer';
import RegisterContainer from 'routes/auth/RegisterContainer';
import SendResetEmail from 'routes/auth/SendResetEmail';
import ResetPassword from 'routes/auth/ResetPassword';
import { getIsEmailVerified } from 'store/user-profile/selectors';
import { EmailNotVerifiedTopBar } from 'components/navigation/EmailNotVerifiedTopBar';
import { GlobalModals } from 'components/global-modals/GlobalModals';
import UserProfileSidebar from 'components/Profile/UserProfileSidebar';
import ThankYouPage from 'routes/thank-you/ThankYouPage';

export const ErrorWrapper = styled.div`
  display: block;
  color: #fff;
  max-width: 800px;
  background: rgba(10, 10, 10, 0.9);
  margin: 0 auto;
  padding: 40px 80px;
  box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);
  position: relative;
  top: 100px;

  @media (max-width: 800px) {
    margin: 0 auto;
    padding: 20px 25px;
    min-height: calc(100vh - 50px);
  }
`;

const AuthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const AppWrapper = styled.div`
  margin: auto;
  padding: 0 0.75rem;
  max-width: 1280px;

  @media (max-width: 800px) {
    padding: 0;
  }
`;

interface IStateProps {
  isAppReady: boolean;
  isLoggedIn: boolean;
  emailVerified: boolean;
}

interface IDispatchProps {
  fetchUser(): void;
  logoutUser(): void;
}

interface IProps extends IStateProps, IDispatchProps {}

interface IState {
  hasError: boolean;
  errorMsg: string;
}

class App extends React.Component<IProps & RouteComponentProps<{}>, IState> {
  state = {
    hasError: false,
    errorMsg: ''
  };

  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.fetchUser();
    }
  }

  componentDidCatch(error) {
    this.setState({ hasError: true, errorMsg: error.toString() });
    captureMessage(error);
  }

  render() {
    const { hasError, errorMsg } = this.state;
    const { isAppReady, isLoggedIn, emailVerified } = this.props;

    if (hasError)
      return (
        <ErrorWrapper>
          <h2>Przepraszamy, coś poszło nie tak.</h2>
          <p>Prosimy o przekazanie tej wiadomości na info@survivalrace.pl, jeśli problem się będzie powtarzać:</p>
          <p>{errorMsg}</p>
        </ErrorWrapper>
      );

    if (!isLoggedIn) {
      return (
        <AuthWrapper>
          <Switch>
            <Route exact path="/logowanie" component={LoginContainer} />
            <Route exact path="/rejestracja" component={RegisterContainer} />
            <Route exact path="/haslo" component={SendResetEmail} />
            <Route exact path="/haslo/reset/:token" component={ResetPassword} />
            <Redirect to={'/logowanie'} />
          </Switch>
        </AuthWrapper>
      );
    }

    return (
      <>
        {!isAppReady ? (
          <LoadingState />
        ) : (
          <>
            {!emailVerified && <EmailNotVerifiedTopBar />}
            <GlobalModals />
            <NavigationContainer logoutUser={this.props.logoutUser} />
            <AppWrapper>
              <Switch>
                <Route exact path="/" component={SignupContainer} />
                <Route path="/zapisz-do-grupy/:id/:code" component={AdditionsView} />
                <Route path="/zapisz-do-grupy/:id" component={AdditionsView} />
                <Route path="/zapisz-do-grupy" component={AdditionsView} />
                <Route path="/grupa/:groupId" component={GroupIndex} />
                <Route path="/zapisy" component={UserProfile} />
                <Route path="/profil" component={UserProfileSidebar} />
                <Route path="/dziekujemy/:paymentId" component={ThankYouPage} />
                <Redirect to="/" />
                {/* <Redirect exact from="/zapisy/:eventName" to="/" /> */}
              </Switch>
            </AppWrapper>
          </>
        )}
      </>
    );
  }
}

const mapState = (state: IStoreStateInterface): IStateProps => ({
  isAppReady: isAppReady(state),
  isLoggedIn: !!getCookie('access_token'),
  emailVerified: getIsEmailVerified(state)
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  fetchUser() {
    dispatch(ProfileActions.fetchProfileRequest());
  },
  logoutUser() {
    dispatch(ProfileActions.logout());
  }
});

const Application = connect(mapState, mapDispatch)(App);

export default withRouter(Application);
