import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

const MobileMenuWrapper = styled.div`
  @media (min-width: 800px) {
    display: none;
  }

  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #000;
  left: 0;
  justify-content: space-evenly;
  z-index: 1050;
`;

const StyledMenuItem = styled(NavLink)`
  max-width: 100px;
  color: #fff;
  font-size: 11px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;
  border-bottom: 4px solid transparent;
  font-weight: 400;
  flex: 1;
  width: 100%;
  margin-bottom: 4px;

  .material-icons {
    font-size: 24px;
  }

  &.active {
    border-color: #fff !important;
    opacity: 1 !important;
  }
`;

const activeClass = `active`;

export const MobileMenu = () => (
  <MobileMenuWrapper>
    <StyledMenuItem to="/zapisy" activeClassName={activeClass}>
      <i className="material-icons">person</i>
      Moje biegi
    </StyledMenuItem>

    <StyledMenuItem to="/" exact activeClassName={activeClass}>
      <i className="material-icons">directions_run</i>
      Zapisz się
    </StyledMenuItem>

    <StyledMenuItem to="/zapisz-do-grupy" activeClassName={activeClass}>
      <i className="material-icons">group_add</i>
      Dołącz do grupy
    </StyledMenuItem>

    <StyledMenuItem to="/profil" activeClassName={activeClass}>
      <i className="material-icons">person</i>
      Profil
    </StyledMenuItem>
  </MobileMenuWrapper>
);
