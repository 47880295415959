import * as React from 'react';
import { FileInput } from './FileInput';
import { FileUploadError, FileUploadErrorType } from 'helpers/file';
import { formattedJoinArray } from 'helpers/array';
import { validateFileSize } from 'helpers/validation';

interface IProps {
  onChange: (file: any) => void;
  onError: (error: FileUploadError) => void;
  extensions: string[];
  maxSizeInMB?: number;
  className?: string;
}

export class FilePicker extends React.PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    maxSizeInMB: 2
  };

  handleOnChange = (file: File) => {
    const { onError, onChange, maxSizeInMB, extensions } = this.props;

    if (!file) {
      onError(new FileUploadError('Failed to upload a file.', FileUploadErrorType.Unknown));
      return;
    }

    if (extensions) {
      const loadedFileExtension = file.name
        .split('.')
        .pop()
        .toLowerCase();
      const isExtensionValid = extensions.some(
        ext => ext.toLocaleLowerCase() === loadedFileExtension.toLocaleLowerCase()
      );

      if (!isExtensionValid) {
        onError(
          new FileUploadError(
            `${loadedFileExtension.toUpperCase()} extension is not supported. Please use ${formattedJoinArray(
              extensions.map(item => item.toUpperCase()),
              'or'
            )} format.`,
            FileUploadErrorType.InvalidExtension
          )
        );
        return;
      }
    }

    const fileSizeValidationError = validateFileSize(file, maxSizeInMB);

    if (fileSizeValidationError) {
      onError(new FileUploadError(fileSizeValidationError, FileUploadErrorType.InvalidSize));
      return;
    }

    onChange(file);
  };

  render(): JSX.Element {
    const { children, className } = this.props;

    return (
      <FileInput onChange={this.handleOnChange} className={className}>
        {children}
      </FileInput>
    );
  }
}
