import { call, takeEvery, put } from 'redux-saga/effects';

import { GroupActionsEnum, GroupActions } from './actions';
import { Api } from '../../services';
import { NotificationActions } from '../ui/notification/actions';
import { SagaIterator } from 'redux-saga';

function* fetchGroupData(action) {
  const { payload: groupId } = action;
  const { result, error } = yield call(Api.signupApi.getGroupInfo, groupId);

  if (result) {
    yield put(GroupActions.fetchGroupDataSuccess(result.data, groupId));
  }

  if (error) {
    yield put(NotificationActions.create(error && error.message));
    yield put(GroupActions.fetchGroupDataFailure('Problem z pobraniem danych grupy'));
  }
}

function* changeCaptain(action) {
  const { groupId, newUser } = action.payload;

  const { result, error } = yield call(Api.signupApi.changeGroupCaptain, groupId, newUser);

  if (result) {
    yield put(GroupActions.fetchGroupDataSuccess(result.data, groupId));
    yield put(GroupActions.changeCaptainSuccess());
    yield put(NotificationActions.create(result.message));
  }

  if (error) {
    yield put(NotificationActions.create(error && error.message));
    yield put(GroupActions.changeCaptainFailure('Problem ze zmianą kapitana'));
  }
}

function* changeWave(action): SagaIterator {
  const { newWave, groupId } = action.payload;
  let request = {} as any;
  request = yield call(Api.signupApi.changeGroupWave, groupId, newWave) as any;

  if (request.result) {
    yield put(GroupActions.fetchGroupDataSuccess(request.result.data, groupId));
    yield put(GroupActions.changeWaveSuccess());
    yield put(NotificationActions.create(request.result.message));
  }

  if (request.error) {
    yield put(NotificationActions.create(request.error.message));
    yield put(GroupActions.changeWaveFailure('Problem ze zmianą fali'));
  }
}

function* changeName(action) {
  const { newName, groupId } = action.payload;
  const { result, error } = yield call(Api.signupApi.changeGroupName, groupId, newName);

  if (result) {
    yield put(GroupActions.fetchGroupDataSuccess(result.data, groupId));
    yield put(GroupActions.changeNameSuccess());
    yield put(NotificationActions.create(result.message));
  }

  if (error) {
    yield put(NotificationActions.create(error && error.message));
    yield put(GroupActions.changeNameFailure('Problem ze zmianą nazwy grupy'));
  }
}

function* deleteSignup(action) {
  const { userId, groupId } = action.payload;
  const { result, error } = yield call(Api.signupApi.deleteSignup, groupId, userId);

  if (result) {
    yield put(GroupActions.fetchGroupDataSuccess(result.data, groupId));
    yield put(GroupActions.deleteOneSignupSuccess());
    yield put(NotificationActions.create(result.message));
  }

  if (error) {
    yield put(NotificationActions.create(error && error.message));
    yield put(GroupActions.deleteOneSignupFailure('Problem ze zmianą kapitana'));
  }
}

export function* groupSaga() {
  yield takeEvery(GroupActionsEnum.FETCH_GROUP_DATA_REQUEST, fetchGroupData);
  yield takeEvery(GroupActionsEnum.CHANGE_CAPTAIN_REQUEST, changeCaptain);
  yield takeEvery(GroupActionsEnum.CHANGE_WAVE_REQUEST, changeWave);
  yield takeEvery(GroupActionsEnum.DELETE_ONE_SIGNUP_REQUEST, deleteSignup);
  yield takeEvery(GroupActionsEnum.CHANGE_GROUP_NAME_REQUEST, changeName);
}
