import React from 'react';
import styled from '@emotion/styled';
import CircularProgress from 'material-ui/CircularProgress';

const StyledCenter = styled.div`
  width: 40px;
  margin: 20px auto;
`;

interface IProps {
  size?: number;
}

export const Loader: React.FC<IProps> = ({ size }) => (
  <StyledCenter>
    <CircularProgress mode="indeterminate" size={size || 40} color="#f60" />
  </StyledCenter>
);
