import { createRequestFetchingSelector } from '../../request/selectors';
import { IStoreStateInterface } from 'store/reducers';
import { IEvent } from 'store/events/interfaces';
import { ISignupState } from './interfaces';

export function getSignup(state: IStoreStateInterface): ISignupState {
  return state.ui.signup;
}
export function getAvailableWaves(state: IStoreStateInterface, event_category_id: number) {
  if (!state.event.waves) {
    return null;
  }

  return state.event.waves.filter(item => item.event_category_id === event_category_id);
}

export function getSelectedPlaces(state: IStoreStateInterface) {
  return getSignup(state).places || 0;
}

export function getEventById(state: IStoreStateInterface, event_id: number): IEvent {
  return state.event.events.find(item => item.id === event_id);
}

export function getCodes(state: IStoreStateInterface) {
  return getSignup(state).codes;
}

export function getSelectedEvent(state: IStoreStateInterface) {
  return getSignup(state).city_id || 0;
}

export function getIsSigningUp(state: IStoreStateInterface) {
  return createRequestFetchingSelector(['SIGNUP'])(state);
}

export function getIsCheckingCoupons(state: IStoreStateInterface) {
  return createRequestFetchingSelector(['CODE'])(state);
}

// export function getSignupPrices(state: IStoreStateInterface) {
//   return getEventPrices(state, state.ui.signup.event_category_id, getSelectedPlaces(state), getCodes(state));
// }

export function getWavesForSignup(state: IStoreStateInterface) {
  const signup = getSignup(state);

  return getAvailableWaves(state, signup.event_category_id);
}
