import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import { isEmpty } from 'lodash';
import { Api } from 'services';

class SignupChangeUserDialog extends React.Component {
  state = {
    loading: false,
    sendInvite: false,
    email: '',
    newUser: {}
  };

  onEmailChange = ev => {
    const email = ev.target.value;
    this.setState({ email });
  };

  onSubmitDialog = async () => {
    this.setState({ loading: true });

    const { email } = this.state;

    if (!email) {
      return;
    }
    const { result, error } = await Api.signupApi.userVerifyTransfer(email, this.props.signupId);

    if (result) {
      this.props.onAddMessage(result.message);
      this.setState({ newUser: result.data, loading: false, sendInvite: false });
    } else {
      if (error.message.content === 'Użytkownik nie istnieje') {
        this.setState({
          loading: false,
          sendInvite: true
        });
        return;
      }

      this.props.onAddMessage(error.message);
      this.setState({ loading: false, newUser: {} });
    }
  };

  renderUser = () => {
    const newUser = this.state.newUser || {};

    return (
      <div className="change-group-info">
        <h3>Możliwe jest przekazanie udziału!</h3>
        <table>
          <tbody>
            <tr>
              <td>Imię i nazwisko:</td>
              <td>{newUser.name}</td>
            </tr>
            <tr>
              <td>Adres e-mail:</td>
              <td>{newUser.email}</td>
            </tr>
          </tbody>
        </table>
        <p style={{ color: 'red' }}>
          Czy na pewno chcesz oddać swój zapis temu użytkownikowi? Spowoduje to usunięcie Cię z grupy na rzecz
          wskazanego użytkownika!
        </p>
        <div>
          <RaisedButton
            labelColor={'#FFFFFF'}
            backgroundColor={'#FF6600'}
            label="Oddaj swój udział"
            onClick={this.onUserChange}
          />
        </div>
      </div>
    );
  };

  onUserChange = () => {
    if (this.state.email.length) {
      this.props.onSubmitDialog(this.state.email);
      this.setState({ email: '', newUser: {} });
    }
  };

  renderSendInvite = () => {
    return (
      <div className="change-group-info">
        <p>
          Nie mamy użytkownika w naszym systemie powiązanego z podanym przez Ciebie adresem e-mail, ale możemy na ten
          adres wysłać wiadomość informującą o Twoich zamiarach.
        </p>
        <div>
          <RaisedButton
            labelColor={'#FFFFFF'}
            backgroundColor={'#FF6600'}
            label="Wyślij zaproszenie"
            onClick={this.handleInviteSend}
          />
        </div>
      </div>
    );
  };

  handleInviteSend = async () => {
    const { email } = this.state;

    if (email.length) {
      const { result, error } = await Api.signupApi.sendInviteToCreate(email);

      if (result) {
        this.props.onAddMessage(result.message);
        this.props.onHideDialog();
      }

      if (error) {
        this.props.onAddMessage(error.message);
      }
    }
  };

  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Oddaj udział"
        disabled={isEmpty(this.state.email)}
        labelStyle={{
          color: isEmpty(this.state.email) ? '#DDDDDD' : '#FF6600'
        }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Oddaj swój udział innemu użytkownikowi"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p>Podaj adres e-mail użytkownika, któremu chcesz oddać swój zapis w tej grupie.</p>

        <div className="group-input">
          <input
            type="text"
            placeholder="Wprowadź adres e-mail użytkownika"
            value={this.state.email}
            onChange={this.onEmailChange}
          />
        </div>

        {this.state.loading ? (
          <div className="change-group-loading">
            <CircularProgress mode="indeterminate" size={60} />
          </div>
        ) : (
          false
        )}

        {!isEmpty(this.state.newUser) && !this.state.loading ? this.renderUser() : false}
        {this.state.sendInvite ? this.renderSendInvite() : false}
      </Dialog>
    );
  }
}

export default SignupChangeUserDialog;
