import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import { isEmpty } from 'lodash';

class SignupInviteDialog extends React.Component {
  state = {
    email: ''
  };

  onEmailChange = ev => {
    const email = ev.target.value;
    this.setState({ email });
  };

  onSubmitDialog = () => {
    if (!isEmpty(this.state.email)) {
      this.props.onSubmitDialog(this.state.email);
      this.setState({ email: '' });
    }
  };

  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Wyślij zaproszenie"
        labelStyle={{ color: '#FF6600' }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Wyślij zaproszenie"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p>Podaj adres e-mail, na który chcesz wysłać zaproszenie do grupy na miejsce opłacone.</p>
        <div className="group-input">
          <input
            type="text"
            placeholder="Wprowadź adres email"
            value={this.state.email}
            onChange={this.onEmailChange}
          />
        </div>
      </Dialog>
    );
  }
}

export default SignupInviteDialog;
