import { BaseApi } from '../base';

interface ILoginPayload {
  email: string;
  password: string;
}

interface IRegisterPayload {
  name: string;
  email: string;
  password: string;
  terms_conditions_consent: boolean;
}

export default class AuthApi extends BaseApi {
  loginUser = (body: ILoginPayload) => this.post(`login`, body);
  register = (body: IRegisterPayload) => this.post(`register`, body);
  authorizeForSocial = (provider: string, accessToken: any) => this.post(`login/social/${provider}`, { accessToken });

  passwordResetLink = (email: string) => this.post('password/email', { email });
  resetPassword = (token, email, password, password_confirmation) =>
    this.post('password/reset', { token, email, password, password_confirmation });
}
