import round from 'lodash/round';
import { createRequestFetchingSelector } from '../request/selectors';
import { IStoreStateInterface } from 'store/reducers';
import { IWave, IEvent, ICategory, ICity } from './interfaces';
import { ICouponCode, CouponType } from 'store/ui/signup/interfaces';

export function getIsFetchingEvent(state: IStoreStateInterface): boolean {
  return createRequestFetchingSelector(['EVENTS/FETCH_EVENT'])(state);
}

export function getEvents(state: IStoreStateInterface): IEvent[] {
  return state.event.events;
}

export function getEventCategories(state: IStoreStateInterface): ICategory[] {
  return state.event.categories;
}

export function getAvailableCitiesForEvent(state: IStoreStateInterface, event_id: number): ICity[] {
  const { cities, categories } = state.event;

  const availableCategories = categories.filter(c => c.event_id === event_id);

  return cities.reduce((acc, city) => {
    if (availableCategories.find(a => a.city_id === city.id)) {
      acc.push(city);
    }

    return acc;
  }, []);
}

export function getAvailableEventCategories(state: IStoreStateInterface, event_id: number, city_id: number) {
  const { categories } = state.event;

  return categories.filter(c => c.event_id === event_id && c.city_id === city_id);
}

export function getWave(state: IStoreStateInterface, waveId: number): IWave {
  if (!state.event.waves) {
    return null;
  }

  return state.event.waves.find(wave => wave.id === waveId) || null;
}

export function getEventCategory(state: IStoreStateInterface, event_category_id: number): ICategory {
  return state.event.categories.find(a => a.id === event_category_id);
}

export function getCity(state: IStoreStateInterface, city_id: number): ICity {
  return state.event.cities.find(a => a.id === city_id);
}

export function calculateDiscount(singlePrice: number, places: number, codes: ICouponCode[]): number {
  const sumDiscount = singlePrice * places - calculatePrice(singlePrice, places, codes);
  return round(sumDiscount, 2);
}

export function calculatePrice(singlePrice: number, places: number, codes: ICouponCode[]): number {
  let totalPrice = 0;

  for (let i = 0; i < places; i++) {
    const code = codes[i];
    const discount = code ? code.value : 0;
    const type = code ? code.coupon_type === CouponType.Value : false;

    if (type) {
      totalPrice += discount > singlePrice ? 0 : singlePrice - discount;
    } else {
      totalPrice += singlePrice * (1 - discount / 100);
    }
  }
  return round(totalPrice, 2);
}

export function getManipulationPrice(price: number): number {
  return round(price * 0.015, 2);
}

export function calculatePlaceDiscount(prices: ISignupSinglePrices, places: number): number {
  if (places < 5) {
    return 0;
  }

  return (prices.initialPrice - prices.finalPrice) * places;
}

export interface ISignupPrices {
  initialSinglePrice: number;
  singlePrice: number;
  manipilation: number;
  totalPrice: number;
  discount: number;
  toPay: number;
  placeDiscount: number;
}

export interface ISignupSinglePrices {
  finalPrice: number;
  initialPrice: number;
}

export function getEventPriceBySinglePrices(
  prices: ISignupSinglePrices,
  places: number,
  codes: ICouponCode[]
): ISignupPrices {
  const totalPrice = calculatePrice(prices.finalPrice, places, codes);
  const manipilation = getManipulationPrice(totalPrice);

  return {
    initialSinglePrice: prices.initialPrice,
    singlePrice: prices.finalPrice,
    manipilation,
    totalPrice,
    discount: calculateDiscount(prices.finalPrice, places, codes),
    toPay: round(totalPrice + manipilation, 2),
    placeDiscount: calculatePlaceDiscount(prices, places)
  };
}
