import React from 'react';
import { GlobalModal } from 'enums/global-modals';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenedGlobalModal } from 'store/ui/global-modals/selectors';
import { GlobalModalActions } from 'store/ui/global-modals/actions';

import EditProfileModal from './EditProfileModal';
import { EmailVerificationModal } from './EmailVerificationModal';
import { TermsConditionsModal } from './TermsConditionsModal';

const GlobalModalMap = {
  [GlobalModal.EmailVerificationModal]: EmailVerificationModal,
  [GlobalModal.EditProfileModal]: EditProfileModal,
  [GlobalModal.TermsConditions]: TermsConditionsModal
};

export const GlobalModals = () => {
  const openedGlobalModals = useSelector(getOpenedGlobalModal);
  const dispatch = useDispatch();

  if (!openedGlobalModals) {
    return null;
  }

  const GlobalModalComponent = GlobalModalMap[openedGlobalModals.modalType];

  return (
    <>
      {openedGlobalModals.modalType && (
        <GlobalModalComponent
          handleClose={() => dispatch(GlobalModalActions.hideModal(openedGlobalModals.modalType))}
        />
      )}
    </>
  );
};
