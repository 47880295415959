import * as React from 'react';
import { FilePicker } from './FilePicker';
import { IImageDimensionsBoundary, loadFileAsDataURL, validateImageDimensions, FileUploadError } from 'helpers/file';

interface IProps {
  onChange: (file: File) => void;
  onError: (error: FileUploadError) => void;
  extensions: string[];
  dimensions: IImageDimensionsBoundary;
  className?: string;
  maxSizeInMB?: number;
}

export class ImagePicker extends React.PureComponent<IProps> {
  private handleImageChange = async file => {
    const { onChange, onError, dimensions } = this.props;

    loadFileAsDataURL(file)
      .then(dataURL => validateImageDimensions(dataURL, dimensions))
      .then(isValid => {
        if (isValid) {
          onChange(file);
        }
      })
      .catch(error => onError(error));
  };

  render() {
    const { children, onChange, ...props } = this.props;

    return (
      <FilePicker onChange={this.handleImageChange} {...props}>
        {children}
      </FilePicker>
    );
  }
}
