import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { SignupActions } from '../../../store/ui/signup/actions';
import { EventCard } from './Selectable';
import { getAvailableCitiesForEvent } from '../../../store/events/selectors';
import { ISignupState } from 'store/ui/signup/interfaces';
import { ICity } from 'store/events/interfaces';
import { Dispatch, AnyAction } from 'redux';
import { IStoreStateInterface } from 'store/reducers';

const StyledCityName = styled.div`
  font-size: 24px;
  line-height: 1.3;
  font-weight: bold;
  color: #fff;
`;

interface IOwnProps {
  signup: ISignupState;
}
interface IStateProps {
  cities: ICity[];
}

interface IDispatchProps {
  onSelectCity(city: number): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const CitySelect: React.FC<IProps> = ({ signup, cities, onSelectCity }) => (
  <>
    {cities.map(city => (
      <EventCard selected={signup.city_id === city.id} onClick={() => onSelectCity(city.id)} key={city.city_name}>
        <StyledCityName>{city.city_name}</StyledCityName>
      </EventCard>
    ))}
  </>
);

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => ({
  cities: getAvailableCitiesForEvent(state, ownProps.signup.event_id)
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  onSelectCity(id) {
    dispatch(SignupActions.city(id));
  }
});

export default connect(
  mapState,
  mapDispatch
)(CitySelect);
