import React, { ReactElement } from 'react';
import styled from '@emotion/styled';

export const SummaryTable = styled('table')`
  width: 100%;
  border: 1px solid #333;
  margin-bottom: 40px;

  td:last-child {
    text-align: right;
    flex: 1;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const StyledDescription = styled.div`
  color: #bbb;
  font-size: 13px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const StyledSummaryRow = styled('tr')`
  border-bottom: 1px solid #333;

  td {
    padding: 8px 16px;

    @media (max-width: 600px) {
      padding: 2px 6px;
    }
  }
`;

export const ImportantRow = styled('span')`
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

interface IProps {
  label: string | ReactElement;
  description?: string;
}

export const SummaryRow: React.FC<IProps> = ({ label, description, children }) => (
  <StyledSummaryRow>
    <td>
      {label}
      {description && (
        <React.Fragment>
          <br />
          <StyledDescription>{description}</StyledDescription>
        </React.Fragment>
      )}
    </td>
    <td>{children}</td>
  </StyledSummaryRow>
);
