import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tippy';

interface IStepProps {
  filled: boolean;
  disabled: boolean;
  onClick?(): void;
}

const StyledWrapper = styled.div`
  display: flex;

  justify-content: center;
  margin: 10px 0 25px;
`;

const Step = styled.div<IStepProps>`
  position: relative;
  width: 40px;
  height: 23.09px;
  background-color: ${props => (props.filled ? '#333' : '#f60')};
  color: ${props => (props.filled ? '#fff' : '#000')};
  margin: 11.55px 0;

  font-family: 'Lordcorps', serif;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  margin-right: 20px;

  @media (max-width: 800px) {
    margin-right: 5px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }

  &::before {
    bottom: 100%;
    border-bottom: 11.55px solid ${props => (props.filled ? '#333' : '#f60')};
  }

  &::after {
    top: 100%;
    width: 0;
    border-top: 11.55px solid ${props => (props.filled ? '#333' : '#f60')};
    left: 0;
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `};
`;

export const ProgressBar = ({ currentStep, allSteps, onStepClicked }) => (
  <StyledWrapper>
    {allSteps.map((item, ix) => (
      <Tooltip title={item} key={ix}>
        <Step filled={ix > currentStep} onClick={() => onStepClicked(ix)} disabled={ix > currentStep}>
          {ix + 1}
        </Step>
      </Tooltip>
    ))}
  </StyledWrapper>
);
