/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React from 'react';
import videoFont from './fonts/Video.woff2';
import lordcorps from './fonts/Lordcorps.woff2';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Video';
          src: url(${videoFont}) format('woff2');
          font-face: swap;
        }

        @font-face {
          font-family: 'Lordcorps';
          src: url(${lordcorps}) format('woff2');
          font-face: swap;
        }

        [class^='lc-'] {
          font-family: 'Video';
        }

        input[type='checkbox'] {
          padding: 0;
          border: 0;
          box-shadow: none;
          width: initial;
        }
      `}
    />
  );
};

export default GlobalStyles;
