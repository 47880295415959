import { IStoreStateInterface } from 'store/reducers';
import { Properties } from './reducer';

export function isAppReady(state: IStoreStateInterface): boolean {
  return state.app.appReady;
}

export function getProperty(state: IStoreStateInterface, name: Properties) {
  return state.app.properties[name];
}
