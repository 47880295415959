import { IKeyValue } from 'helpers/url';

export enum Properties {
  EditModalText = 'profile_edit_label'
}
export interface IAppState {
  appReady: boolean;
  properties: IKeyValue<Properties>;
}

const initialState = {
  appReady: false,
  properties: {}
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case 'APP_READY': {
      return {
        appReady: true
      };
    }

    case 'PROPERTIES_READY': {
      return {
        appReady: true,
        properties: {
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
}
