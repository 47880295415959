import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

class GroupDeleteDialog extends React.Component {
  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Usuń grupę"
        labelStyle={{ color: '#FF6600' }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.props.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Usuń grupę"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p style={{ color: 'red' }}>
          Na pewno chcesz usunąć grupę? UWAGA! W przypadku usunięcia grupy pieniądze nie zostaną zwrócone! Nie usuwaj
          zapisu, jeśli chcesz zmienić swoją grupę.
        </p>
      </Dialog>
    );
  }
}

export default GroupDeleteDialog;
