import React from 'react';
import styled from '@emotion/styled';
import vars from '../assets/vars';

export const SelectWrapper = styled('select')`
  font-size: 15px;
  padding: 0 8px;
  border: 1px solid ${vars.borderColor};
  width: 100%;
  transition: border 0.2s ${vars.basicBezier};
  box-sizing: border-box;
  color: ${vars.text};
  display: inline-block;
  flex: 7;
  height: 38px;
  min-height: 38px;
  background: #fff;

  appearance: none;

  -webkit-font-smoothing: auto;

  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAQCAMAAAAs2N9uAAAAqFBMVEVWVlb///////9WVlb///////////////////////////////////////////////9WVlb///////////9WVlb///////9WVlb////////////////////////////+/v7///9WVlZXV1ddXV1kZGRlZWV0dHR+fn6CgoKGhoaSkpKUlJSurq6vr6/Dw8PHx8fb29vq6urs7Ozx8fHy8vL19fX7+/v////M5xNkAAAAIXRSTlMAAAEDAwYPFBUcHzdVWnh6lpahpLS0t7i4vcLS6er1/v5Eh0bQAAAAfklEQVR42qXI2xaBQBgG0O/XCUVFSBEVlSiR8v5vpjUrrRm39uWGFYosgrQRxpEIdpNwkzQ2QXYfp2HOz5VMgOpXcT9x5avUFab7MmIT3XcTsIIe3FgVgY6+MDvk3VyPBoaC2aZh1s7B1WhZX14L8AXFe28VsTBea/gW/fijPianEKHyW6cOAAAAAElFTkSuQmCC);
  background-position: 96% 18px;
  background-repeat: no-repeat;
  background-size: 9px;
`;

export const SelectItem = ({ value, children, ...rest }) => (
  <option value={value} {...rest}>
    {children}
  </option>
);
