import { createRequestFetchingSelector } from '../request/selectors';
import { IStoreStateInterface } from 'store/reducers';
import { IGroup } from './reducer';

enum EventTypes {
  Kids = 'kids',
  SurvivalRace = 'sr',
  Legend = 'legend',
  Junior = 'junior'
}

export function getGroupInfo(state: IStoreStateInterface, id: number): IGroup {
  return state.groups[id] || null;
}

export function getIsFetchingGroup(state) {
  return createRequestFetchingSelector(['GROUPS/FETCH_GROUP'])(state);
}

export function getEventType(group: IGroup) {
  if(!group) {
    return EventTypes.SurvivalRace;
  }
  
  if(group.distance === 1) {
    return EventTypes.Kids;
  }

  if(group.distance === 36) {
    return EventTypes.Legend;
  }

  if(group.distance === 24) {
    return EventTypes.Junior;
  }

  return EventTypes.SurvivalRace;
}