import React from 'react';
import styled from '@emotion/styled';
import { connect } from 'react-redux';

import { ISignupState } from 'store/ui/signup/interfaces';
import { SignupActions } from 'store/ui/signup/actions';
import { getSignup } from 'store/ui/signup/selectors';

import ConnectedProgressBar from './ConnectedProgressBar';

export const StyledSignupWrapper = styled.div`
  display: block;
  color: #fff;
  max-width: 780px;
  min-width: 600px;
  width: 100%;
  background: rgba(10, 10, 10, 0.9);
  margin: 0 auto 15%;
  padding: 40px 80px;
  box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);
  backdrop-filter: saturate(180%) blur(10px);

  @media (max-height: 800px) {
    margin: 25px auto;
  }

  @media (max-width: 800px) {
    margin: 0 auto 30px;
    padding: 20px 25px;
    min-height: 100vh;
    min-width: 95vw;
  }
`;

const StyledHeader = styled('h2')`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(10, 10, 10, 0.9);
    box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);
    backdrop-filter: saturate(180%) blur(10px);
  }
`;

interface IProps {
  step(step: number): void;
  signup: ISignupState;
  title: string;
  buttons?(prev: () => void, next: () => void): React.ReactNode;
}

class SignupWrapper extends React.PureComponent<IProps> {
  proceed = () => {
    this.props.step(this.props.signup.step + 1);
  };

  back = () => {
    this.props.step(this.props.signup.step - 1);
  };

  render() {
    return (
      <>
        <StyledSignupWrapper>
          <StyledHeader>{this.props.title}</StyledHeader>
          <ConnectedProgressBar />

          {this.props.children}
        </StyledSignupWrapper>
        <StepWrapper>{this.props.buttons && this.props.buttons(this.proceed, this.back)}</StepWrapper>
      </>
    );
  }
}

const mapState = state => {
  return {
    state,
    signup: getSignup(state)
  };
};

const mapDispatch = {
  step: SignupActions.step
};

export default connect(mapState, mapDispatch)(SignupWrapper);
