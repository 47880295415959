/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { isEmpty } from 'lodash';
import { SelectItem, SelectWrapper } from '../Form/Select';
import { ButtonGroup, Button } from '../Button';
import { Modal, ModalPortal, ModalFooter } from '../Modals';
import InputGroup from '../Form/InputGroup';
import Input from '../Form/Input';
import Label from '../Form/Label';
import Validator from '../../helpers/Validator';
import { useDispatch, useSelector } from 'react-redux';
import { getProperty } from 'store/app/selectors';
import { Properties } from 'store/app/reducer';
import { IStoreStateInterface } from 'store/reducers';
import { ProfileActions } from 'store/user-profile/actions';
import styled from '@emotion/styled';
import { getUserProfile } from 'store/user-profile/selectors';

const StyledColumn = styled.div`
  width: 50%;
  padding: 0 8px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ProfileModalContent = styled.div`
  padding: 8px;
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

interface IProps {
  handleClose(): void;
}

const EditProfileModal: React.FC<IProps> = ({ handleClose }) => {
  const user = useSelector(getUserProfile);
  const text = useSelector((state: IStoreStateInterface) => getProperty(state, Properties.EditModalText));
  const dispatch = useDispatch();

  const [formData, setFormData] = React.useState({
    email: user.email || '',
    name: user.name || '',
    surname: user.surname || '',
    phone: user.phone || '',
    sex: user.sex,
    size: user.size,
    birthdate: user.birthdate && user.birthdate.toISOString().slice(0, 10)
  });

  const updateFormData = updateObject => {
    setFormData({ ...formData, ...updateObject });
  };

  const updateTextField = ({ target }) => {
    updateFormData({ [target.name]: target.value });
  };

  const onPhoneKeyPress = e => {
    if (!Validator.validatePhoneNumberByEvent(e)) {
      e.preventDefault();
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!isEmpty(formData)) {
      updateFormData({
        sex: !isEmpty(formData.sex) ? formData.sex : 'M',
        size: !isEmpty(formData.size) ? formData.size : 'XS'
      });

      dispatch(ProfileActions.updateProfileRequest(formData, handleClose));
    }
  };

  return (
    <ModalPortal>
      x
      <Modal title="Edytuj dane profilowe" closeModal={handleClose} width="900px">
        <form onSubmit={onSubmit}>
          <p
            css={css`
              padding: 8px;
            `}
          >
            {text}
          </p>

          <ProfileModalContent>
            <StyledColumn>
              <InputGroup column>
                <Label wide={false}>Imię</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Imię / Pseudonim"
                  value={formData.name}
                  onChange={updateTextField}
                  required
                />
              </InputGroup>
              <InputGroup column>
                <Label wide={false}>Nazwisko</Label>
                <Input
                  type="text"
                  name="surname"
                  placeholder="Nazwisko"
                  value={formData.surname}
                  onChange={updateTextField}
                />
              </InputGroup>

              <InputGroup column>
                <Label>Adres e-mail</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="Wprowadź swój email"
                  value={formData.email}
                  onChange={updateTextField}
                  required
                />
              </InputGroup>
            </StyledColumn>
            <StyledColumn>
              <InputGroup column>
                <Label>Nr telefonu</Label>
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Wprowadź swój nr telefonu"
                  value={formData.phone}
                  onChange={updateTextField}
                  onKeyPress={onPhoneKeyPress}
                  pattern=".{9,}"
                  maxLength={9}
                  title="Podaj poprawny nr telefonu bez numeru kierunkowego"
                />
              </InputGroup>

              <InputGroup column>
                <Label>Płeć</Label>

                <SelectWrapper onChange={updateTextField} name="sex" value={formData.sex}>
                  <SelectItem value="">Wybierz</SelectItem>
                  <SelectItem value="K">Kobieta</SelectItem>
                  <SelectItem value="M">Mężczyzna</SelectItem>
                </SelectWrapper>
              </InputGroup>
              <InputGroup column>
                <Label>Data urodzenia</Label>

                <Input
                  type="date"
                  name="birthdate"
                  placeholder="Podaj datę urodzenia"
                  value={formData.birthdate}
                  onChange={updateTextField}
                />
              </InputGroup>
              <InputGroup column>
                <Label>
                  <a
                    href="https://survivalrace.pl/bez-kategorii/rozmiary-koszulek/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rozmiar koszulki &nbsp;<i className="material-icons">info</i>
                  </a>
                </Label>
                <SelectWrapper value={formData.size} onChange={updateTextField} name="size">
                  {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map(s => (
                    <SelectItem key={s} value={s}>
                      {s}
                    </SelectItem>
                  ))}
                </SelectWrapper>
              </InputGroup>
            </StyledColumn>
          </ProfileModalContent>

          <ModalFooter>
            <ButtonGroup>
              <Button onClick={handleClose}>Anuluj</Button>
              <Button primary type="submit">
                Zapisz dane
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </Modal>
    </ModalPortal>
  );
};

export default EditProfileModal;
