export type ParamValue = string | number | boolean;

export interface IKeyValue<T = ParamValue> {
  [key: string]: T | T[];
}

export function stringifyQueryParams(params: IKeyValue) {
  if (!params) {
    return '';
  }

  return Object.keys(params)
    .map(key => {
      if (!Array.isArray(params[key])) {
        const value = params[key] === undefined || params[key] === null ? '' : params[key].toString();
        return value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `${encodeURIComponent(key)}`;
      }

      const stringArray = (params[key] as ParamValue[]).map(item => item.toString());
      return stringArray
        .map(value =>
          value ? `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}` : `${encodeURIComponent(key)}[]`
        )
        .join('&');
    })
    .join('&');
}

export function parseQueryParams(params: string): IKeyValue<string> {
  if (!params) {
    return {};
  }

  const pairs = decodeURIComponent(params.replace(/^\?/, '')).split('&');
  const mergedPairs = pairs.reduce((acc, pair) => {
    const [key, value] = pair.split('=');
    if (value === undefined) {
      return acc;
    }

    const isArrayParam = key.endsWith('[]');
    const valueToSet = isArrayParam ? [decodeURIComponent(value)] : decodeURIComponent(value);
    const paramName = isArrayParam ? key.slice(0, -2) : key;
    const shouldUseArray = isArrayParam && Array.isArray(acc[paramName]);

    return {
      ...acc,
      [paramName]: shouldUseArray ? [...acc[paramName], ...valueToSet] : valueToSet
    };
  }, {});

  return mergedPairs;
}
