export enum MessageType {
  Success = 'success',
  Error = 'error',
  Help = 'help',
  Info = 'info',
  Warning = 'warning'
}

export interface INotification {
  id: string;
  type: MessageType;
  content: string;
}

export interface INotificationsState {
  data: INotification[];
}

export interface INotificationPayload {
  type: MessageType;
  content: string;
  timeout?: number;
}
