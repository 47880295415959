class Validator {
  validatePhoneNumberByEvent = e => {
    const code = e.which || e.keyCode;

    if (!e.shiftKey) {
      if (code >= 48 && code <= 57) return true; // 0-9
    }
    return false;
  };
}

export default new Validator();
