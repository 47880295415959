import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import Checkbox from 'material-ui/Checkbox';
import { SignupActions } from '../../../store/ui/signup/actions';
import Coupons from './Coupons';
import { getEventById, getSignup, getIsSigningUp } from '../../../store/ui/signup/selectors';
import { Button } from '../../../components/Button/Button';
import { ButtonGroup } from '../../../components/Button/ButtonGroup';
import { SummaryTable, SummaryRow, ImportantRow } from '../../../components/summary/SummaryTable';
import { Strike } from './Strike';
import { ISignupState } from 'store/ui/signup/interfaces';
import { IStoreStateInterface } from 'store/reducers';
import { ISignupPrices, getEventCategory, getEventPriceBySinglePrices, getCity } from 'store/events/selectors';
import { IEvent, ICategory, ICity } from 'store/events/interfaces';
import { IProfileState } from 'store/user-profile/reducer';
import { Api } from 'services';
import { Loader } from 'components/Loader/Loader';
import { trackEvent } from 'components/helpers/eventTracking';
import { getUserProfile } from 'store/user-profile/selectors';

const CheckBoxWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;

  span {
    position: relative;
    left: 30px;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  justify-content: center;
`;

const FakeLink = styled('span')`
  color: #f60;
  cursor: pointer;
  font-weight: 600;
  font-size: 80%;
`;

interface IOwnProps {
  signup: ISignupState;
}

interface IProps extends IOwnProps {
  handleMakeSignup(data: { reservation: boolean }): void;
  event: IEvent;
  handleSetStep(step: number): void;
  isSending: boolean;
  user: IProfileState;
  event_category: ICategory;
  city: ICity;
}

const SignupSummary: React.FC<IProps> = props => {
  const { signup, handleMakeSignup, event, handleSetStep, isSending, event_category, city } = props;

  const [acceptedRegulations, setAcceptedRegulations] = React.useState(false);
  const [singlePrice, setPrice] = React.useState({ initialPrice: 0, finalPrice: 0 });
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function getSinglePrice() {
      const { result } = (await Api.signupApi.getSinglePrice(signup.event_category_id, signup.places)) as any;

      if (result) {
        setPrice(result.data);
        setIsLoading(false);
      }
    }

    getSinglePrice();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const prices: ISignupPrices = getEventPriceBySinglePrices(singlePrice, signup.places, signup.codes);

  React.useEffect(() => {
    trackEvent('Cart displayed', {
      runType: event.event_name,
      city: city.city_name,
      distance: event_category.distance,
      wave: signup.wave.id,
      seatsReserved: signup.places,
      unitPrice: prices.singlePrice,
      totalPrice: prices.totalPrice,
      extraFee: prices.manipilation,
      finalPrice: prices.totalPrice
    });
  });

  if (isLoading) {
    return <Loader size={80} />;
  }

  return (
    <>
      <SummaryTable>
        <tbody>
          <SummaryRow label={<span>Wydarzenie</span>}>{event.event_name}</SummaryRow>
          <SummaryRow label={<span>Data wydarzenia</span>}>{event_category.event_category_date}</SummaryRow>
          <SummaryRow
            label={
              <span>
                Dystans <FakeLink onClick={() => handleSetStep(2)}>Edytuj</FakeLink>
              </span>
            }
          >
            {event_category.category_name} / {event_category.distance} KM
          </SummaryRow>
          <SummaryRow
            label={
              <span>
                Fala (godz. startu) <FakeLink onClick={() => handleSetStep(3)}>Edytuj</FakeLink>
              </span>
            }
          >
            {signup.wave && signup.wave.wave_time}
          </SummaryRow>
          <SummaryRow
            label={
              <span>
                Zarezerwowane miejsca <FakeLink onClick={() => handleSetStep(4)}>Edytuj</FakeLink>
              </span>
            }
          >
            {signup.places}
          </SummaryRow>
          <SummaryRow
            label={
              <span>
                Nazwa grupy. <FakeLink onClick={() => handleSetStep(4)}>Edytuj</FakeLink>
              </span>
            }
          >
            {signup.groupName || 'Nie wprowadzona'}
          </SummaryRow>
          <SummaryRow
            label={<span>Cena jednostkowa</span>}
            description="Cena za jedno miejsce / powyżej 5 osób uwzględniająca potencjalne rabaty"
          >
            {prices.initialSinglePrice !== prices.singlePrice && <Strike>{prices.initialSinglePrice} zł</Strike>}{' '}
            {prices.singlePrice} zł
          </SummaryRow>
          <SummaryRow
            label={<span>Cena sumaryczna</span>}
            description="Podsumowanie kosztu wszystkich wybranych biletów"
          >
            {prices.totalPrice} zł
          </SummaryRow>
          {signup.places >= 5 ? (
            <SummaryRow
              label={<span>Rabat za ilość miejsc</span>}
              description="Wysokość udzielonego rabatu, ze względu na wykupioną liczbę miejsc"
            >
              {prices.placeDiscount} zł
            </SummaryRow>
          ) : null}
          {signup.codes.length ? (
            <SummaryRow label={<span>Suma zniek</span>} description="Suma zniżek dokonanych za pomocą kodów rabatowych">
              {prices.discount} zł
            </SummaryRow>
          ) : null}
          <SummaryRow label={<span>Opłata manipulacyjna 1,5%</span>} description="Prowizje banków / obsługa płatności">
            {prices.manipilation} zł
          </SummaryRow>
          <SummaryRow label={<ImportantRow>Kwota do zapłaty</ImportantRow>} description="Pozostała kwota do zapłaty">
            <ImportantRow>{prices.toPay} zł</ImportantRow>
          </SummaryRow>
        </tbody>
      </SummaryTable>
      <Coupons signup={signup} />

      <div>
        <CheckBoxWrapper>
          <Checkbox
            name="regulations"
            iconStyle={{ color: '#FF6600', fill: '#FF6600' }}
            style={{
              display: 'inline-block',
              position: 'absolute',
              width: 'auto'
            }}
            onCheck={() => setAcceptedRegulations(true)}
          />
          <span>
            Akceptuję{' '}
            <a href="https://www.survivalrace.pl/regulamin" target="_blank" rel="noopener noreferrer">
              regulamin
            </a>
          </span>
        </CheckBoxWrapper>
      </div>
      <StyledButtonGroup>
        <FakeLink onClick={() => handleSetStep(signup.step - 1)}>Wstecz</FakeLink>
        <Button
          loading={isSending}
          onClick={() => handleMakeSignup({ reservation: true })}
          disabled={!acceptedRegulations}
        >
          Zarezerwuj na 48h
        </Button>
        <Button
          onClick={() => handleMakeSignup({ reservation: false })}
          primary
          disabled={!acceptedRegulations}
          loading={isSending}
        >
          {prices.toPay === 0 ? 'Dokonaj zapisu' : 'Przejdź do płatności'}
        </Button>
      </StyledButtonGroup>
    </>
  );
};

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps) => ({
  event: getEventById(state, getSignup(state).event_id),
  event_category: getEventCategory(state, ownProps.signup.event_category_id),
  user: getUserProfile(state),
  isSending: getIsSigningUp(state),
  city: getCity(state, ownProps.signup.city_id)
});

const mapDispatch = {
  handleMakeSignup: SignupActions.signupRequest,
  handleSetStep: SignupActions.step
};

export default connect(mapState, mapDispatch)(SignupSummary);
