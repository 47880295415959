interface ICookiesOptions {
  days?: number;
  path?: string;
}

export function getCookie(key: string): object | string {
  const cookies = document.cookie.split(';');
  const found = cookies.find(cookie => {
    const [cookieName] = cookie.trim().split('=');
    return decodeURIComponent(cookieName) === key;
  });

  if (!found) {
    return null;
  }

  const cookie = decodeURIComponent(found.split('=')[1]);

  try {
    return JSON.parse(cookie);
  } catch (_) {
    return cookie || null;
  }
}

export const setCookie = (key: string, value: object | string, options: ICookiesOptions): void => {
  const { days, path } = options;
  const expires = days ? `; expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}` : '';

  const writePath = path ? `; path=${path}` : '';
  const stringifiedValue = typeof value === 'object' ? JSON.stringify(value) : String(value);
  const cookie = `${encodeURIComponent(key)}=${encodeURIComponent(stringifiedValue)}`;
  document.cookie = `${cookie}${expires}${writePath}`;
};

export const removeCookie = (key: string): void => setCookie(key, '', { days: -1 });
