import React from 'react';
import styled from '@emotion/styled';
import { AddMorePlacesModal } from './AddMorePlacesModal';

const StyledAdditionalWrapper = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
`;

const StyledSVG = styled('svg')`
  width: 60px;
  margin-right: 20px;
`;

const StyledCTA = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

export const BuyAdditionalPlace = ({ nextItemId, groupId }) => {
  const [modalOpened, setModalOpened] = React.useState(false);
  return (
    <>
      <tbody onClick={() => setModalOpened(true)}>
        <tr>
          <td>{nextItemId}</td>
          <td colSpan={4}>
            <StyledAdditionalWrapper>
              <StyledSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  fill="#fff"
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
                />
              </StyledSVG>
              <div>
                <StyledCTA>Większa ekipa? Dokup miejsca w wyścigu</StyledCTA>
                <div>Zapłać za dodatkowych zawodników w Twojej drużynie</div>
              </div>
            </StyledAdditionalWrapper>
          </td>
        </tr>
      </tbody>
      {modalOpened && <AddMorePlacesModal groupId={groupId} handleClose={() => setModalOpened(false)} />}
    </>
  );
};
