import { PROFILE } from './actions';
import defaultAvatar from '../../assets/default-av.png';

const initialState: IProfileState = {
  id: 0,
  name: '',
  surname: '',
  email: '',
  phone: '',
  size: '',
  sex: '',
  signups: [],
  email_verified: true,
  birthdate: new Date('01-01-1995'),
  avatar: defaultAvatar,
  terms_conditions_consent: false
};

export interface IProfileState {
  terms_conditions_consent: boolean;
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
  size: string;
  sex: string;
  signups: IUserSignup[];
  email_verified: boolean;
  birthdate?: Date;
  avatar?: string;
}

export interface IUserSignup {
  id: number;
  group_name: string;
  status: number;
  is_captain: number;
  wave_time: string;
  distance: number;
  category_name: string;
  event_name: string;
  event_date: string;
  city: string;
}

const deserializeProfile = profileData => {
  return {
    ...profileData,
    birthdate: profileData.birthdate ? new Date(profileData.birthdate) : null,
    avatar: profileData.avatar ? profileData.avatar : defaultAvatar
  };
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE.FETCH_PROFILE_SUCCESS: {
      const { payload } = action;

      const parsedPayload = deserializeProfile(payload);
      return {
        ...state,
        ...parsedPayload
      };
    }

    default:
      return state;
  }
}
