import React from 'react';
import CardText from 'material-ui/Card/CardText';
import { IGroup } from 'store/groups/reducer';
import { ICategory } from 'store/events/interfaces';
import { BuyAdditionalPlace } from './BuyAdditionalPlace';

interface IProps {
  group: IGroup;
  category: ICategory;
}

export const KidsView: React.FC<IProps> = ({ group, category }) => {
  const paidParticipants = group.signups.reduce((acc, val) => (val.status ? acc + 1 : acc), 0);
  const isGroupActive = paidParticipants > 0;

  return (
    <>
      <h2
        style={{
          color: '#fff',
          textTransform: 'uppercase',
          fontWeight: 700,
          paddingLeft: '1rem'
        }}
      >
        Rodzic / Opiekun
      </h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <div style={{ color: '#fff' }}>{group.signups[0].name}</div>
          <div
            style={{
              color: '#fff',
              opacity: 0.7,
              fontSize: '12px',
              lineHeight: '1'
            }}
          >
            {group.signups[0].email}
          </div>
        </div>
      </div>
      <CardText color="#fff" style={{ textAlign: 'center' }}>
        <div style={{ border: '1px solid rgba(255,255,255,.6)' }}>
          <h2
            style={{
              marginTop: '10px',
              marginBottom: '4px',
              textTransform: 'uppercase'
            }}
          >
            Gratulacje - zmieszasz dzieci z błotem
          </h2>
          <p
            style={{
              color: '#f60',
              fontWeight: 'bold',
              marginBottom: '4px',
              textTransform: 'uppercase'
            }}
          >
            Wykupione miejsca: {group.signups.length} <br /> Opłacone miejsca: {paidParticipants} <br />{' '}
            {group.category_name}
          </p>
          <p>
            Zgłoś się z nimi 30 minut przed startem by zdążyły się przygotować. <br />
            Co potrzebujesz?
            <br />
            1. Swój dowód osobisty (lub paszport) <br />
            2. Oświadczenie (
            <a href="https://drive.google.com/file/d/1uJqJGD3fnx02yNKNsA4DOJvbWokx52Gl/view">pobierz</a>)
          </p>
        </div>
      </CardText>
      {isGroupActive ? (
        <div className="group__challenge">
          <BuyAdditionalPlace nextItemId={group.signups.length + 1} groupId={group.id} />
        </div>
      ) : null}
    </>
  );
};
