import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createReducers } from './reducers';
import { runAppSagas } from './sagas';
import { history } from '../browser-history';

interface IWindowExtended {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

declare const window: IWindowExtended & Window;

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return {
    ...createStore(
      createReducers(history),
      initialState,
      composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    ),
    runSaga: runAppSagas(sagaMiddleware)
  };
};

export default configureStore();
