import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  max-width: 600px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  padding: 40px 50px;
  color: #fff;
  margin: 150px auto;
`;

export const Centered = styled.div`
  text-align: center;
`;
export const Logo = styled('img')`
  width: auto;
  height: 120px;
  margin: 0 auto;
`;

export const Title = styled('h1')`
  font-size: 32px;
  font-weight: 700;
  text-align: center;
`;

export const StyledTC = styled.span`
  font-size: 12px;
  line-height: 1.2;
  margin-left: 16px;
  color: #999;
`;

export const SelectAllTc = styled.span`
  font-size: 14px;
  line-height: 1.2;
  margin-left: 16px;
  color: #fff;
`;

export const labelStyles = css`
  display: flex;
`;
