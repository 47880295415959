import { call, takeEvery, put, delay, race } from 'redux-saga/effects';
import { Api } from 'services';
import { EventActions, EventActionsEnum } from './actions';
import { NotificationActions } from '../ui/notification/actions';
import { MessageType } from 'store/ui/notification/interfaces';

function* fetchEventData() {
  const { result, error } = yield call(Api.eventApi.fetch);

  if (error) {
    yield put(EventActions.fetchEventDataFailure(error.message));
    yield put(NotificationActions.create(error.message));
  }

  if (result) {
    yield put(EventActions.fetchEventDataSuccess(result.data));
    yield put(EventActions.fetchWavesRequest());
  }
}

function* fetchWavesData() {
  for (let i = 0; i < 10; i++) {
    const {
      request: { result }
    } = yield race({
      request: call(Api.eventApi.fetchWaves),
      timeout: delay(3000)
    });

    if (result) {
      yield put(EventActions.fetchWavesSuccess(result.data));
      return;
    }

    if (i < 9) {
      yield delay(2000);
    }
  }
  // attempts failed after 10 attempts

  yield put(
    NotificationActions.create({
      type: MessageType.Error,
      content: 'Nie udało się pobrać fal! Część funkcjonalności aplikacji może nie działać prawidłowo.'
    })
  );
}

export function* eventSaga() {
  yield takeEvery(EventActionsEnum.FETCH_DATA_REQUEST, fetchEventData);
  yield takeEvery(EventActionsEnum.FETCH_WAVES_REQUEST, fetchWavesData);
}
