import React from 'react';
import styled from '@emotion/styled';
import { ImagePicker } from 'components/Form/ImagePicker';
import { FileUploadError, FileUploadErrorType, IImageDimensionsBoundary } from 'helpers/file';
import { IProfileState } from 'store/user-profile/reducer';
import apiManager from 'services/api-manager';
import { LoaderCircle } from 'components/Loader/LoaderCircle';

const StyledAvatar = styled.div`
  max-width: 120px;
  max-height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const AlignCenter = styled.div`
  text-align: center;
  width: 120px;
  margin: 0 auto 30px;
`;

const AlignWithMargin = styled(AlignCenter)`
  padding: 28px 0;
`;

const LoadingWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 auto;
`;

const LoadingState = (): JSX.Element => (
  <AlignWithMargin>
    <LoadingWrapper>
      <LoaderCircle />
    </LoadingWrapper>
  </AlignWithMargin>
);

export async function uploadAvatar(file: File): Promise<void> {
  const data = new FormData();
  data.append('avatar_file', file, file.type === 'image/jpeg' ? 'upload.jpg' : file.name);

  const { error } = await apiManager.profileApi.uploadAvatar(data);

  if (error) {
    throw new FileUploadError('Could not upload avatar. Please try again later', FileUploadErrorType.ServerError);
  }
}

const dimensions: IImageDimensionsBoundary = {
  maxWidth: 1000,
  maxHeight: 1000,
  minWidth: 80,
  minHeight: 80
};

interface IProps {
  user: IProfileState;
  onFetchUser(): void;
  onDisplayError(message: string): void;
}

const Avatar: React.FC<IProps> = ({ user, onFetchUser, onDisplayError }) => {
  const [loading, setLoading] = React.useState(false);

  const handleFileUpload = (file: File) => {
    setLoading(true);

    uploadAvatar(file)
      .then(() => onFetchUser())
      .catch(error => onDisplayError(error.toString()))
      .finally(() => setLoading(false));
  };

  const onUploadError = (error: FileUploadError) => {
    onDisplayError(error.message);
    console.error(`[${error.type}] ${error.message}`);
  };

  if (loading) {
    return <LoadingState />;
  }

  return (
    <ImagePicker
      onChange={handleFileUpload}
      extensions={['jpg', 'jpeg', 'png', 'gif']}
      onError={onUploadError}
      dimensions={dimensions}
      maxSizeInMB={10}
    >
      <StyledAvatar>
        <img src={user.avatar} alt="avatar" />
      </StyledAvatar>
    </ImagePicker>
  );
};

export default Avatar;
