import React from 'react';
import styled from '@emotion/styled';
import { EventCard } from './Selectable';
import { IWave } from 'store/events/interfaces';

const StyledTitle = styled('span')`
  font-size: 15px;
  opacity: 0.8;
  line-height: 1.3;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const StyledTime = styled('h2')`
  margin: 0;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Available = styled('span')`
  font-size: 13px;
  line-height: 1.3;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

interface IProps {
  isSelected: boolean;
  handleSelect(wave: IWave): void;
  wave: IWave;
  date: string;
}

export const WaveCard: React.FC<IProps> = ({ isSelected, handleSelect, wave, date }) => (
  <EventCard selected={isSelected} onClick={() => handleSelect(wave)}>
    <StyledTitle>Godzina biegu</StyledTitle>
    <StyledTime>{wave.wave_time}</StyledTime>
    <Available>Wolne miejsca {wave.free_places}</Available>
    <Available>Data: {date}</Available>
  </EventCard>
);
