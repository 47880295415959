import React from 'react';
import { connect } from 'react-redux';

import { SignupActions } from '../../../store/ui/signup/actions';
import { Selectable } from './Selectable';
import { getSignup } from '../../../store/ui/signup/selectors';
import { getEvents } from 'store/events/selectors';
import { IEvent } from 'store/events/interfaces';
import { ISignupState } from 'store/ui/signup/interfaces';
import { Dispatch, AnyAction } from 'redux';

interface IDispatchProps {
  onSelectEvent(id: number): void;
}
interface IProps extends IDispatchProps {
  eventTypes: IEvent[];
  signup: ISignupState;
}

const EventTypeSelect: React.FC<IProps> = ({ signup, onSelectEvent, eventTypes }) => (
  <>
    {eventTypes.map(item => (
      <Selectable icon selected={signup.event_id === item.id} onClick={() => onSelectEvent(item.id)} key={item.id}>
        <img src={item.image} alt={item.event_name} />
      </Selectable>
    ))}
  </>
);

const mapState = state => ({
  signup: getSignup(state),
  eventTypes: getEvents(state)
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  onSelectEvent(id) {
    dispatch(SignupActions.type(id));
  }
});

export default connect(
  mapState,
  mapDispatch
)(EventTypeSelect);
