import React from 'react';
import styled from '@emotion/styled';
import { ModalPresenter } from './ModalPresenter';

interface StyledModalProps {
  width: string;
  height: string;
  allowOverflow?: boolean;
}

const StyledModal = styled.div<StyledModalProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: 90%;
  max-width: 600px;
  box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);

  @media (max-width: 768px) {
    max-height: 100%;
  }
`;

const StyledModalHeader = styled('h2')`
  padding: 20px;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  background-color: #000;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0;
  border-bottom: 1px solid #222;
`;

const StyledModalCloseButton = styled('button')`
  position: absolute;
  z-index: 1;
  right: 18px;
  top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  opacity: 0.7;
  outline: none;
  color: #fff;

  &:hover {
    opacity: 1;
  }
`;

const StyledModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #000;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: auto;
  color: #fff;
`;

const ESC_KEY_CODE = 27;

interface IProps {
  title?: string;
  width?: string;
  height?: string;
  closeModal(): void;
  closeOnEscClick?: boolean;
  isOpen?: boolean;
  allowOverflow?: boolean;
}

export class Modal extends React.Component<IProps> {
  static defaultProps = {
    closeOnEscClick: true,
    isOpen: true
  };

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp, true);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp, true);
  }

  onPresenterClick = event => {
    if (this.modalRef && !this.modalRef.current.contains(event.target)) {
      this.handleCloseModal();
    }
  };

  onCloseButtonClick = event => {
    event.preventDefault();
    this.handleCloseModal();
  };

  onKeyUp = event => {
    if (event.keyCode === ESC_KEY_CODE && this.props.closeOnEscClick) {
      this.handleCloseModal();
    }
  };

  handleCloseModal = () => {
    this.props.closeModal();
  };

  modalRef: React.RefObject<HTMLDivElement> = React.createRef();

  render() {
    const { title, isOpen, width, height, children, allowOverflow } = this.props;

    return (
      <ModalPresenter isOpen={isOpen} onClick={this.onPresenterClick}>
        <StyledModal width={width} height={height} ref={this.modalRef} allowOverflow={allowOverflow}>
          {!!title && <StyledModalHeader>{title}</StyledModalHeader>}
          <StyledModalCloseButton title="Close modal" onClick={this.onCloseButtonClick}>
            <i className="material-icons">close</i>
          </StyledModalCloseButton>
          <StyledModalBody>{children}</StyledModalBody>
        </StyledModal>
      </ModalPresenter>
    );
  }
}
