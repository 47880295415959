/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';

import { css } from '@emotion/react';

interface IProps {
  className?: string;
  multiple?: boolean;
  onChange: (file: File) => void;
}

const inputCss = css`
  display: none;
`;

export class FileInput extends React.PureComponent<IProps> {
  private fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  private handleFilePick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = event.target;
    const { onChange } = this.props;

    if (files) {
      Array.from(files).forEach(file => {
        onChange(file);
      });
      this.fileInputRef.current.value = null;
    }
  };

  getChildren = () => {
    const { children } = this.props;

    if (typeof children === 'function') {
      return children({ onClick: () => this.fileInputRef.current.click() });
    }

    return React.Children.map(children, child =>
      React.cloneElement(child as React.ReactElement, {
        onClick: () => this.fileInputRef.current.click()
      })
    );
  };

  render(): JSX.Element {
    const { className, multiple } = this.props;
    const childrenWithProps = this.getChildren();

    return (
      <div className={className}>
        <input css={inputCss} type="file" multiple={multiple} onChange={this.handleFilePick} ref={this.fileInputRef} />
        {childrenWithProps}
      </div>
    );
  }
}
