import React from 'react';
import classnames from 'classnames';
import { Button } from 'components/Button';

interface IProps {
  afterClick?(): void;
  openNext(): void;
  openCard(index: number): void;
  index: string;
  choice?: string;
  title: string;
  subtitle: string;
  buttonLabel: string;
  isRequired: boolean;
  isOpen: boolean;
  isFilled: boolean;
}

interface IState {
  show: boolean;
  doubleClick: boolean;
}

class SignupsCard extends React.Component<IProps, IState> {
  state = {
    show: false,
    doubleClick: false
  };

  static defaultProps = {
    afterClick: () => {}
  };

  getLabel = () => {
    return this.props.isRequired ? 'Część obowiązkowa' : 'Wybór nieobowiązkowy';
  };

  showTooltip = () => {
    this.setState({ show: true });
  };

  hideTooltip = () => {
    this.setState({ show: false });
  };

  handleClick = () => {
    this.setState({ doubleClick: !this.state.doubleClick });
    this.props.openNext();
    this.props.afterClick();

    setTimeout(
      function() {
        this.setState({ doubleClick: !this.state.doubleClick });
      }.bind(this),
      1000
    );
  };

  render() {
    const cardClasses = classnames({
      'signups-card': true,
      'signups-card__open': this.props.isOpen,
      'signups-card__filled': this.props.isFilled
    });

    const choice = !!this.props.choice ? this.props.choice : '';

    return (
      <article className={cardClasses}>
        <header onClick={() => this.props.openCard} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip}>
          <em>{this.props.index + '.'}</em>
          <h2>{this.props.title}</h2>
          <p>{this.props.subtitle}</p>
          <strong>{choice}</strong>
          {this.state.show && <span className="signup-card__label">{this.getLabel()}</span>}
        </header>
        <section>
          {this.props.children}
          <footer>
            <Button primary disabled={!this.props.isFilled || this.state.doubleClick} onClick={this.handleClick}>
              {this.props.buttonLabel ? this.props.buttonLabel : 'Przejdź dalej'}
            </Button>
          </footer>
        </section>
      </article>
    );
  }
}

export default SignupsCard;
