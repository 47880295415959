import { BaseApi } from '../base';

export default class ProfileAPI extends BaseApi {
  fetch = () => this.get(`user`);
  update = body => this.post(`user/profile`, body);
  acceptTermsConditions = accepted =>
    this.post(`user/accept-terms-conditions`, {
      terms_conditions_consent: accepted
    });
  logout = () => this.post(`user-logout`);
  resendVerificationEmail = () => this.post('email/confirm/resend');
  uploadAvatar = (payload: FormData) => this.post('user/avatar', payload);
}
