import { call, takeEvery, put, select } from 'redux-saga/effects';
import { PROFILE, ProfileActions } from './actions';
import { Api } from '../../services';
import { captureEvent } from '@sentry/browser';

import { EventActions } from '../events/actions';
import { NotificationActions } from '../ui/notification/actions';
import { redirectToUrl } from '../helpers';
import { removeCookie } from 'components/helpers/cookies';
import { MessageType } from 'store/ui/notification/interfaces';
import { GlobalModalActions } from 'store/ui/global-modals/actions';
import { GlobalModal } from 'enums/global-modals';
import { getIsEmailVerified, getTermsConditionsConfirmed } from './selectors';

function* fetchUserData() {
  const { result, error } = yield call(Api.profileApi.fetch);

  if (result) {
    yield put(EventActions.fetchEventDataRequest());
    yield put(ProfileActions.fetchProfileSuccess(result.data));
  }

  const termsConditionsConfirmed = yield select(getTermsConditionsConfirmed);

  if (!termsConditionsConfirmed) {
    yield put(GlobalModalActions.showModal(GlobalModal.TermsConditions));
  }

  const isEmailVerified = yield select(getIsEmailVerified);

  if (termsConditionsConfirmed && !isEmailVerified) {
    yield put(GlobalModalActions.showModal(GlobalModal.EmailVerificationModal));
  }

  if (error) {
    yield put(ProfileActions.fetchProfileFailure(error.message));
    yield put(
      NotificationActions.create({
        content: `Wystąpił błąd. Zostałeś wylogowany. ${JSON.stringify(error.message)}`,
        type: MessageType.Info
      })
    );

    captureEvent({ message: error.message });

    yield call(removeCookie, 'access_token');
  }
}

function* updateUserData(data) {
  const { onSuccess, ...request } = data.payload;
  const { result, error } = yield call(Api.profileApi.update, request);

  if (error) {
    yield put(ProfileActions.updateProfileFailure(error.message));
    yield put(NotificationActions.create(error.message));
  }

  if (result) {
    yield put(NotificationActions.create(result.message));
    yield put(ProfileActions.updateProfileSuccess());
    yield call(onSuccess);
  }
}

function* handleUserLogout() {
  yield call(removeCookie, 'access_token');
  yield call(redirectToUrl, '/');
}

export function* profileSaga() {
  yield takeEvery(PROFILE.FETCH_PROFILE_REQUEST, fetchUserData);
  yield takeEvery(PROFILE.UPDATE_PROFILE_REQUEST, updateUserData);
  yield takeEvery(PROFILE.UPDATE_PROFILE_SUCCESS, fetchUserData);
  yield takeEvery(PROFILE.LOGOUT, handleUserLogout);
}
