import styled from '@emotion/styled';

interface IStyledButtonProps {
  right?: boolean;
  fullWidth?: boolean;
  spaceBottom?: boolean;
  stackOnMobile?: boolean;
}

export const ButtonGroup = styled.div<IStyledButtonProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -8px;
  width: ${props => (props.fullWidth ? '100%' : '')};

  ${props => (props.spaceBottom ? 'margin-bottom: 1rem;' : '')}

  & > * {
    margin-right: 8px;
    margin-top: 8px;
    flex: ${props => (props.fullWidth ? '1' : '')};
  }

  ${props =>
    props.right
      ? `
    justify-content: flex-end;
  `
      : ''};

  ${props =>
    props.stackOnMobile &&
    `
  @media (max-width: 600px) {
    flex-direction: column;
  }`}
`;
