import { createAction } from '../helpers';

export function appReady() {
  return createAction('APP_READY');
}

export function propertiesReady(properties) {
  return createAction('PROPERTIES_READY', properties);
}

export enum AuthActionsEnum {
  LOGIN_USER_REQUEST = 'AUTH/LOGIN_USER_REQUEST',
  LOGIN_USER_SUCCESS = 'AUTH/LOGIN_USER_SUCCESS',
  LOGIN_USER_FAILURE = 'AUTH/LOGIN_USER_FAILURE',
  REGISTER_USER_REQUEST = 'AUTH/REGISTER_USER_REQUEST',
  REGISTER_USER_SUCCESS = 'AUTH/REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE = 'AUTH/REGISTER_USER_FAILURE'
}

export const AuthActions = {
  loginUserRequest: ({ email, password }) => createAction(AuthActionsEnum.LOGIN_USER_REQUEST, { email, password }),
  loginUserSuccess: ({ token, expires_in, source }) =>
    createAction(AuthActionsEnum.LOGIN_USER_SUCCESS, { token, expires_in, source }),
  loginUserFailure: data => createAction(AuthActionsEnum.LOGIN_USER_FAILURE, data)
};
