import React from 'react';
import { MobileMenu } from './MobileMenu';
import { Navigation } from './Navigation';

interface IProps {
  logoutUser(): void;
}

const NavigationContainer: React.FC<IProps> = ({ logoutUser }) => (
  <>
    <MobileMenu />
    <div className="mesr-profile-nav-container">
      <div className="mesr-profile-nav">
        <Navigation logoutUser={logoutUser} />
      </div>
    </div>
  </>
);

export default NavigationContainer;
