import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import { isEmpty } from 'lodash';
import { Api } from 'services';
import { IGroup } from 'store/groups/reducer';

interface IState {
  loading: boolean;
  groupId: string;
  group: IGroup;
}

interface IProps {
  signupId: string;
  onAddMessage(data): void;
  onSubmitDialog(data): void;
  onHideDialog(): void;
  show: boolean;
  bodyStyle: any;
}

class SignupChangeGroupDialog extends React.Component<IProps, IState> {
  state: IState = {
    loading: false,
    groupId: '',
    group: {} as IGroup
  };

  onGroupIdChange = ev => {
    const groupId = ev.target.value;
    this.setState({ groupId });
  };

  onSubmitDialog = async () => {
    this.setState({ loading: true });

    const { groupId } = this.state;

    if (!groupId) {
      return;
    }
    const { result, error } = (await Api.signupApi.groupVerifyTransfer(groupId, this.props.signupId)) as any;

    if (result) {
      this.props.onAddMessage(result.message);
      this.setState({ group: result.data, loading: false });
    } else {
      this.props.onAddMessage(error.message);
      this.setState({ loading: false, groupId: '' });
    }
  };

  renderGroup = () => {
    const group = this.state.group;
    const captain = group.signups.find(u => u.is_captain);

    return (
      <div className="change-group-info">
        <h2>{group.name}</h2>
        <h3>{'Grupa nr ' + group.id}</h3>
        <table>
          <tbody>
            <tr>
              <td>Wydarzenie:</td>
              <td>
                {group.event_name} {group.city_name}
              </td>
            </tr>
            <tr>
              <td>Data:</td>
              <td>{group.event_date}</td>
            </tr>
            <tr>
              <td>Dystans:</td>
              <td>{group.distance} KM</td>
            </tr>
            <tr>
              <td>Fala:</td>
              <td>{group.wave_time}</td>
            </tr>
            <tr>
              <td>Kapitan:</td>
              <td>{captain.name ? captain.name : captain.email}</td>
            </tr>
          </tbody>
        </table>
        <div>
          <RaisedButton
            labelColor={'#FFFFFF'}
            backgroundColor="#FF6600"
            label="Zmień grupę"
            onClick={this.onGroupChange}
          />
        </div>
      </div>
    );
  };

  onGroupChange = () => {
    if (this.state.groupId) {
      this.props.onSubmitDialog(this.state.groupId);
      this.setState({ groupId: '', group: null });
    }
  };

  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Zmień grupę"
        disabled={!isEmpty(this.state.group)}
        labelStyle={{
          color: !isEmpty(this.state.group) ? '#DDDDDD' : '#FF6600'
        }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Zmień grupę"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p>Podaj numer grupy, do której chcesz się przenieść.</p>

        <div className="group-input">
          <input
            type="text"
            placeholder="Wprowadź numer grupy"
            value={this.state.groupId}
            onChange={this.onGroupIdChange}
          />
        </div>

        {this.state.loading ? (
          <div className="change-group-loading">
            <CircularProgress mode="indeterminate" size={60} />
          </div>
        ) : (
          false
        )}

        {!isEmpty(this.state.group) && !this.state.loading ? this.renderGroup() : false}
      </Dialog>
    );
  }
}

export default SignupChangeGroupDialog;
