import * as dateFns from 'date-fns';

export interface ICountdown {
  day: number;
  hour: number;
  minute: number;
  second: number;
}

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export function humanizeFutureToNow(fDate: Date): ICountdown {
  const result: ICountdown = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0
  };

  const now = new Date();
  const parts = ['day', 'hour', 'minute', 'second'];

  parts.forEach((p, i) => {
    let uP = capitalize(p);
    let difference = dateFns[`differenceIn${uP}s`](fDate, now);

    if (difference) {
      result[p] = difference;
      if (i < parts.length) fDate = dateFns[`sub${uP}s`](fDate, difference);
    }
  });

  return result;
}
