import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import Checkbox from 'material-ui/Checkbox';
import { trackEcommerce, trackEvent } from '../helpers/eventTracking';
import { NotificationActions } from '../../store/ui/notification/actions';
import { ProfileActions } from '../../store/user-profile/actions';
import { IStoreStateInterface } from 'store/reducers';
import { IProfileState } from 'store/user-profile/reducer';
import { IEventsState } from 'store/events/interfaces';
import { MessageType } from 'store/ui/notification/interfaces';
import { Dispatch, AnyAction } from 'redux';
import { IGroup } from 'store/groups/reducer';
import { Api } from 'services';
import SignupsCard from 'components/shared/SignupsCard';
import { getManipulationPrice } from 'store/events/selectors';
import styled from '@emotion/styled';
import { getUserProfile } from 'store/user-profile/selectors';

const AdditionsWrapper = styled.div`
  display: block;
  max-width: 780px;
  width: 100%;
  background: rgba(10, 10, 10, 0.9);
  margin: 0 auto 8%;
  box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);
  backdrop-filter: saturate(180%) blur(10px);

  @media (max-height: 800px) {
    margin: 25px auto;
  }

  @media (max-width: 800px) {
    margin: 0 auto;
    min-height: calc(100vh - 60px);
  }

  .challenge-card {
    text-align: left;
  }
  .card-input {
    text-align: center;
  }
  .card-input input {
    margin-bottom: 0.75rem;
  }
  .invite-group-info,
  .invite-group-summary {
    color: #fff;
    text-align: center;
  }
  .invite-group-info h2,
  .invite-group-summary h2 {
    margin-bottom: 0;
    font-family: 'Lordcorps', cursive;
    font-size: 2.25rem;
    font-size: 3.25rem;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
  }
  .invite-group-info h3,
  .invite-group-summary h3 {
    margin-bottom: 0.75rem;
    color: #ff6600;
  }
  .invite-group-info table,
  .invite-group-summary table {
    border: 1px solid #222;
    border-bottom: none;
    width: 100%;
  }
  .invite-group-info table tr,
  .invite-group-summary table tr {
    border-bottom: 1px solid #222;
  }
  .invite-group-info table td,
  .invite-group-summary table td {
    padding: 0.25rem 0.75rem;
    width: 50%;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
  }
  .invite-group-info table td:first-child,
  .invite-group-summary table td:first-child {
    font-weight: 700;
    text-align: right;
  }
  .invite-group-info .signups-codes,
  .invite-group-summary .signups-codes {
    border-bottom: none;
    padding: 0.75rem 0 !important;
    text-align: center;
  }
  .invite-group-info .signups-codes .code-verification-button,
  .invite-group-summary .signups-codes .code-verification-button {
    padding-top: 0.75rem;
    text-align: center;
  }
  .invite-group-info .signups-codes input,
  .invite-group-summary .signups-codes input {
    color: #474747;
  }
  .invite-group-summary {
    padding: 1.5rem 0;
  }
  .invite-group-summary h2 {
    margin-bottom: 0.75rem;
    font-family: inherit;
    font-size: 1.25rem;
    font-weight: 700;
    color: #ff6600;
  }
  .invite-group-summary h3 {
    color: #ff6600;
  }
  .invite-group-summary p {
    margin-bottom: 1.5rem;
  }
  .invite-group-summary em {
    margin-left: 0.75rem;
    font-size: 1.125rem;
    font-weight: bold;
    font-style: normal;
    color: #222;
  }
`;

interface IOwnProps extends RouteComponentProps<{ id?: string; code?: string }> {}

interface IAddition {
  price: number;
}

interface IStateProps {
  user: IProfileState;
  event: IEventsState;
}

interface IDispatchProps {
  showToast(message: string, severity: MessageType): void;
  fetchProfile(): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

interface IState {
  regulations: boolean;
  loading: boolean;
  openIndex: number;
  groupId: string;
  groupCode: string;
  group?: IGroup & IAddition;
}

class AdditionsView extends React.Component<IProps, IState> {
  state: IState = {
    regulations: false,
    loading: false,
    openIndex: 1,
    groupId: this.props.match.params.id || '',
    groupCode: this.props.match.params.code || '',
    group: null
  };

  componentDidMount() {
    const pageName = 'Dołącz do grupy';
    const path = window.location.pathname;

    trackEvent('VirtualPageView', {
      virtualPageTitle: pageName,
      virtualPagePath: path,
      user: this.props.user && this.props.user.id
    });
  }

  openCard = openIndex => {
    if (openIndex === 2 && !this.state.group && !this.state.loading) {
      return;
    }

    this.setState({ openIndex });
  };

  isCardOpen = index => {
    return index === this.state.openIndex;
  };

  onGroupChange = ev => {
    const groupId = ev.target.value;
    this.setState({ groupId });
  };

  onCodeChange = ev => {
    const groupCode = ev.target.value.toLowerCase();
    this.setState({ groupCode });
  };

  verifyGroup = async () => {
    const { groupId, groupCode } = this.state;

    if (!groupId) {
      this.props.showToast('Podaj numer grupy', MessageType.Error);
      return;
    }
    this.setState({ loading: true });
    const { result, error } = (await Api.additionsApi.groupVerify(parseInt(groupId), groupCode)) as any;

    if (error) {
      this.props.showToast(error.message.content, error.message.type);
      this.setState({ loading: false });
      return;
    }

    this.setState({ group: result.data, loading: false, openIndex: 2 });
  };

  signupToGroup = async () => {
    const { group } = this.state;

    let totalPrice = 0;

    const { groupId, groupCode } = this.state;

    if (groupCode) {
      const request = await Api.additionsApi.acceptInvitation(parseInt(groupId), groupCode);

      if (request.result) {
        this.props.showToast('Udało się dopisać do grupy', MessageType.Success);
        this.props.fetchProfile();
        this.props.history.push(`/grupa/${groupId}`);
      }
    } else {
      const request = (await Api.additionsApi.payToJoin(parseInt(groupId))) as any;

      if (request.result) {
        this.props.showToast('Udało się dopisać do grupy', MessageType.Success);
        this.props.history.push(`/grupa/${groupId}`);

        if (request.result.data.payment_url) {
          setTimeout(() => {
            window.location.href = request.result.data.payment_url;
          }, 500);
        }
      }
    }

    trackEvent('Purchase', {
      signupEvent: group,
      totalPrice,
      currency: 'PLN',
      value: Math.round(totalPrice)
    });

    trackEcommerce({
      price: totalPrice,
      id: group.id,
      city: group.city_name,
      distance: group.distance,
      hour: group.wave_time,
      seats: group.signups && group.signups.length,
      coupon: null
    });
  };

  acceptRegulations = (ev, regulations) => {
    this.setState({ regulations });
  };

  renderGroup = () => {
    const { group } = this.state;

    if (group.signups.length === 0) {
      return 'Grupa nieaktywna';
    }
    const captain = group.signups.find(u => u.is_captain);

    return (
      <div className="invite-group-info">
        <h2>{group.name}</h2>
        <h3>{'Grupa nr ' + group.id}</h3>
        <table>
          <tbody>
            <tr>
              <td>Wydarzenie:</td>
              <td>
                {group.event_name} {group.city_name}
              </td>
            </tr>
            <tr>
              <td>Data:</td>
              <td>{group.event_date}</td>
            </tr>
            <tr>
              <td>Dystans:</td>
              <td>
                {group.distance} KM - {group.category_name}
              </td>
            </tr>
            <tr>
              <td>Fala:</td>
              <td>{group.wave_time}</td>
            </tr>
            <tr>
              <td>Kapitan:</td>
              <td>{captain.name}</td>
            </tr>
            {!this.state.groupCode ? (
              <>
                <tr>
                  <td>Cena:</td>
                  <td>{group.price} zł</td>
                </tr>
                <tr>
                  <td>Do zapłaty:</td>
                  <td>{group.price + getManipulationPrice(group.price)} zł</td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
        <div className="checkbox-regulations">
          <Checkbox
            name="regulations"
            iconStyle={{ color: '#FF6600', fill: '#FF6600' }}
            style={{
              position: 'absolute',
              top: '1.5rem',
              left: '0',
              width: '1.5rem'
            }}
            onCheck={this.acceptRegulations}
          />
          Akceptuję{' '}
          <a href="https://www.survivalrace.pl/regulamin" target="_blank" rel="noopener noreferrer">
            regulamin
          </a>
        </div>
      </div>
    );
  };

  render() {
    const { group, groupId, groupCode, loading, regulations } = this.state;

    return (
      <AdditionsWrapper>
        <SignupsCard
          index="1"
          title="Znajdź i zweryfikuj grupę"
          subtitle="Wprowadź numer i kod autoryzacyjny grupy, do której chcesz dołączyć."
          buttonLabel="Zweryfikuj grupę"
          isRequired={true}
          isOpen={this.isCardOpen(1)}
          isFilled={groupId !== null && groupCode !== null}
          openNext={this.verifyGroup}
          openCard={this.openCard.bind(null, 1)}
        >
          <div className="card-input">
            <input type="text" placeholder="Wprowadź numer grupy" value={groupId} onChange={this.onGroupChange} />
            <input
              type="text"
              placeholder="Wprowadź kod weryfikacyjny (jeśli posiadasz)"
              value={groupCode}
              onChange={this.onCodeChange}
            />
          </div>

          {loading ? (
            <div className="invite-group-info">
              <CircularProgress mode="indeterminate" size={60} />
            </div>
          ) : null}
        </SignupsCard>

        <SignupsCard
          index="2"
          choice={group && regulations ? 'Tak' : ''}
          title={!!groupCode ? 'Dołącz do grupy' : 'Opłać udział w grupie'}
          subtitle={
            !!groupCode
              ? 'Dołączasz do grupy na miejsce opłacone. Sprawdź poprawność danych.'
              : 'Dołączasz do grupy na miejsce nieopłacone. '
          }
          buttonLabel={!!groupCode ? 'Dołącz do grupy' : 'Opłać udział w grupie'}
          isRequired={true}
          isOpen={this.isCardOpen(2)}
          isFilled={group && regulations}
          openNext={this.signupToGroup}
          openCard={this.openCard.bind(null, 2)}
        >
          {group ? this.renderGroup() : false}
        </SignupsCard>
      </AdditionsWrapper>
    );
  }
}

const mapState = (state: IStoreStateInterface): IStateProps => ({
  user: getUserProfile(state),
  event: state.event
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  fetchProfile() {
    dispatch(ProfileActions.fetchProfileRequest());
  },
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(mapState, mapDispatch)(AdditionsView);
