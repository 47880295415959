import { trackEvent } from 'components/helpers/eventTracking';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserProfile } from 'store/user-profile/selectors';

declare const window: any;

export function useTrackPageview(pageName: string, path: string): void {
  const user = useSelector(getUserProfile);
  useEffect(() => {
    trackEvent('VirtualPageView', {
      virtualPageTitle: pageName,
      virtualPagePath: path,
      user: user && user.id
    });

    window.fbq('track', 'PageView', { pageID: pageName, url: path });
    window.ga('send', 'pageview', path);
  }, [pageName, path, user]);
}
