import React from 'react';
import { GiftIcon, LightningBoltIcon, PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

interface IProps {
  onGroupChangeName(): void;
  onGroupChallange(): void;
  onGroupChangeWave(): void;
  onGroupDelete(): void;
}

export const ManageGroupDropdown: React.FC<IProps> = props => {
  return (
    <div className="ml-auto">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-srOrange hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                Zarządzaj grupą
                <ChevronDownIcon
                  className="w-5 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={props.onGroupChangeName}
                        className={`${
                          active ? 'bg-srOrange' : 'text-gray-900'
                        } group flex items-center w-full px-2 py-2 text-sm`}
                      >
                        <div className={`w-5 h-5 mr-2 ${!active ? 'text-srOrange' : ''}`}>
                          <PencilAltIcon />
                        </div>
                        Edytuj nazwę
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={props.onGroupChangeWave}
                        className={`${
                          active ? 'bg-srOrange' : 'text-gray-900'
                        } group flex items-center w-full px-2 py-2 text-sm`}
                      >
                        <div className={`w-5 h-5 mr-2 ${!active ? 'text-srOrange' : ''}`}>
                          <LightningBoltIcon />
                        </div>
                        Edytuj falę
                      </button>
                    )}
                  </Menu.Item>

                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={props.onGroupChallange}
                        className={`${
                          active ? 'bg-srOrange' : 'text-gray-900'
                        } group flex items-center w-full px-2 py-2 text-sm`}
                      >
                        <div className={`w-5 h-5 mr-2 ${!active ? 'text-srOrange' : ''}`}>
                          <GiftIcon />
                        </div>
                        Zaproś do grupy
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={props.onGroupDelete}
                        className={`${
                          active ? 'bg-red-600 text-white' : 'text-gray-900'
                        } group flex items-center w-full px-2 py-2 text-sm`}
                      >
                        <div className={`w-5 h-5 mr-2 ${!active ? 'text-red-600' : ''}`}>
                          <TrashIcon />
                        </div>
                        Usuń grupę
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
};
