import React from 'react';
import Dialog from 'material-ui/Dialog';
import CardText from 'material-ui/Card/CardText';
import { Api } from 'services';
import { getManipulationPrice } from 'store/events/selectors';
import { Button, ButtonGroup } from 'components/Button';

interface IProps {
  paymentId: number;
  show: boolean;
  onHideDialog(): void;
}

const PaymentModal: React.FC<IProps> = ({ paymentId, show, onHideDialog }) => {
  const [payment, setPayment] = React.useState({} as any);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      const response = (await Api.signupApi.paymentDetails(paymentId)) as any;

      if (response.result) {
        setPayment(response.result.data);
        setLoading(false);
      }
    }

    fetchData();
  }, [paymentId]);

  const handleGoToPayment = React.useCallback(() => {
    window.location.assign(payment.payment_url);
  }, [payment]);

  if (loading) {
    return null;
  }

  const customActions = [
    <ButtonGroup right>
      <Button onClick={onHideDialog}>Wróć</Button>
      <Button primary onClick={handleGoToPayment}>
        Opłać grupę
      </Button>
    </ButtonGroup>
  ];

  return (
    <Dialog open={show} title="Podsumowanie płatności" actions={customActions} onRequestClose={onHideDialog}>
      <CardText className="signup-payment-summary">
        <strong>Cena zapisu:</strong>
        <em>{payment.price + ' zł'}</em>
        <br />

        <strong>Opłata manipulacyjna:</strong>
        <em>{getManipulationPrice(payment.price) + ' zł'}</em>
        <br />
        <strong>Do zapłaty:</strong>
        <em>{payment.price + getManipulationPrice(payment.price) + ' zł'}</em>
        <div>
          <small>Wszelkie kupony rabatowe zostały wliczone w ostateczną cenę zapisu</small>
        </div>

        <div>
          <small>Zostaniesz przekierowany na stronę, gdzie należy dokonać płatności</small>
        </div>
      </CardText>
    </Dialog>
  );
};

export default PaymentModal;
