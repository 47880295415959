const initialState: IRequestsState = {};

interface IRequest {
  error?: string;
  isFetching: boolean;
}

export interface IRequestsState {
  [key: string]: IRequest;
}

const requestsReducer = (state = initialState, action) => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) {
    return state;
  }

  const [, requestActionName, requestState] = matches;

  return {
    ...state,
    [requestActionName]: {
      isFetching: requestState === 'REQUEST',
      error: requestState === 'FAILURE' ? action.payload.error : null
    }
  };
};

export default requestsReducer;
