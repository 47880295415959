/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import * as Yup from 'yup';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { InputField } from '@livechat/design-system';
import { Api } from 'services';

import { getMetrics } from 'helpers/metrics-obtainer';
import { AuthActions } from 'store/app/actions';
import { IStoreStateInterface } from 'store/reducers';
import { Button, ButtonGroup } from 'components/Button';
import { Title, Logo, Centered, StyledTC, labelStyles, SelectAllTc } from './components/styled';
import { StyledSignupWrapper } from 'routes/signup/SignupWrapper';
import { NotificationActions } from 'store/ui/notification/actions';
import { MessageType } from 'store/ui/notification/interfaces';
import LoginWithGoogle from './components/LoginWithGoogle';
import LoginWithFacebook from './components/LoginWithFacebook';

import logo from '../../assets/mesr-badge.svg';

const RegisterSchema = Yup.object().shape({
  nick: Yup.string().required('Wymagane'),
  email: Yup.string()
    .email('Niepoprawny adres email')
    .required('Wymagane'),
  password: Yup.string().required('Wymagane'),
  tc: Yup.boolean().oneOf([true], 'Wymagana akceptacja regulaminu'),
  marketing_consent_email: Yup.boolean(),
  marketing_consent_phone: Yup.boolean(),
  marketing_consent_partners: Yup.boolean()
});

interface IStateProps {}

interface IDispatchProps {
  loginUser(data: IUserLoginData): void;
  showToast(message: string, severity: MessageType): void;
}

interface IUserLoginData {
  token: string;
  expires_in: number;
}

interface IProps extends IStateProps, IDispatchProps {}

const FormikInput = ({ field, form, ...props }) => {
  return <InputField {...field} {...props} htmlFor={field.name} id={field.name} />;
};

const RegistrationForm: React.FC<IProps> = ({ loginUser, showToast }) => {
  return (
    <StyledSignupWrapper>
      <Centered>
        <Logo src={logo} />
      </Centered>
      <Title>Załóż konto</Title>
      <ButtonGroup fullWidth spaceBottom stackOnMobile>
        <LoginWithGoogle />
        <LoginWithFacebook />
      </ButtonGroup>
      <Formik
        validationSchema={RegisterSchema}
        initialValues={{
          password: '',
          email: '',
          nick: '',
          tc: false,
          marketing_consent_email: false,
          marketing_consent_phone: false,
          marketing_consent_partners: false
        }}
        onSubmit={async (values, actions) => {
          const { email, nick: name, password, tc, ...rest } = values;
          const metrics = getMetrics();

          const { result, error } = (await Api.authApi.register({
            name,
            email,
            password,
            terms_conditions_consent: tc,
            ...rest,
            ...metrics
          })) as any;

          if (result) {
            loginUser({
              token: result.data.token,
              expires_in: result.data.expires_in
            });
          }

          if (error) {
            try {
              Object.keys(error.errors).map(field => actions.setFieldError(field, error.errors[field][0]));
            } catch (e) {
              showToast('Wystąpił błąd podczas zakładania konta. Spróbuj ponownie później.', MessageType.Error);
            }
          }

          actions.setSubmitting(false);
        }}
        render={({ errors, touched, isSubmitting, submitCount, setFieldValue, values }) => {
          const selectAllCheckboxes = () => {
            setFieldValue('marketing_consent_email', true);
            setFieldValue('marketing_consent_phone', true);
            setFieldValue('marketing_consent_partners', true);
            setFieldValue('tc', true);
          };

          return (
            <Form>
              <Field
                type="text"
                name="nick"
                placeholder="Imię / pseudonim"
                component={FormikInput}
                error={touched.nick && errors.nick}
              />
              <Field
                type="email"
                name="email"
                placeholder="Email"
                component={FormikInput}
                error={touched.email && errors.email}
              />
              <Field
                type="password"
                name="password"
                placeholder="Hasło"
                component={FormikInput}
                error={touched.password && errors.password}
              />
              <div>
                <label css={labelStyles}>
                  <input type="checkbox" onClick={selectAllCheckboxes} />
                  <SelectAllTc>
                    <strong>Zaznacz wszystkie zgody</strong>
                  </SelectAllTc>
                </label>
                <label css={labelStyles}>
                  <Field type="checkbox" name="tc" checked={values.tc} />
                  <StyledTC>
                    Akceptuję postanowienia <a href="https://www.survivalrace.pl/regulamin">Regulaminu Survival Race</a>{' '}
                    i <a href="https://www.survivalrace.pl/polityka-prywatnosci">Polityki Prywatności</a> oraz wyrażam
                    zgodę na przetwarzanie moich danych osobowych przez Polską Fundację na Rzecz Promocji Sportu Akara z
                    siedzibą we Wrocławiu, dla potrzeb niezbędnych do realizacji procesu rejestracji i uczestnictwa w
                    biegu Survival Race (zgodnie z Ustawą z dnia 29.08.1997 roku o Ochronie Danych Osobowych; (tekst
                    jednolity: Dz. U. 2016 r. poz. 922).
                  </StyledTC>
                </label>
                <span className="lc-field-error">{submitCount > 0 && errors.tc}</span>
              </div>

              <div>
                <label css={labelStyles}>
                  <Field type="checkbox" name="marketing_consent_email" checked={values.marketing_consent_email} />
                  <StyledTC>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych, poprzez przesyłanie
                    informacji na podany adres e-mail, zgodnie z ustawą o świadczeniu usług drogą elektroniczną z dnia
                    18 lipca 2002 r. (Dz. U z 2013 r., poz. 1422 ze zm.)
                  </StyledTC>
                </label>
              </div>

              <div>
                <label css={labelStyles}>
                  <Field type="checkbox" name="marketing_consent_phone" checked={values.marketing_consent_phone} />
                  <StyledTC>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych w celach marketingowych, poprzez przesyłanie
                    informacji na podany numer telefonu, zgodnie z ustawą z dnia 16 lipca 2004 r. Prawo
                    telekomunikacyjne (Dz. U z 2014 r. poz. 243 ze zm.).
                  </StyledTC>
                </label>
              </div>

              <div>
                <label css={labelStyles}>
                  <Field
                    type="checkbox"
                    name="marketing_consent_partners"
                    checked={values.marketing_consent_partners}
                  />
                  <StyledTC>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych przez Partnerów i podmioty współpracujące z
                    Polską Fundacją na Rzecz Promocji Sportu Akara.
                  </StyledTC>
                </label>
              </div>

              <div style={{ margin: '10px 0' }}>
                <Button submit primary fullWidth disabled={isSubmitting}>
                  Załóż konto
                </Button>
              </div>
            </Form>
          );
        }}
      />
      Masz już konto? <Link to="/logowanie">Zaloguj się!</Link>
      <span style={{ float: 'right' }}>
        <Link to="/haslo">Zresetuj hasło</Link>
      </span>
    </StyledSignupWrapper>
  );
};

const mapState = (state: IStoreStateInterface): IStateProps => ({});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  loginUser(data) {
    dispatch(AuthActions.loginUserSuccess({ ...data, source: 'google' }));
  },
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(mapState, mapDispatch)(RegistrationForm);
