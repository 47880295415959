import React from 'react';
import styled from '@emotion/styled';

const LabelWide= styled.label<{ secondary?: boolean }>`
  display: inline-block;
  margin: 0;
  flex: 14;
  padding: 0 0 4px;
  line-height: 16px;

  .material-icons {
    font-size: 16px;
  }

  small {
    opacity: 0.5;
    font-size: 13px;
  }

  ${props =>
    props.secondary &&
    `
  opacity: 0.5;
  padding-top: 4px;
  font-size: 13px;`};
`;

const LabelSmall = styled(LabelWide)`
  flex: 3;
`;

interface IProps {
  wide?: boolean;
}

const Component: React.FC<IProps> = ({ wide, ...rest }) => (wide ? <LabelWide {...rest} /> : <LabelSmall {...rest} />);

export default Component;
