import { getCookie } from 'components/helpers/cookies';

const metrics = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'landing_page', 'partner_id'];

export function getMetrics(): { [name: string]: string | object } {
  return metrics.reduce((acc, item) => {
    const value = getCookie(item);
    if (value) {
      return { ...acc, [item]: getCookie(item) };
    }
    return acc;
  }, {});
}
