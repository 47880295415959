import React from 'react';

import { ButtonGroup, Button } from '../Button';
import styled from '@emotion/styled';

const StyledInput = styled('input')`
  padding-right: 0;
  font-size: 6rem;
  max-width: 220px;
  text-align: center;
  color: #424d57;
  margin-bottom: 16px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 2rem;
`;

interface IProps {
  value: number;
  onChangeValue(newVal: number): void;
  children?: React.ReactElement
  minValue?: number;
  maxValue?: number;
}

export const PlacesSelect: React.FC<IProps> = ({ value, onChangeValue, children, minValue, maxValue }) => {
  const changeValue = newVal => {
    if (newVal >= minValue && newVal <= maxValue) {
      onChangeValue(newVal);
    }

    if (newVal > maxValue) {
      onChangeValue(maxValue);
    }
  };

  return (
    <>
      <StyledInput type="number" min="1" value={value} onChange={e => changeValue(e.target.value)} />

      {children && children}

      <StyledButtonGroup>
        <Button onClick={() => changeValue(value - 1)} disabled={minValue === value}>
          -
        </Button>
        <Button onClick={() => changeValue(value + 1)} disabled={maxValue === value}>
          +
        </Button>
      </StyledButtonGroup>
    </>
  );
};

PlacesSelect.defaultProps = {
  minValue: 0,
  maxValue: 50
};
