import { SIGNUP } from './actions';
import { ISignupState } from './interfaces';

const initialState: ISignupState = {
  event_id: null,
  event_category_id: null,
  wave: null,
  groupName: '',
  city_id: null,
  age: '',
  step: 0,
  places: 1,
  codes: []
};

export function signupReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SIGNUP.CITY: {
      return {
        ...state,
        city_id: payload.city,
        wave: null,
        age: '',
        codes: []
      };
    }

    case SIGNUP.TYPE: {
      return {
        ...state,
        event_id: payload.type,
        wave: null,
        age: '',
        distance: null
      };
    }

    case SIGNUP.WAVE: {
      return {
        ...state,
        wave: payload.wave
      };
    }

    case SIGNUP.GROUPNAME: {
      return {
        ...state,
        groupName: payload.groupName
      };
    }

    case SIGNUP.DISTANCE: {
      return {
        ...state,
        distance: payload.distance.distance,
        event_category_id: payload.distance.id,
        wave: null
      };
    }

    case SIGNUP.AGE: {
      return {
        ...state,
        age: payload.age,
        distance: 1,
        wave: null
      };
    }

    case SIGNUP.STEP: {
      return {
        ...state,
        step: payload.step || 0
      };
    }

    case SIGNUP.PLACES: {
      const codes = new Array(payload.places);
      return {
        ...state,
        codes: codes[0] && codes[0].title ? [...codes] : [],
        places: payload.places
      };
    }

    case SIGNUP.CODE_SUCCESS: {
      const codes = state.codes.filter(code => code.name !== payload.name);
      return {
        ...state,
        codes: [...codes, payload]
      };
    }

    case SIGNUP.SIGNUP_SUCCESS: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
