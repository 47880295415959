import { connect } from 'react-redux';
import { SignupActions } from '../../store/ui/signup/actions';
import { SignupSteps } from './SignupSteps';
import { getSignup } from '../../store/ui/signup/selectors';

const mapState = state => {
  return {
    signup: getSignup(state)
  };
};

const mapDispatch = {
  ...SignupActions
};

export default connect(
  mapState,
  mapDispatch
)(SignupSteps);
