import { put, takeEvery, select, call, fork, all } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { SIGNUP, SignupActions } from './actions';
import { Api } from 'services';
import { NotificationActions } from '../notification/actions';
import { trackEvent, trackEcommerce } from '../../../components/helpers/eventTracking';
import { getSignup } from './selectors';
import { redirectToUrl } from '../../helpers';
import { ISignupState } from './interfaces';
import { getEventCategory } from 'store/events/selectors';
import { IStoreStateInterface } from 'store/reducers';

function* placesSaga(action) {
  const {
    payload: { places }
  } = action;
  const signup: ISignupState = yield select(getSignup);

  const requestesPlaces = places || signup.places;

  if (signup.wave && signup.wave.free_places < requestesPlaces) {
    yield put(SignupActions.places(signup.wave.free_places));
  }

  if (places < 1) {
    yield put(SignupActions.places(1));
  }
}

function* registrationSaga(action) {
  const {
    payload: { reservation = false }
  } = action;
  const signup: ISignupState = yield select(getSignup);

  const { places, wave, groupName, codes } = signup;

  const eventCategory = yield select((state: IStoreStateInterface) =>
    getEventCategory(state, signup.event_category_id)
  );

  const { result, error } = yield call(Api.signupApi.createGroup, {
    participants: places,
    wave_id: wave.id,
    group_name: groupName || 'Nazwa grupy',
    coupons: codes.map(code => code.name)
  });

  if (error) {
    yield put(SignupActions.signupFailed(error.message));
    yield put(NotificationActions.create(error.message));
  }

  if (result) {
    yield put(NotificationActions.create(result.message));

    const { total_price: totalPrice } = result.data;

    if (!reservation) {
      yield call(trackEvent, 'formSubmitted', {
        submitType: 'paid'
      });

      yield call(trackEvent, 'Purchase', {
        event: signup.city_id,
        currency: 'PLN',
        value: Math.round(totalPrice),
        num_items: places
      });

      yield call(trackEcommerce, {
        price: totalPrice,
        id: signup.city_id,
        city: signup.city_id,
        distance: eventCategory.distance,
        hour: wave.wave_time,
        seats: places,
        coupon: codes.map(code => code.name).toString()
      });
    } else {
      yield call(trackEvent, 'formSubmitted', {
        submitType: 'reserved'
      });

      yield call(trackEvent, 'AddToWishlist', {
        event: signup.city_id,
        value: totalPrice,
        currency: 'PLN'
      });
    }

    if (!reservation && result.data && result.data.payment_url) {
      yield call(redirectToUrl, result.data.payment_url);
    }

    yield put(push(`/grupa/${result.data.group_id}`));

    yield put(SignupActions.signupSuccess(result));
  }
}

function* couponSaga(action) {
  const {
    payload: { codeName, event_id }
  } = action;
  const codes = codeName.split(',');

  yield all(codes.map(code => fork(singleCouponSaga, String(code.trim()), event_id)));
}

function* singleCouponSaga(codeName, event_id) {
  const { result, error } = yield call(Api.signupApi.checkCoupon, codeName, event_id);

  if (error) {
    yield put(SignupActions.codeFailed(error.message));
    yield put(NotificationActions.create(error.message));
  }

  if (result) {
    yield put(SignupActions.codeSuccess(result.data));
    yield put(NotificationActions.create(result.message));
  }
}

export function* signupSaga() {
  yield takeEvery(SIGNUP.PLACES, placesSaga);
  yield takeEvery(SIGNUP.WAVE, placesSaga);
  yield takeEvery(SIGNUP.AGE, placesSaga);
  yield takeEvery(SIGNUP.DISTANCE, placesSaga);
  yield takeEvery(SIGNUP.TYPE, placesSaga);
  yield takeEvery(SIGNUP.CITY, placesSaga);
  yield takeEvery(SIGNUP.SIGNUP_REQUEST, registrationSaga);
  yield takeEvery(SIGNUP.CODE_REQUEST, couponSaga);
}
