import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

class SignupDeleteDialog extends React.Component {
  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Zwolnij miejsce"
        labelStyle={{ color: '#FF6600' }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.props.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Usuń uczestnika z grupy"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p style={{ color: 'red' }}>
          Czy na pewno chcesz usunąć tego uczestnika z grupy? Będziesz mógł wtedy zaprosić nowego uczestnika na to
          miejsce.
        </p>
      </Dialog>
    );
  }
}

export default SignupDeleteDialog;
