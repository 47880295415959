/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import isNull from 'lodash/isNull';

import { Api } from 'services';

import GroupTable from './GroupTable';
import GroupInfo from './GroupInfo';
import GroupDeleteDialog from './groupDelete';
import GroupChangeNameDialog from './groupChangeName';
import GroupChangeWaveDialog from './GroupChangeWaveDialog';
import GroupChallengeDialog from './groupChallenge';

import SignupCaptainDialog from './signupCaptain';
import SignupInviteDialog from './signupInvite';
import SignupChangeGroupDialog from './SignupChangeGroupDialog';
import SignupChangeUserDialog from './signupChangeUser';
import SignupDeleteDialog from './signupDelete';
import SignupDeleteSelfDialog from './signupDeleteSelf';
import { trackEvent, sendPageview, trackEngagement } from '../helpers/eventTracking';
import UserProfileSidebar from '../Profile/UserProfileSidebar';
import { NotificationActions } from '../../store/ui/notification/actions';
import { ProfileActions } from '../../store/user-profile/actions';
import { GroupActions } from '../../store/groups/actions';
import { getGroupInfo, getIsFetchingGroup } from '../../store/groups/selectors';
import styled from '@emotion/styled';
import { IStoreStateInterface } from 'store/reducers';
import { IProfileState } from 'store/user-profile/reducer';
import { IGroup } from 'store/groups/reducer';
import { IWave } from 'store/events/interfaces';
import { AnyAction, Dispatch } from 'redux';
import { INotification } from 'store/ui/notification/interfaces';
import { getAvailableWaves } from 'store/ui/signup/selectors';
import { gridWrapper, mainContentWrapperStyles, userProfileWraperStyles } from 'components/grid/AppGridComponents';
import { getUserProfile } from 'store/user-profile/selectors';

const ProgressCentered = styled.div`
  text-align: center;
  padding: 50px 0;
`;

const ProgressDescription = styled.div`
  color: #fff;
  margin-top: 10px;
`;

interface IState {
  groupId: number;
  loading: boolean;
  dialogIndex: string;
  newCaptainId: string;
  signupRemoveId: string;
  signupChangeGroupId: string;
  signupChangeUserId: string;
}

interface IOwnProps extends RouteComponentProps<{ groupId: string }> {}

interface IStateProps {
  user: IProfileState;
  group: IGroup;
  isFetchingGroup: boolean;
  waves: IWave[];
}

interface IDispatchProps {
  onMount(): void;
  handleRemoveSignup(signup: string): void;
  handleChangeGroupname(newName: string): void;
  handleChangeWave(newWaveId: number): void;
  handleChangeCaptain(newCapitain: string): void;
  onResetData(): void;
  displayNotification(payload: INotification): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

declare const window: any;

class GroupView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      groupId: parseInt(props.match.params.groupId, 10),
      loading: true,
      dialogIndex: null,
      newCaptainId: null,
      signupRemoveId: null,
      signupChangeGroupId: null,
      signupChangeUserId: null
    };
  }

  componentDidMount() {
    this.props.onMount();
    trackEngagement('Group view displayed', 'Group view', { groupId: this.state.groupId });
    trackEvent('Group view displayed');
    const inGroup = this.inGroup();
    if (!inGroup) {
      this.props.history.push('/informacje');
    } else {
      const pageName = 'Grupa';
      const path = window.location.pathname;

      trackEvent('VirtualPageView', {
        virtualPageTitle: pageName,
        virtualPagePath: path,
        user: this.props.user && this.props.user.id
      });
      sendPageview(pageName);
    }
  }

  showDialog = dialogIndex => {
    this.setState({ dialogIndex });
  };

  hideDialog = () => {
    this.setState({ dialogIndex: null });
  };

  isCaptain = () => {
    if (!this.props.group) {
      return false;
    }

    return this.props.group.signups.find(u => u.is_captain).user_id === this.props.user.id;
  };

  inGroup = () => {
    const { groupId } = this.state;
    return !!this.props.user.signups.filter(group => group.id === groupId).length;
  };

  groupChangeName = groupName => {
    this.hideDialog();
    this.props.handleChangeGroupname(groupName);
    this.props.onMount();
    trackEngagement('Change group name', 'Group view');
  };

  groupChangeWave = waveId => {
    this.props.handleChangeWave(waveId);
    trackEngagement('Change group wave', 'Group view');
    this.hideDialog();
  };

  groupChallenge = async email => {
    this.hideDialog();
    const { result } = (await Api.signupApi.sendInviteToPay(this.state.groupId, email)) as any;

    if (result) {
      this.props.displayNotification(result.message);
    }
    trackEngagement('Send event challenge', 'Group view');
  };

  handleGroupDelete = async () => {
    this.hideDialog();

    const { result } = (await Api.signupApi.deleteGroup(this.state.groupId)) as any;
    trackEngagement('Group delete', 'Group view');

    if (result) {
      this.props.displayNotification(result.message);
      this.props.onResetData();
      this.props.history.push('/informacje');
    }
  };

  handleShowChangeCaptainModal = (newCaptainId: string) => {
    this.setState({ newCaptainId });
    this.showDialog('signupcaptain');
    trackEngagement('Change captain modal displayed', 'Group view');
  };

  handleChangeGroupCaptain = () => {
    this.props.handleChangeCaptain(this.state.newCaptainId);
    this.hideDialog();
    trackEngagement('Captain changed', 'Group view');
  };

  handleSendSignupInvite = async email => {
    this.hideDialog();

    trackEngagement('Prepaid place invite', 'Group view');
    const { result } = (await Api.signupApi.sendInvite(this.state.groupId, email)) as any;

    if (result) {
      this.props.displayNotification(result.message);
    }
  };

  handleShowSignupChangeGroup = signupChangeGroupId => {
    this.setState({ signupChangeGroupId });
    this.showDialog('signupchangegroup');
    trackEngagement('Change group modal displayed', 'Group view');
  };

  handleChangeSignupGroup = async newGroupId => {
    this.hideDialog();
    if (isNull(this.state.signupChangeGroupId)) {
      return;
    }

    trackEngagement('Change group', 'Group view');

    const { result } = (await Api.signupApi.groupPerformTransfer(newGroupId, this.state.signupChangeGroupId)) as any;

    this.props.displayNotification(result.message);
    this.props.onResetData();

    if (result) {
      this.props.history.push('/informacje');
    }
  };

  handleShowChangeUserModal = signupChangeUserId => {
    this.setState({ signupChangeUserId });
    this.showDialog('signupchangeuser');
    trackEngagement('Change user modal displayed', 'Group view');
  };

  signupChangeUser = async newUserEmail => {
    this.hideDialog();
    trackEngagement('Change user', 'Group view');

    const { result } = (await Api.signupApi.userPerformTransfer(newUserEmail, this.state.signupChangeUserId)) as any;

    this.props.displayNotification(result.message);
    this.props.onResetData();

    if (result) {
      this.props.history.push('/informacje');
    }
  };

  handleShowSignupDeleteModal = signupRemoveId => {
    this.setState({ signupRemoveId });
    this.showDialog('signupdelete');
    trackEngagement('Group delete modal displayed', 'Group view');
  };

  handleSignupDeleteSelfModal = signupRemoveId => {
    this.setState({ signupRemoveId });
    this.showDialog('signupdeleteself');
    trackEngagement('Signup delete modal displayed', 'Group view');
  };

  handleRemoveSingleSignup = () => {
    this.props.handleRemoveSignup(this.state.signupRemoveId);
    trackEngagement('Signup deleted', 'Group view');

    this.hideDialog();
    this.setState({ signupRemoveId: null });
  };

  render() {
    const { waves, group: propsGroup, isFetchingGroup } = this.props;
    const { dialogIndex } = this.state;

    const group = propsGroup || ({} as IGroup);

    const waveId = group.wave_id;

    return (
      <section className="group-index info-index">
        <div css={gridWrapper}>
          <div css={userProfileWraperStyles}>
            <UserProfileSidebar />
          </div>

          <div css={mainContentWrapperStyles} className="signup-card-container">
            {!group.id || isFetchingGroup ? (
              <ProgressCentered>
                <CircularProgress mode="indeterminate" size={60} color="#f60" />
                <ProgressDescription>Ładowanie danych grupy</ProgressDescription>
              </ProgressCentered>
            ) : (
              <div>
                <GroupInfo
                  group={group}
                  isCaptain={this.isCaptain()}
                  onGroupChangeName={this.showDialog.bind(null, 'name')}
                  onGroupChangeWave={this.showDialog.bind(null, 'wave')}
                  onGroupChallange={this.showDialog.bind(null, 'challenge')}
                  onGroupPayment={this.showDialog.bind(null, 'payment')}
                  onGroupDelete={this.showDialog.bind(null, 'delete')}
                  user={this.props.user}
                />
                <GroupTable
                  isCaptain={this.isCaptain()}
                  user={this.props.user}
                  group={group}
                  onGroupChallange={this.showDialog.bind(null, 'challenge')}
                  onSignupChangeCaptain={this.handleShowChangeCaptainModal}
                  onSignupSendInvite={this.showDialog.bind(null, 'signupinvite')}
                  onSignupChangeGroup={this.handleShowSignupChangeGroup}
                  onSignupChangeUser={this.handleShowChangeUserModal}
                  onSignupDelete={this.handleShowSignupDeleteModal}
                  onSignupDeleteSelf={this.handleSignupDeleteSelfModal}
                />
              </div>
            )}
          </div>

          <GroupChangeNameDialog
            show={this.isCaptain() && dialogIndex === 'name'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.groupChangeName}
          />
          {this.isCaptain() && dialogIndex === 'wave' && (
            <GroupChangeWaveDialog
              waves={waves}
              onHideDialog={this.hideDialog}
              onSubmitDialog={this.groupChangeWave}
              currentWaveId={waveId}
              date={group.event_date}
            />
          )}
          {dialogIndex === 'challenge' && (
            <GroupChallengeDialog onHideDialog={this.hideDialog} onSubmitDialog={this.groupChallenge} />
          )}

          <GroupDeleteDialog
            show={this.isCaptain() && dialogIndex === 'delete'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleGroupDelete}
          />

          <SignupCaptainDialog
            show={this.isCaptain() && dialogIndex === 'signupcaptain'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleChangeGroupCaptain}
          />
          <SignupInviteDialog
            show={this.isCaptain() && dialogIndex === 'signupinvite'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleSendSignupInvite}
          />
          <SignupChangeGroupDialog
            show={dialogIndex === 'signupchangegroup'}
            bodyStyle={styles.body}
            signupId={this.state.signupChangeGroupId}
            onAddMessage={this.props.displayNotification}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleChangeSignupGroup}
          />
          <SignupChangeUserDialog
            show={dialogIndex === 'signupchangeuser'}
            bodyStyle={styles.body}
            signupId={this.state.signupChangeUserId}
            onAddMessage={this.props.displayNotification}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.signupChangeUser}
          />
          <SignupDeleteDialog
            show={dialogIndex === 'signupdelete'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleRemoveSingleSignup}
          />
          <SignupDeleteSelfDialog
            show={dialogIndex === 'signupdeleteself'}
            bodyStyle={styles.body}
            onHideDialog={this.hideDialog}
            onSubmitDialog={this.handleRemoveSingleSignup}
          />
        </div>
      </section>
    );
  }
}

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => {
  const group = getGroupInfo(state, parseInt(ownProps.match.params.groupId));

  return {
    user: getUserProfile(state),
    group,
    isFetchingGroup: getIsFetchingGroup(state),
    waves: getAvailableWaves(state, group && group.category_id)
  };
};

const mapDispatch = (dispatch: Dispatch<AnyAction>, ownProps: IOwnProps): IDispatchProps => ({
  onMount() {
    dispatch(GroupActions.fetchGroupDataRequest(ownProps.match.params.groupId));
  },
  handleRemoveSignup(signup: string) {
    dispatch(GroupActions.deleteOneSignupRequest(signup, ownProps.match.params.groupId));
  },
  handleChangeGroupname(newName) {
    dispatch(GroupActions.changeNameRequest(newName, ownProps.match.params.groupId));
  },
  handleChangeWave(newWave) {
    dispatch(GroupActions.changeWaveRequest(newWave, ownProps.match.params.groupId));
  },
  handleChangeCaptain(newUser) {
    dispatch(GroupActions.changeCaptainRequest(newUser, ownProps.match.params.groupId));
  },
  onResetData() {
    dispatch(ProfileActions.fetchProfileRequest());
  },
  displayNotification(payload) {
    dispatch(NotificationActions.create(payload));
  }
});

export default connect(mapState, mapDispatch)(GroupView);

const styles = {
  main: {},
  content: {},
  body: {
    color: '#474747'
  }
};
