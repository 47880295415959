import { NOTIFICATION } from './actions';
import { INotificationsState } from './interfaces';

const initialState: INotificationsState = {
  data: []
};

export function notificationReducer(state = initialState, { payload, type }) {
  switch (type) {
    case NOTIFICATION.DISPLAY: {
      return {
        ...state,
        data: [...state.data, payload]
      };
    }

    case NOTIFICATION.DISMISS: {
      const notifications = state.data.filter(item => item.id !== payload.id);

      return {
        ...state,
        data: [...notifications]
      };
    }

    default:
      return state;
  }
}
