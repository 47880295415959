import { createAction } from '../helpers';

export const PROFILE = {
  CHANGE_PASSWORD: 'PROFILE/CHANGE_PASSWORD',
  LOGOUT: 'PROFILE/USER_LOGOUT',
  FETCH_PROFILE_REQUEST: 'PROFILE/FETCH_USER_REQUEST',
  FETCH_PROFILE_SUCCESS: 'PROFILE/FETCH_USER_SUCCESS',
  FETCH_PROFILE_FAILURE: 'PROFILE/FETCH_USER_FAILURE',
  UPDATE_PROFILE_REQUEST: 'PROFILE/UPDATE_USER_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'PROFILE/UPDATE_USER_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'PROFILE/UPDATE_USER_FAILURE'
};

export const ProfileActions = {
  fetchProfileRequest: () => createAction(PROFILE.FETCH_PROFILE_REQUEST),
  fetchProfileSuccess: payload => createAction(PROFILE.FETCH_PROFILE_SUCCESS, payload),
  fetchProfileFailure: payload => createAction(PROFILE.FETCH_PROFILE_FAILURE, payload),
  updateProfileRequest: (payload, onSuccess) => createAction(PROFILE.UPDATE_PROFILE_REQUEST, Object.assign(payload, { onSuccess })),
  updateProfileSuccess: () => createAction(PROFILE.UPDATE_PROFILE_SUCCESS),
  updateProfileFailure: payload => createAction(PROFILE.UPDATE_PROFILE_FAILURE, payload),
  updatePassword: payload => createAction(PROFILE.CHANGE_PASSWORD, payload),
  logout: () => createAction(PROFILE.LOGOUT)
};
