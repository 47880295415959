import { connect } from 'react-redux';
import { SignupActions } from '../../store/ui/signup/actions';
import { ProgressBar } from './components/ProgressBar';

const steps = [
  'Wybierz wydarzenie',
  'Wybierz miasto',
  'Wybierz dystans',
  'Wybierz falę',
  'Ustawienia grupy',
  'Podsumowanie i płatność'
];

const mapState = ({ ui: { signup } }) => ({
  currentStep: signup.step,
  allSteps: steps
});

const mapDispatch = {
  onStepClicked: SignupActions.step
};

export default connect(
  mapState,
  mapDispatch
)(ProgressBar);
