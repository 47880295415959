import React, { Fragment, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ui/notification/actions';
import { Api } from 'services';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { ProfileActions } from 'store/user-profile/actions';

interface IOwnProps {
  handleClose(): void;
}

interface IProps extends IOwnProps {}

export const TermsConditionsModal: React.FC<IProps> = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handleAcceptTC = React.useCallback(() => {
    const call = async () => {
      const { result, error } = (await Api.profileApi.acceptTermsConditions(true)) as any;

      if (result) {
        dispatch(ProfileActions.fetchProfileRequest());
        dispatch(NotificationActions.create(result.message));
      }

      if (error) {
        dispatch(NotificationActions.create(error));
      }

      handleClose();
    };

    call();
  }, [dispatch, handleClose]);

  const handleDeclineTC = () => dispatch(ProfileActions.logout());

  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        static
        open={true}
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-50">
                      Nowy regulamin
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-200">
                        Akceptuję postanowienia{' '}
                        <a href="https://www.survivalrace.pl/regulamin">Regulaminu Survival Race</a> i{' '}
                        <a href="https://www.survivalrace.pl/polityka-prywatnosci">Polityki Prywatności</a> oraz wyrażam
                        zgodę na przetwarzanie moich danych osobowych przez Polską Fundację na Rzecz Promocji Sportu
                        Akara z siedzibą we Wrocławiu, dla potrzeb niezbędnych do realizacji procesu rejestracji i
                        uczestnictwa w biegu Survival Race (zgodnie z Ustawą z dnia 29.08.1997 roku o Ochronie Danych
                        Osobowych; (tekst jednolity: Dz. U. 2016 r. poz. 922).
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-900 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleAcceptTC}
                >
                  Akceptuję regulamin
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDeclineTC}
                  ref={cancelButtonRef}
                >
                  Nie akceptuję
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
