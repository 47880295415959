import ApiClient, { IApiClient } from './api-client';
import { EventApi } from './event';
import { ProfileApi } from './profile';
import { SignupApi } from './signup';
import { AuthApi } from './auth';
import { AdditionsApi } from './additions';
import { PropertiesApi } from './properties';

interface IBaseApiConstructor<T> {
  new (api: IApiClient): T;
}

type ApiType = SignupApi | EventApi | ProfileApi | AuthApi | AdditionsApi | PropertiesApi;

class ApiManagerBase {
  private api: ApiClient;
  private apiInstances: Map<IBaseApiConstructor<ApiType>, ApiType> = new Map();

  constructor() {
    this.api = new ApiClient();
  }

  private getApiInstance = (
    TypeConstructor: IBaseApiConstructor<ApiType>,
    apiClient: ApiClient = this.api
  ): ApiType => {
    let instance = this.apiInstances.get(TypeConstructor);

    if (!instance) {
      instance = new TypeConstructor(apiClient);
      this.apiInstances.set(TypeConstructor, instance);
    }

    return instance;
  };

  get signupApi() {
    return this.getApiInstance(SignupApi) as SignupApi;
  }

  get eventApi() {
    return this.getApiInstance(EventApi) as EventApi;
  }

  get profileApi() {
    return this.getApiInstance(ProfileApi) as ProfileApi;
  }

  get authApi() {
    return this.getApiInstance(AuthApi) as AuthApi;
  }

  get additionsApi() {
    return this.getApiInstance(AdditionsApi) as AdditionsApi;
  }

  get propertiesApi() {
    return this.getApiInstance(PropertiesApi) as PropertiesApi;
  }
}

export default new ApiManagerBase();
