import React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  logoutUser(): void;
}
export const Navigation: React.FC<IProps> = ({ logoutUser }) => (
  <React.Fragment>
    <ul className="mesr-profile__profile-nav">
      <li>
        <NavLink to="/zapisy" activeClassName="nav-active">
          <strong>Twój profil / Zapisy</strong>
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/" activeClassName="nav-active">
          <strong>Kup bilet</strong>
        </NavLink>
      </li>
      <li>
        <NavLink to="/zapisz-do-grupy" activeClassName="nav-active">
          <strong>Zapisz się do grupy</strong>
        </NavLink>
      </li>
    </ul>
  </React.Fragment>
);
