// Based on https://medium.com/@martin_hotell/improved-redux-type-safety-with-typescript-2-8-2c11a8062575

export interface IAction<T extends string = string> {
  type: T;
}

export interface IActionWithPayload<T extends string, P> extends IAction<T> {
  payload: P;
}

type FunctionType = (...args: any[]) => any;
interface IActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends IActionCreatorsMapObject> = ReturnType<A[keyof A]>;

// function overloading
function actionCreator<T extends string>(type: T): IAction<T>;
function actionCreator<T extends string, P>(type: T, payload: P): IActionWithPayload<T, P>;
function actionCreator<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export const createAction = actionCreator;

/**
 * Returning request action name based on action payload.
 * @param requestName Base request action name.
 * @param payload Action payload.
 */
export const getRequestActionName = (requestName, payload): string => {
  let requestActionName = requestName;
  if (payload.additionalRequestId) {
    requestActionName = `${requestActionName}_${payload.additionalRequestId}`;
  }
  if (payload.id) {
    requestActionName = `${requestActionName}_${payload.id}`;
  }
  return requestActionName;
};

export function redirectToUrl(url) {
  window.location.href = url;
}
