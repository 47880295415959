/** @jsxRuntime classic */

/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Button } from 'components/Button';
import { trackPayments } from 'components/helpers/eventTracking';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { StyledSignupWrapper } from 'routes/signup/SignupWrapper';
import apiManager from 'services/api-manager';

interface IProps extends RouteComponentProps<{ paymentId: string }> {}

const paymentBanner = css`
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 800;
  font-size: 0.9rem;
  margin-bottom: 0;
`;

const paymentHeader = css`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 32px;
`;

const paymentDescription = css`
  color: #e5e7eb;
`;

const ThankYouPage: React.FC<IProps> = props => {
  const [eventSent, setEventSent] = React.useState(false);
  const [groupId, setGroupId] = React.useState(0);
  const [error, setError] = React.useState(null);

  const { paymentId } = props.match.params;

  useEffect(() => {
    apiManager.signupApi.paymentDetails(paymentId).then(({ result: reqResult, error }) => {
      if (error) {
        return setError(error);
      }

      const { data: result } = reqResult;

      trackPayments(
        result.payment_id,
        result.price,
        result.run_name,
        result.event_category_id,
        result.single_price,
        result.quantity
      );
      setGroupId(result.group_id);
      setEventSent(true);
    });
  }, [paymentId]);

  const handleGoToGroup = () => {
    if (eventSent) {
      props.history.replace(`/grupa/${groupId}`);
    }
  };

  return (
    <StyledSignupWrapper>
      {error ? (
        <React.Fragment>
          <span css={paymentBanner}>Problem z weryfikacją płatności</span>
          <h1 css={paymentHeader}>Mamy problem</h1>
          <p css={paymentDescription}>Mamy problem z weryfikacją płatności. Spróbuj ponownie później</p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span css={paymentBanner}>Płatność udana</span>
          <h1 css={paymentHeader}>Dziękujemy</h1>
          <p css={paymentDescription}>Czekamy na potwierdzenie Twojej płatności z banku.</p>

          <Button primary onClick={handleGoToGroup} disabled={!eventSent}>
            Przejdź do grupy
          </Button>
        </React.Fragment>
      )}
    </StyledSignupWrapper>
  );
};

export default ThankYouPage;
