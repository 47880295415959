import React from 'react';
import { ClockCountdown } from '../clock-countdown/ClockCountdown';
import { IGroup } from 'store/groups/reducer';
import { IProfileState } from 'store/user-profile/reducer';
import { Header, Subheader } from './styles';
import styled from '@emotion/styled';
import { ManageGroupDropdown } from './ManageGroupDropdown';
import { Link } from 'react-router-dom';

interface IProps {
  group: IGroup;
  isCaptain: boolean;
  user: IProfileState;
  onGroupChangeName(): void;
  onGroupChallange(): void;
  onGroupChangeWave(): void;
  onGroupDelete(): void;
  onGroupPayment(): void;
}

const StyledGroupInfo = styled.ul`
  color: #fff;
  font-size: 14px;
  display: table;
  list-style: none;
  padding-left: 0;
  width: 80%;
  margin: 0 auto 10px;

  span {
    cursor: pointer;
    color: tomato;
  }

  & > li {
    display: table-row;
    float: left;
    width: 50%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  & > li > * {
    display: table-cell;
    padding: 5px;
  }

  & label {
    font-weight: bold;
    text-align: right;
    color: #f60;
    text-transform: uppercase;
    font-size: 13px;
  }

  & label:after {
    content: ':';
  }
`;

class GroupInfo extends React.Component<IProps> {
  render() {
    const { group, onGroupChangeName, onGroupChallange, onGroupChangeWave, onGroupDelete } = this.props;

    const paidParticipants = group.signups.reduce((acc, val) => (val.status ? acc + 1 : acc), 0);
    const groupInactive = this.props.isCaptain && paidParticipants < group.signups.length;

    return (
      <div style={{ marginBottom: '30px' }}>
        <div className="group-navigation">
          <Link to="/zapisy">
            <i className="material-icons">navigate_before</i> Powrót do zapisów
          </Link>
          <ManageGroupDropdown
            onGroupChangeName={onGroupChangeName}
            onGroupChallange={onGroupChallange}
            onGroupChangeWave={onGroupChangeWave}
            onGroupDelete={onGroupDelete}
          />
        </div>

        <Header>{group.event_name}</Header>
        <Subheader>Szczegóły wydarzenia</Subheader>

        <StyledGroupInfo>
          <li>
            <label>Miasto</label>
            <div>{group.city_name}</div>
          </li>

          <li>
            <label>Data</label>
            <div>
              {group.event_date}, {group.wave_time}
            </div>
          </li>

          <li>
            <label>Dystans</label>
            <div>
              {group.distance} KM - {group.category_name}
            </div>
          </li>

          <li>
            <label>Opłacone miejsca</label>
            <div>
              {paidParticipants} / {group.signups.length}
            </div>
          </li>
          <li>
            <label>Numer grupy</label>
            <div>{group.id}</div>
          </li>
          {this.props.isCaptain && (
            <li>
              <label>Kod weryfikacyjny</label>
              <div>{group.verifyCode}</div>
            </li>
          )}
        </StyledGroupInfo>

        {groupInactive ? (
          <ClockCountdown groupId={group.id} placesToPay={group.signups.length - paidParticipants} />
        ) : null}
      </div>
    );
  }
}

export default GroupInfo;
