import React from 'react';
import { connect } from 'react-redux';
import { SignupActions } from '../../../store/ui/signup/actions';
import { getWavesForSignup } from '../../../store/ui/signup/selectors';
import { WaveCard } from './WaveCard';
import { IStoreStateInterface } from 'store/reducers';
import { ISignupState } from 'store/ui/signup/interfaces';
import { IWave, ICategory } from 'store/events/interfaces';
import { AnyAction, Dispatch } from 'redux';
import { getEventCategory } from 'store/events/selectors';

interface IOwnProps {
  signup: ISignupState;
}

interface IStateProps {
  waves: IWave[];
  event: ICategory;
}

interface IDispatchProps {
  handleSelectWave(wave: IWave): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const WaveSelect: React.FC<IProps> = ({ waves, handleSelectWave, signup, event }) => (
  <>
    {waves &&
      waves.map(wave => (
        <WaveCard
          key={wave.id}
          isSelected={signup.wave && signup.wave.id === wave.id}
          handleSelect={handleSelectWave}
          wave={wave}
          date={event.event_category_date}
        />
      ))}
  </>
);

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => ({
  waves: getWavesForSignup(state),
  event: getEventCategory(state, ownProps.signup.event_category_id)
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  handleSelectWave(wave: IWave) {
    dispatch(SignupActions.wave(wave));
  }
});

export default connect(
  mapState,
  mapDispatch
)(WaveSelect);
