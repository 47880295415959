import React from 'react';
import styled from '@emotion/styled';
import { Api } from 'services';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ui/notification/actions';

const StyledEmailNotVerifiedTopBar = styled.div`
  background: tomato;
  min-height: 40px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const StyledButton = styled('button')`
  border: 0;
  display: inline-block;
  apperiance: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  font-weight: 600;
`;

export const EmailNotVerifiedTopBar: React.FC = () => {
  const [wasSent, setWasSent] = React.useState(false);
  const dispatch = useDispatch();

  const handleResendEmail = React.useCallback(() => {
    const call = async () => {
      const { result, error } = (await Api.profileApi.resendVerificationEmail()) as any;

      if (result) {
        dispatch(NotificationActions.create(result.message));
      }

      if (error) {
        dispatch(NotificationActions.create(result.error));
      }

      setWasSent(true);
    };

    if (!wasSent) {
      call();
    }
  }, [wasSent, setWasSent, dispatch]);

  return (
    <StyledEmailNotVerifiedTopBar>
      <span>
        Adres email nie został potwierdzony.{' '}
        {wasSent ? (
          'Email z potwierdzeniem wysłany ponownie'
        ) : (
          <>
            <StyledButton onClick={handleResendEmail}>Wyślij email ponownie</StyledButton> lub zaktaulizuj adres email w
            profilu
          </>
        )}
      </span>
    </StyledEmailNotVerifiedTopBar>
  );
};
