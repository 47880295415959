import React from 'react';
import { Strike } from './Strike';
import { ISignupSinglePrices } from 'store/events/selectors';

interface ISinglePriceProps extends ISignupSinglePrices {}

export const SinglePrice: React.FC<ISinglePriceProps> = ({ initialPrice, finalPrice }) => {
  if (initialPrice === finalPrice) {
    return <>{finalPrice} zł</>;
  }

  if (initialPrice > finalPrice) {
    return (
      <>
        <Strike>{initialPrice} zł</Strike> {finalPrice} zł
      </>
    );
  }
};
