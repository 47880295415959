import { GlobalModal } from 'enums/global-modals';
import { ActionsUnion, createAction } from 'store/helpers';

export enum GlobalModalActionsEnum {
  SHOW_GLOBAL_MODAL = 'UI/GLOBAL_MODALS/SHOW',
  HIDE_GLOBAL_MODAL = 'UI/GLOBAL_MODALS/HIDE'
}

/**
 * GlobalModalActions
 */
export const GlobalModalActions = {
  showModal: (modal: GlobalModal, payload = {}) =>
    createAction(GlobalModalActionsEnum.SHOW_GLOBAL_MODAL, { modal, payload }),
  hideModal: (modal: GlobalModal) => createAction(GlobalModalActionsEnum.HIDE_GLOBAL_MODAL, { modal })
};

export type GlobalModalActionTypes = ActionsUnion<typeof GlobalModalActions>;
