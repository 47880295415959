import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

class SignupCaptainDialog extends React.Component {
  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Mianuj kapitana"
        labelStyle={{ color: '#FF6600' }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.props.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Wyznacz nowego kapitana"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p>
          Czy na pewno chcesz tego uczestnika mianować nowym kapitanem drużyny? Utracisz wtedy wszystkie opcje
          zarządzania grupą.
        </p>
      </Dialog>
    );
  }
}

export default SignupCaptainDialog;
