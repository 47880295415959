declare const window: any;
let api;
let apiLoaded = false;

(async function loadScript() {
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = 'https://apis.google.com/js/api.js';
    script.async = true;
    script.defer = true;
    script.onload = resolve;
    script.onerror = reject;

    document.body.appendChild(script);
  });

  window.gapi.load('auth2', () => {
    apiLoaded = true;
  });

  api = window.gapi;
})();

class GoogleAuthService {
  init() {
    if(!apiLoaded) {
      console.log('api not loaded yet');
    }

    return new Promise(resolve => {
      const auth2 = api.auth2.init({
        scope: 'profile',
        client_id: process.env.REACT_APP_GID
      });

      resolve(auth2);

      if (auth2.isSignedIn.get() === true) {
        auth2.signIn();
      }
    });
  }

  signIn(auth2) {
    return new Promise(resolve => {
      auth2.signIn();

      auth2.currentUser.listen(user => {
        resolve(user.getAuthResponse());
      });
    });
  }
}

export default GoogleAuthService;
