import styled from '@emotion/styled';

interface IProps {
  sticky?: boolean;
}

export const ModalFooter = styled.div<IProps>`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-shrink: 0;
  ${props =>
    props.sticky
      ? `
  position: sticky;
  bottom: 0;
  padding: 20px 16px;
  `
      : 'padding: 11px 16px'};
  width: 100%;
  background: #000;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;
