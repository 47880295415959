import React from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { AuthActions } from 'store/app/actions';
import { IStoreStateInterface } from 'store/reducers';
import { Button } from 'components/Button';
import { Api } from 'services';
import { MessageType } from 'store/ui/notification/interfaces';
import { NotificationActions } from 'store/ui/notification/actions';
import styled from '@emotion/styled';

declare const window: any;

interface IStateProps {}

interface IDispatchProps {
  userLoggedIn(data): void;
  showToast(message: string, severity: MessageType): void;
}

interface IProps extends IStateProps, IDispatchProps {}

const FacebookLoginButton = styled(Button)`
  background: #3b5998;
  color: #fff;
  border: 1px solid #3b5998;
  width: 100%;
`;

const LoginWithFacebook: React.FC<IProps> = ({ userLoggedIn, showToast }) => {
  const [disabled, setDisabled] = React.useState(false);

  const handleFacebookLogin = React.useCallback(() => {
    if (!window.FB) {
      showToast('Wystąpił błąd. Wyłącz Adblocka i odśwież stronę.', MessageType.Error);
      return;
    }

    setDisabled(true);
    window.FB.login(
      async response => {
        const { result, error } = (await Api.authApi.authorizeForSocial(
          'facebook',
          response.authResponse && response.authResponse.accessToken
        )) as any;

        setDisabled(false);

        if (result) {
          userLoggedIn(result.data);
        }

        if (error) {
          if (error.message && error.message.content) {
            showToast(error.message.content, MessageType.Error);
            return;
          }

          showToast('Wystąpił błąd. Spróbuj ponownie później', MessageType.Error);
        }
      },
      { scope: 'public_profile,email' }
    );
  }, [userLoggedIn, showToast]);

  return (
    <FacebookLoginButton disabled={disabled} onClick={handleFacebookLogin}>
      Zaloguj przez Facebooka
    </FacebookLoginButton>
  );
};

const mapState = (state: IStoreStateInterface): IStateProps => ({});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  userLoggedIn(data) {
    dispatch(AuthActions.loginUserSuccess({ ...data, source: 'facebook' }));
  },
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(mapState, mapDispatch)(LoginWithFacebook);
