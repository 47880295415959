import React from 'react';
import * as yup from 'yup';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { InputField } from '@livechat/design-system';

import { AuthActions } from 'store/app/actions';
import { IStoreStateInterface } from 'store/reducers';
import { Button, ButtonGroup } from 'components/Button';
import { StyledSignupWrapper } from 'routes/signup/SignupWrapper';
import { MessageType } from 'store/ui/notification/interfaces';
import { NotificationActions } from 'store/ui/notification/actions';

import { Title, Logo, Centered } from './components/styled';
import LoginWithFacebook from './components/LoginWithFacebook';
import LoginWithGoogle from './components/LoginWithGoogle';

import logo from '../../assets/mesr-badge.svg';

const LoginSchema = yup.object().shape({
  email: yup.string()
    .email('Niepoprawny adres email')
    .required('Wymagane'),
  password: yup.string().required('Wymagane')
});

interface IStateProps {}

interface IDispatchProps {
  loginUser(data: IUserLoginData): void;
  userLoggedIn(data): void;
  showToast(message: string, severity: MessageType): void;
}

interface IUserLoginData {
  email: string;
  password: string;
}

interface IProps extends IStateProps, IDispatchProps {}

const FormikInput = ({ field, form, ...props }) => {
  return <InputField {...field} {...props} htmlFor={field.name} id={field.name} />;
};

const LoginForm: React.FC<IProps> = ({ userLoggedIn, loginUser, showToast }) => (
  <StyledSignupWrapper>
    <Centered>
      <Logo src={logo} />
    </Centered>
    <Title>Logowanie</Title>
    <ButtonGroup fullWidth spaceBottom stackOnMobile>
      <LoginWithGoogle />
      <LoginWithFacebook />
    </ButtonGroup>
    <Formik
      validationSchema={LoginSchema}
      initialValues={{ password: '', email: '' }}
      onSubmit={(values, actions) => {
        loginUser(values);
        actions.setSubmitting(false);
      }}
      render={({ errors, touched, isSubmitting }) => {
        return (
          <Form>
            <Field
              type="email"
              name="email"
              placeholder="Email"
              component={FormikInput}
              error={touched.email && errors.email}
            />
            <Field
              type="password"
              name="password"
              placeholder="Hasło"
              component={FormikInput}
              error={touched.password && errors.password}
            />
            <div style={{ margin: '10px 0' }}>
              <Button submit primary fullWidth disabled={isSubmitting}>
                Logowanie
              </Button>
            </div>
          </Form>
        );
      }}
    />
    Nie masz konta? <Link to="/rejestracja">Załóż je teraz!</Link>
    <span style={{ float: 'right' }}>
      <Link to="/haslo">Zresetuj hasło</Link>
    </span>
  </StyledSignupWrapper>
);

const mapState = (state: IStoreStateInterface): IStateProps => ({});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  loginUser(data) {
    dispatch(AuthActions.loginUserRequest(data));
  },
  userLoggedIn(data) {
    dispatch(AuthActions.loginUserSuccess(data));
  },
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(
  mapState,
  mapDispatch
)(LoginForm);
