import { createAction } from '../../helpers';

export const SIGNUP = {
  CITY: 'SIGNUP_CITY',
  STEP: 'SIGNUP_STEP',
  WAVE: 'SIGNUP_WAVE',
  GROUPNAME: 'SIGNUP_GROUPNAME',
  DISTANCE: 'SIGNUP_DISTANCE',
  TYPE: 'SIGNUP_TYPE',
  AGE: 'SIGNUP_AGE',
  PLACES: 'SIGNUP_PLACES',
  CODE_REQUEST: 'CODE_REQUEST',
  CODE_SUCCESS: 'CODE_SUCCESS',
  CODE_FAILED: 'CODE_FAILURE',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILED: 'SIGNUP_FAILURE'
};

export const SignupActions = {
  step: step => createAction(SIGNUP.STEP, { step }),
  city: city => createAction(SIGNUP.CITY, { city }),
  wave: wave => createAction(SIGNUP.WAVE, { wave }),
  groupname: groupName => createAction(SIGNUP.GROUPNAME, { groupName }),
  distance: distance => createAction(SIGNUP.DISTANCE, { distance }),
  type: type => createAction(SIGNUP.TYPE, { type }),
  age: age => createAction(SIGNUP.AGE, { age }),
  places: places => createAction(SIGNUP.PLACES, { places }),
  codeRequest: payload => createAction(SIGNUP.CODE_REQUEST, payload),
  codeSuccess: payload => createAction(SIGNUP.CODE_SUCCESS, payload),
  codeFailed: payload => createAction(SIGNUP.CODE_FAILED, payload),
  signupRequest: payload => createAction(SIGNUP.SIGNUP_REQUEST, payload),
  signupSuccess: payload => createAction(SIGNUP.SIGNUP_SUCCESS, payload),
  signupFailed: payload => createAction(SIGNUP.SIGNUP_FAILED, payload)
};
