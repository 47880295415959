import React from 'react';
import { ModalFooter, ModalPortal, Modal, ModalContent } from '../Modals';
import { ButtonGroup, Button } from '../Button';
import Input from '../Form/Input';

class GroupChallengeDialog extends React.Component {
  state = {
    email: ''
  };

  onEmailChange = ev => {
    const email = ev.target.value;
    this.setState({ email });
  };

  onSubmitDialog = e => {
    e.preventDefault();
    if (this.state.email.length) {
      this.props.onSubmitDialog(this.state.email);
      this.setState({ email: '' });
    }
  };

  render() {
    return (
      <ModalPortal>
        <Modal title="Rzuć wyzwanie" closeModal={this.props.onHideDialog} width="900px">
          <form onSubmit={this.onSubmitDialog}>
            <ModalContent>
              <p>
                Wyzwanie to zaproszenie do grupy na <strong>MIEJSCE NIEOPŁACONE</strong>. Dzięki niemu możesz powiększyć
                liczbę jej uczestników.
              </p>
              <Input
                type="text"
                placeholder="Wprowadź adres email"
                value={this.state.email}
                onChange={this.onEmailChange}
              />
            </ModalContent>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={this.props.onHideDialog}>Anuluj</Button>
                <Button primary type="submit" disabled={!this.state.email} onClick={this.onSubmitDialog}>
                  Wyślij wyzwanie
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </Modal>
      </ModalPortal>
    );
  }
}

export default GroupChallengeDialog;
