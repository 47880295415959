import React from 'react';
import { Link } from 'react-router-dom';

import CardText from 'material-ui/Card/CardText';

import { Button } from '../Button/Button';
import { ButtonGroup } from '../Button';
import { SignupsTable } from './SignupsTable';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { getUserProfile } from 'store/user-profile/selectors';

const CardTitle = styled.h2`
  color: #ffffff;
  font-weight: 700;
  font-family: 'Lordcorps', cursive;
  letter-spacing: 0.03rem;
  padding: 0 16px;
`;

const InfoSignups: React.FC = () => {
  const user = useSelector(getUserProfile);

  return (
    <div className="signup-card-container">
      <CardTitle>Twoje Grupy / Zapisy</CardTitle>

      {user.signups.length ? (
        <SignupsTable signups={user.signups} />
      ) : (
        <CardText>
          <p style={{ color: '#FFFFFF' }}>Nie masz wykupionego żadnego miejsca.</p>
          <ButtonGroup>
            <Link to="/">
              <Button primary>Przejdź do zapisów</Button>
            </Link>
            <Link to="/zapisz-do-grupy">
              <Button>Dołącz do istniejącej grupy</Button>
            </Link>
          </ButtonGroup>
        </CardText>
      )}
    </div>
  );
};

export default InfoSignups;
