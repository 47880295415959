import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { profileReducer, IProfileState } from './user-profile/reducer';
import { eventReducer } from './events/reducer';
import { notificationReducer } from './ui/notification/reducer';
import { signupReducer } from './ui/signup/reducer';
import requestsReducer, { IRequestsState } from './request/reducer';
import { appReducer, IAppState } from './app/reducer';
import { groupReducer, IGroupState } from './groups/reducer';
import { INotificationsState } from './ui/notification/interfaces';
import { ISignupState } from './ui/signup/interfaces';
import { IEventsState } from './events/interfaces';
import { IGlobalModalsState } from './ui/global-modals/interfaces';
import { globalModalsReducer } from './ui/global-modals/reducer';

export interface IStoreStateInterface {
  router: RouterState;
  profile: IProfileState;
  event: IEventsState;
  requests: IRequestsState;
  app: IAppState;
  groups: IGroupState;
  ui: {
    notifications: INotificationsState;
    signup: ISignupState;
    globalModals: IGlobalModalsState;
  };
}

export const createReducers = history =>
  combineReducers({
    router: connectRouter(history),
    profile: profileReducer,
    event: eventReducer,
    requests: requestsReducer,
    app: appReducer,
    groups: groupReducer,
    ui: combineReducers({
      notifications: notificationReducer,
      signup: signupReducer,
      globalModals: globalModalsReducer
    })
  });
