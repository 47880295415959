import 'react-app-polyfill/ie9';
import 'core-js/stable/array';
import 'core-js/stable/string';
import {} from '@emotion/react';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';
import { init } from '@sentry/browser';

import store from './store';
import { history } from './browser-history';
import Application from './App';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import './tailwind.css';
import './styles/animate.css';
import './styles/main.css';
import MessagesList from 'components/shared/MessagesList';
import GlobalStyles from './GlobalStyles';
import storeMetrics from '@livechat/store-metrics';

try {
  storeMetrics();
} catch (e) {}

init({
  dsn: 'https://fc3ab221a0154ea2951421dd25ae0dfa@sentry.io/296020'
});

const muiTheme = getMuiTheme({
  fontFamily: "'Video', 'Helvetica', 'Arial'"
});

render(
  <Provider store={store}>
    <Router history={history}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <>
          <GlobalStyles />
          <Application />
          <MessagesList />
        </>
      </MuiThemeProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);
