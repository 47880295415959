import styled from '@emotion/styled';

const InputGroup = styled.div<{ column?: boolean }>`
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${props =>
    props.column
      ? `
      flex-direction: column;
  `
      : ''};
`;

export default InputGroup;
