import React, { useState } from 'react';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';

import { AuthActions } from 'store/app/actions';
import { IStoreStateInterface } from 'store/reducers';
import { Button } from 'components/Button';
import { Api } from 'services';
import { MessageType } from 'store/ui/notification/interfaces';
import { NotificationActions } from 'store/ui/notification/actions';
import GoogleAuthService from 'services/googleAuthService';
import styled from '@emotion/styled';

interface IStateProps {}

interface IDispatchProps {
  userLoggedIn(data): void;
  showToast(message: string, severity: MessageType): void;
}

interface IProps extends IStateProps, IDispatchProps {}

const GoogleLoginButton = styled(Button)`
  background: #fff;
  color: #333;
  border: 1px solid #fff;
  width: 100%;
`;

const LoginWithGoogle: React.FC<IProps> = ({ userLoggedIn, showToast }) => {
  const [disabled, setDisabled] = useState(false);

  const handleGoogleLogin = React.useCallback(() => {
    const initAuth = async () => {
      setDisabled(true);
      const auth = new GoogleAuthService();
      try {
        const authorization = await auth.init();
        const data: any = await auth.signIn(authorization);
        const { result, error } = (await Api.authApi.authorizeForSocial('google', data.access_token)) as any;

        setDisabled(false);

        if (result) {
          userLoggedIn(result.data);
        }

        if (error) {
          if (error.message && error.message.content) {
            showToast(error.message.content, MessageType.Error);
            return;
          }

          showToast('Wystąpił błąd. Spróbuj ponownie później', MessageType.Error);
        }
      } catch (e) {
        setDisabled(false);
        console.error(e);
      }
    };

    initAuth();
  }, [showToast, userLoggedIn]);

  return (
    <GoogleLoginButton disabled={disabled} onClick={handleGoogleLogin}>
      Zaloguj przez Google
    </GoogleLoginButton>
  );
};

const mapState = (state: IStoreStateInterface): IStateProps => ({});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  userLoggedIn(data) {
    dispatch(AuthActions.loginUserSuccess({ ...data, source: 'google' }));
  },
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(mapState, mapDispatch)(LoginWithGoogle);
