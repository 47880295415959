import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const LoaderCircle = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 50%;
  animation: ${rotate} 1s infinite linear;
  border: 2px solid rgba(255, 255, 255, 1);
  border-top-color: #f60;
`;
