import 'unfetch/polyfill';
import { stringifyQueryParams, IKeyValue } from 'helpers/url';
import Debug from 'debug';
import { getCookie } from 'components/helpers/cookies';
import { INotificationPayload } from 'store/ui/notification/interfaces';

const debug = Debug('api-client');

export interface IKeyAny<T = any> {
  [key: string]: T;
}

export interface IRequestResult<TResult> {
  result: TResult;
  error?: any;
  message?: INotificationPayload;
}

export interface IApiClient {
  updateAccessToken?: (accessToken: string) => void;
  getCurrentUserLogin?(): string;
  get: <TResult>(path: string, params?: IKeyValue, headers?: IKeyValue) => Promise<IRequestResult<TResult>>;
  post: <TResult>(path: string, body: any, headers?: IKeyValue) => Promise<IRequestResult<TResult>>;
  put: <TResult>(path: string, body: any, headers?: IKeyValue) => Promise<IRequestResult<TResult>>;
  delete: <TResult>(path: string, body: any, headers?: IKeyValue) => Promise<IRequestResult<TResult>>;
}

enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Delete = 'DELETE'
}

interface IRequestOptions {
  path: string;
  method: HttpMethod;
  body?: IKeyValue;
  headers?: IKeyValue;
  params?: IKeyValue;
}

export default class ApiClient implements IApiClient {
  private getBaseRootPath(): string {
    return process.env.REACT_APP_API_URL;
  }

  private getCombinedHeaders(skipContentType: boolean) {
    if (skipContentType) {
      return {};
    }

    return {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
  }

  private async request<TResult>(options: IRequestOptions): Promise<IRequestResult<TResult>> {
    const bearer = {
      Authorization: `Bearer ${getCookie('access_token')}`
    };
    let fullPath = options.path.startsWith('http') ? options.path : `${this.getBaseRootPath()}${options.path}`;

    if (options.params) {
      fullPath += `?${stringifyQueryParams(options.params)}`;
    }

    const requestResult = {
      result: null,
      error: null
    };

    try {
      const isFormData = options.body instanceof FormData;

      const fetchOptions = {
        headers: {
          ...this.getCombinedHeaders(isFormData),
          ...options.headers,
          ...bearer
        },
        method: options.method,
        body: null
      };

      if (options.body) {
        fetchOptions.body = isFormData ? options.body : JSON.stringify(options.body);
      }

      debug(`===>>> ${options.method} ${fullPath}`);

      const response = await fetch(fullPath, fetchOptions);
      const json = await response.json();

      if (!response.ok) {
        requestResult.error = json;
      } else {
        requestResult.result = json;
      }

      debug(`<<<=== ${options.method} ${fullPath} (${response.status}): ${response.statusText}`);
    } catch (e) {
      debug(`<<<=== ${options.method} ${fullPath} (5XX): ${e.message}`);
      requestResult.error = e;
    }

    return requestResult;
  }

  async get<TResult>(path: string, params?: IKeyValue, headers?: IKeyValue): Promise<IRequestResult<TResult>> {
    return this.request<TResult>({ path, params, headers, method: HttpMethod.Get });
  }

  async post<TResult>(path: string, body: IKeyValue, headers?: IKeyValue): Promise<IRequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Post });
  }

  async put<TResult>(path: string, body: IKeyValue, headers?: IKeyValue): Promise<IRequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Put });
  }

  async delete<TResult>(path: string, body: IKeyValue, headers?: IKeyValue): Promise<IRequestResult<TResult>> {
    return this.request<TResult>({ path, body, headers, method: HttpMethod.Delete });
  }
}
