import { BaseApi } from '../base';

interface ICreateGroupBody {
  wave_id: number;
  group_name: string;
  participants: number;
  coupons?: string[];
}

interface IAPIResponse<T> {
  data: T;
  error: string;
}

interface IGroupResponse {
  category_id: number;
  category_name: string;
  city_name: string;
  created: string;
  distance: number;
  event_date: string;
  event_name: string;
  id: number;
  is_kids: number;
  name: string;
  signups: any[];
  verifyCode: string;
  wave_id: number;
  wave_time: string;
}

interface IPaymentResult {
  payment_id: string;
  payment_url: string;
  price: number;
  single_price: number;
  quantity: number;
  run_name: string;
  group_id: number;
  event_category_id: number;
}
export default class SignupApi extends BaseApi {
  createGroup = (body: ICreateGroupBody) => this.post(`group-signup`, body);
  getGroupInfo = (groupId: number) => this.get<IAPIResponse<IGroupResponse>>(`groups/${groupId}`);
  deleteGroup = (groupId: number) => this.post(`groups/${groupId}/delete`);

  changeGroupName = (groupId, groupname) => this.post(`groups/${groupId}/name`, { group_name: groupname });
  changeGroupWave = (groupId, waveId) => this.post(`groups/${groupId}/wave`, { wave_id: waveId });
  changeGroupCaptain = (groupId, new_captain_id) => this.post(`groups/${groupId}/captain`, { new_captain_id });
  deleteSignup = (groupId, participant_id) => this.post(`groups/${groupId}/participant/remove`, { participant_id });

  checkCoupon = (coupon, eventId) => this.post(`payment/validate-coupon`, { coupon, event_id: eventId });
  groupPayment = paymentId => this.get(`payment/${paymentId}/url`);
  paymentDetails = paymentId => this.get<IAPIResponse<IPaymentResult>>(`payment/${paymentId}/details`);
  getPendingPayments = (group_id: number) => this.get(`payment/pending`, { group_id });

  sendInvite = (group_id, email) => this.post(`invitations/join`, { group_id, email });
  sendInviteToPay = (group_id, email) => this.post(`invitations/register`, { group_id, email });
  sendInviteToCreate = email => this.post('addition/invite', { email });

  addNewParticipants = (group, participants, coupons = []) =>
    this.post(`groups/${group}/payToJoin`, { participants, coupons });

  userVerifyTransfer = (email: string, participation_id) =>
    this.post('transfer/user/verify', { email, participation_id });
  userPerformTransfer = (email: string, participation_id) =>
    this.post('transfer/user/perform', { email, participation_id });
  groupVerifyTransfer = (group_id, participation_id) =>
    this.post('transfer/group/verify', { group_id, participation_id });
  groupPerformTransfer = (group_id, participation_id) =>
    this.post('transfer/group/perform', { group_id, participation_id });

  getSinglePrice = (event_category_id, participants) =>
    this.get('payment/single-price', { event_category_id, participants });
}
