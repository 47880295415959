/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import Divider from 'material-ui/Divider';
import FontIcon from 'material-ui/FontIcon';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';
import { connect } from 'react-redux';
import { ProfileActions } from '../../store/user-profile/actions';
import { NotificationActions } from '../../store/ui/notification/actions';
import { IProfileState } from 'store/user-profile/reducer';
import { MessageType } from 'store/ui/notification/interfaces';
import styled from '@emotion/styled';
import Avatar from './Avatar';
import { GlobalModalActions } from 'store/ui/global-modals/actions';
import { GlobalModal } from 'enums/global-modals';
import { css } from '@emotion/react';
import { getUserProfile } from 'store/user-profile/selectors';

const SectionTitle = css`
  padding: 0 16px;
  margin-bottom: 8px;
`;

const StyledProfile = styled.ul`
  color: #fff;
  font-size: 14px;
  display: table;
  list-style: none;
  padding-left: 0;
  width: 100%;
  padding: 0 16px;

  span {
    cursor: pointer;
    color: tomato;
  }

  & > li {
    display: table-row;
  }

  & > li > * {
    display: table-cell;
    padding: 5px;
  }

  & label {
    font-weight: bold;
    text-align: right;
    color: #f60;
    text-transform: uppercase;
    font-size: 13px;
  }

  & label:after {
    content: ':';
  }
`;

const FillUserDataBanner = styled.div`
  margin: 0 16px;
  background-color: #dc2625;
  color: #fff;
  padding: 8px 16px;
  text-align: center !important;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px;
`;

interface IProps {
  user: IProfileState;
  onEditProfile(data, onSuccess): void;
  onLogout(): void;
  onDisplayNotification(error: string): void;
  onFetchUser(): void;
  onOpenEditProfileModal(): void;
}

const UserProfileSidebar: React.FC<IProps> = ({
  user,
  onFetchUser,
  onDisplayNotification,
  onOpenEditProfileModal,
  onLogout
}) => {
  React.useEffect(() => {
    onFetchUser();
  }, [onFetchUser]);

  return (
    <div className="signup-card-container profile-info-card info-index">
      <h2 css={SectionTitle}>Twoje dane</h2>

      <Avatar user={user} onFetchUser={onFetchUser} onDisplayError={onDisplayNotification} />

      <StyledProfile>
        <li>
          <label>Imię</label>
          <div>{user.name}</div>
        </li>
        <li>
          <label>Nazwisko</label>
          <div>{user.surname}</div>
        </li>
        <li>
          <label>Email</label>
          <div>{user.email}</div>
        </li>
        <li>
          <label>Telefon</label>
          <div>{user.phone}</div>
        </li>
        <li>
          <label>Koszulka</label>
          <div>{user.sex && user.size ? `${user.size}, ${user.sex === 'M' ? 'Męska' : 'Damska'}` : 'Nie wybrano'}</div>
        </li>
        {user.birthdate && (
          <li>
            <label>Urodziny</label>
            <div>{user.birthdate.toLocaleDateString()}</div>
          </li>
        )}
      </StyledProfile>
      {!user.phone || !user.birthdate ? (
        <FillUserDataBanner onClick={onOpenEditProfileModal}>Uzupełnij wszystkie dane w profilu</FillUserDataBanner>
      ) : null}

      <List className="mesr-list-options">
        <h3 css={SectionTitle}>Zarządzaj kontem</h3>

        <ListItem
          primaryText="Edytuj dane"
          leftIcon={<FontIcon className="material-icons">mode_edit</FontIcon>}
          onClick={onOpenEditProfileModal}
        />
        <Divider />
        <ListItem
          primaryText="Wyloguj się"
          leftIcon={<FontIcon className="material-icons">power_settings_new</FontIcon>}
          onClick={onLogout}
        />
      </List>
    </div>
  );
};

const mapState = state => ({
  user: getUserProfile(state)
});

const mapDispatch = dispatch => ({
  onOpenEditProfileModal: () => dispatch(GlobalModalActions.showModal(GlobalModal.EditProfileModal)),
  onFetchUser: () => dispatch(ProfileActions.fetchProfileRequest()),
  onLogout: () => dispatch(ProfileActions.logout()),
  onDisplayNotification: content =>
    dispatch(
      NotificationActions.create({
        type: MessageType.Error,
        content
      })
    )
});

export default connect(mapState, mapDispatch)(UserProfileSidebar);
