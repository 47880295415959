import { GlobalModal } from 'enums/global-modals';
import { IGlobalModalsState, IOpenedGlobalModal } from './interfaces';

interface IStateWithGlobalModals {
  ui: {
    globalModals: IGlobalModalsState;
  };
}

export function getGlobalModals(state: IStateWithGlobalModals): IGlobalModalsState {
  return state.ui.globalModals;
}

export function getOpenedGlobalModal(state: IStateWithGlobalModals): IOpenedGlobalModal {
  const modals = getGlobalModals(state);

  const openedModal = Object.keys(modals).find((modalName): boolean => modals[modalName].displayed) as GlobalModal;

  if (openedModal) {
    return {
      modalType: openedModal,
      ...modals[openedModal]
    };
  }

  return null;
}
