import { EventActionsEnum } from './actions';
import { IEventsState } from './interfaces';

const initialState: IEventsState = {
  events: [],
  cities: [],
  waves: [],
  categories: []
};

export function eventReducer(state = initialState, action) {
  switch (action.type) {
    case EventActionsEnum.FETCH_DATA_SUCCESS: {
      const {
        payload: { events, cities, categories }
      } = action;

      return {
        ...state,
        events,
        cities,
        categories
      };
    }

    case EventActionsEnum.FETCH_WAVES_SUCCESS: {
      const {
        payload: { waves }
      } = action;

      return {
        ...state,
        waves
      };
    }

    default:
      return state;
  }
}
