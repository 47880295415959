import { IGlobalModalsState } from './interfaces';
import { GlobalModalActionsEnum, GlobalModalActionTypes } from './actions';

const initialState: IGlobalModalsState = {};

export function globalModalsReducer(
  state: IGlobalModalsState = initialState,
  action: GlobalModalActionTypes
): IGlobalModalsState {
  switch (action.type) {
    case GlobalModalActionsEnum.SHOW_GLOBAL_MODAL:
      return {
        ...state,
        [action.payload.modal]: {
          payload: action.payload.payload,
          displayed: true
        }
      };

    case GlobalModalActionsEnum.HIDE_GLOBAL_MODAL:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          displayed: false
        }
      };

    default:
      return state;
  }
}
