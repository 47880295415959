/** @jsxRuntime classic */

/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import UserProfileSidebar from './UserProfileSidebar';
import InfoSignups from './UserSignups';

import { gridWrapper, mainContentWrapperStyles, userProfileWraperStyles } from 'components/grid/AppGridComponents';
import { useTrackPageview } from 'hooks/event-tracking';

const UserProfile: React.FC = () => {
  useTrackPageview('Twój profil', window.location.pathname);

  return (
    <section css={gridWrapper} className="info-index">
      <div css={userProfileWraperStyles}>
        <UserProfileSidebar />
      </div>
      <div css={mainContentWrapperStyles}>
        <InfoSignups />
      </div>
    </section>
  );
};

export default UserProfile;
