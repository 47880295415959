/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { IUserSignup } from 'store/user-profile/reducer';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';

interface IOwnProps {
  signup: IUserSignup;
  order: number;
}

interface IDispatchProps {
  onRowClicked(): void;
}

interface IProps extends IOwnProps, IDispatchProps {}

const cardManage = css`
  grid-area: event-manage;
  border: 1px solid #ed702d;
  font-size: 12px;
  padding: 4px 8px;
  color: #ed702d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background: transparent;

  margin-top: 15px;
  margin-right: 15px;

  @media (max-width: 800px) {
    display: none;
  }
`;

const cardWrapper = css`
  color: #fff;
  background: rgba(37, 37, 37, 0.42);
  border: 1px solid #ed702d;
  display: grid;
  grid-template-rows: 50px 1fr 1fr 50px;
  gap: 0px 0px;
  grid-template-areas:
    'event-name event-name event-name event-manage'
    'event-category event-category event-category .'
    'event-city event-city event-date event-date'
    'event-warning event-warning event-warning event-warning';

  min-height: 120px;

  svg {
    margin-right: 4px;
  }

  @media (max-width: 800px) {
    grid-template-areas:
      'event-name'
      'event-category'
      'event-city'
      'event-date'
      'event-manage'
      'event-warning';
    grid-template-rows: 40px 1fr 1fr 30px;
  }

  &:hover {
    cursor: pointer;

    button {
      background: #ed702d;
      color: #fff;
    }
  }
`;

const EventName = styled.div`
  grid-area: event-name;
  font-size: 14px;
  align-items: flex-end;
  display: flex;
  margin-left: 30px;
  margin-bottom: 4px;
  text-shadow: 2px 2px 10px #000;
`;

const EventCategory = styled.div`
  grid-area: event-category;
  color: #ed702d;
  font-family: 'Lordcorps', 'sans-serif';
  font-size: 30px;
  line-height: 1;
  margin-left: 30px;
  margin-bottom: 8px;
  text-shadow: 2px 2px 10px #000;
`;

const EventWarning = styled.div`
  grid-area: event-warning;
  background: #eb5757;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 0 15px;
  cursor: pointer;
  text-shadow: 2px 2px 10px #000;
`;

const EventCity = styled.div`
  grid-area: event-city;
  margin-left: 30px;
`;

const EventDate = styled.div`
  grid-area: event-date;
  @media (max-width: 800px) {
    margin-left: 30px;
  }
`;

const SignupCard: React.FC<IProps> = ({ signup, order, onRowClicked }) => {
  return (
    <div css={cardWrapper} onClick={onRowClicked}>
      <button css={cardManage}>Zarządzaj</button>
      <EventName>{signup.event_name}</EventName>
      <EventCategory>
        {signup.category_name} - {signup.distance} KM
      </EventCategory>
      <EventCity>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width="15px"
          height="15px"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        {signup.city}
      </EventCity>
      <EventDate>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width="15px"
          height="15px"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        {signup.event_date}, {signup.wave_time}
      </EventDate>

      {!signup.status && (
        <EventWarning>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="15px">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>
            Rezerwacja niepotwierdzona. <u>Opłać teraz!</u>
          </span>
        </EventWarning>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: IOwnProps): IDispatchProps => ({
  onRowClicked() {
    dispatch(push(`/grupa/${ownProps.signup.id}`));
  }
});

export default connect(null, mapDispatchToProps)(SignupCard);
