import { eventSaga } from './events/saga';
import { notificationSaga } from './ui/notification/saga';
import { signupSaga } from './ui/signup/saga';
import { profileSaga } from './user-profile/saga';
import { appReadySaga, appSagas } from './app/saga';
import { groupSaga } from './groups/saga';

export function runAppSagas(sagaMiddleware) {
  sagaMiddleware.run(eventSaga);
  sagaMiddleware.run(notificationSaga);
  sagaMiddleware.run(signupSaga);
  sagaMiddleware.run(profileSaga);
  sagaMiddleware.run(appReadySaga);
  sagaMiddleware.run(groupSaga);
  sagaMiddleware.run(appSagas)
}
