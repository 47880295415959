import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import { NotificationActions } from '../../store/ui/notification/actions';
import { INotification, MessageType } from 'store/ui/notification/interfaces';
import { Dispatch, AnyAction } from 'redux';
import { IStoreStateInterface } from 'store/reducers';

interface IDispatchProps {
  hideNotification(id: string): void;

}

interface IStateProps {
  messages: INotification[];
}

interface IProps extends IStateProps, IDispatchProps {}

class MessagesList extends React.Component<IProps> {
  renderMessage = (message: INotification) => {
    const { content, id, type } = message;
    const { hideNotification } = this.props;

    const classes = classnames({
      'mesr-message animated': true,
      'mesr-message__success': type === MessageType.Success,
      'mesr-message__warning': type === MessageType.Warning,
      'mesr-message__error': type === MessageType.Error,
      'mesr-message__info': type === MessageType.Info,
      'mesr-message__help': type === MessageType.Help
    });

    return (
      <div key={id} className={classes}>
        {content}

        <strong onClick={() => hideNotification(id)}>
          <span className="material-icons">clear</span>
        </strong>
      </div>
    );
  };

  render() {
    const { messages } = this.props;

    const classes = classnames({
      'mesr-messages': true,
      'mesr-messages__visible': !!messages.length
    });

    return <section className={classes}>{messages.map(this.renderMessage)}</section>;
  }
}

const mapState = (state: IStoreStateInterface): IStateProps => ({
  messages: state.ui.notifications.data
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  hideNotification(id: string) {
    dispatch(NotificationActions.dismiss({ id }))
  }
})

export default connect(
  mapState,
  mapDispatch
)(MessagesList);
