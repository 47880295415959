import React from 'react';
import ReactDOM from 'react-dom';

/**
 * This component allows you to render content outside main app component DOM tree
 *
 * @example
 *   <ModalPortal>
 *     <Modal>Hello World!</Modal>
 *   </ModalPortal>
 */
export class ModalPortal extends React.Component {
  modalContainer;
  body;

  constructor(props) {
    super(props);

    this.modalContainer = document.createElement('div');
    this.modalContainer.className = 'modal-portal-container';
  }

  componentDidMount() {
    this.body = document.querySelector('body');
    this.body.appendChild(this.modalContainer);
    this.body.style.setProperty('overflow', 'hidden');
    this.body.style.setProperty('position', 'relative');
  }

  componentWillUnmount() {
    this.body.removeChild(this.modalContainer);
    this.body.style.setProperty('overflow', '');
    this.body.style.setProperty('position', '');
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.modalContainer);
  }
}
