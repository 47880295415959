import React from 'react';
import * as Yup from 'yup';
import { Dispatch, AnyAction } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link, RouteComponentProps } from 'react-router-dom';
import { InputField } from '@livechat/design-system';
import { Api } from 'services';

import { IStoreStateInterface } from 'store/reducers';
import { Button } from 'components/Button';
import { Title, Logo, Centered } from './components/styled';
import { StyledSignupWrapper } from 'routes/signup/SignupWrapper';
import { NotificationActions } from 'store/ui/notification/actions';
import { MessageType } from 'store/ui/notification/interfaces';

import logo from '../../assets/mesr-badge.svg';


const RegisterSchema = Yup.object().shape({
  email: Yup.string()
    .email('Niepoprawny adres email')
    .required('Wymagane'),
  password: Yup.string().required('Wymagane'),
  password_confirmation: Yup.string().required('Wymagane')
});

interface IStateProps {}

interface IDispatchProps {
  showToast(message: string, severity: MessageType): void;
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<{ token: string }> {}

const FormikInput = ({ field, form, ...props }) => {
  return <InputField {...field} {...props} htmlFor={field.name} id={field.name} />;
};

const ResetPassword: React.FC<IProps> = props => {
  return (
    <StyledSignupWrapper>
      <Centered>
        <Logo src={logo} />
      </Centered>
      <Title>Reset hasła</Title>
      <Formik
        validationSchema={RegisterSchema}
        initialValues={{ email: '', password: '', password_confirmation: '' }}
        onSubmit={async (values, actions) => {
          const { email, password, password_confirmation } = values;
          const { token } = props.match.params;
          
          const { result, error } = (await Api.authApi.resetPassword(
            token,
            email,
            password,
            password_confirmation
          )) as any;

          if (result) {
            props.showToast(result.message.content, result.message.type);
            props.history.push('/');
          }

          if (error) {
            props.showToast(error.message.content, error.message.type);
          }

          actions.setSubmitting(false);
        }}
        render={({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <Field
                type="email"
                name="email"
                placeholder="Twój adres email"
                component={FormikInput}
                error={touched.email && errors.email}
              />
              <Field
                type="password"
                name="password"
                placeholder="Nowe hasło"
                component={FormikInput}
                error={touched.password && errors.password}
              />
              <Field
                type="password"
                name="password_confirmation"
                placeholder="Potwierdź nowe hasło"
                component={FormikInput}
                error={touched.password && errors.password}
              />
              <div style={{ margin: '10px 0' }}>
                <Button submit primary fullWidth disabled={isSubmitting}>
                  Resetuj hasło
                </Button>
              </div>
            </Form>
          );
        }}
      />
      <Link to="/logowanie">Powrót</Link>
    </StyledSignupWrapper>
  );
};

const mapState = (state: IStoreStateInterface): IStateProps => ({});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  showToast(content: string, type: MessageType) {
    dispatch(
      NotificationActions.create({
        type,
        content
      })
    );
  }
});

export default connect(
  mapState,
  mapDispatch
)(ResetPassword);
