import styled from '@emotion/styled';

export const Header = styled.h1`
  text-align: center;
  color: #ed702d;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
  padding-top: 36px;
  line-height: 1;
`;

export const Subheader = styled.div`
  text-align: center;
  color: #fff;
  padding-bottom: 10px;
`;
