export interface IImageDimensionsBoundary {
  minWidth?: number;
  minHeight?: number;
  maxWidth: number;
  maxHeight: number;
}

export enum FileUploadErrorType {
  InvalidSize,
  InvalidDimensions,
  InvalidExtension,
  ServerError,
  Unknown
}

export class FileUploadError extends Error {
  type: FileUploadErrorType;

  constructor(message: string, type: FileUploadErrorType) {
    super(message);
    this.type = type;
  }
}

export const loadFileAsDataURL: (file: File) => Promise<string> = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    // https://stackoverflow.com/questions/35789498/new-typescript-1-8-4-build-error-build-property-result-does-not-exist-on-t
    reader.onloadend = (loadedFile: any) => resolve(loadedFile.target.result);
    reader.onerror = () =>
      reject(new FileUploadError('There was an error loading the file', FileUploadErrorType.Unknown));
  });

export function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;

    image.onload = () => {
      resolve(image);
    };

    image.onerror = reject;
  });
}

export const validateImageDimensions: (
  dataUrl: string,
  dimensions: IImageDimensionsBoundary
) => Promise<boolean> = async (dataUrl, dimensions): Promise<boolean> => {
  const { minWidth = 1, minHeight = 1, maxWidth, maxHeight } = dimensions;

  let image;

  try {
    image = await loadImage(dataUrl);
  } catch (e) {
    throw new FileUploadError('There was an error loading the image', FileUploadErrorType.Unknown);
  }

  if (image.width < minWidth || image.height < minHeight) {
    throw new FileUploadError(
      `The uploaded image is too small. Must be at least ${minWidth}px by ${minHeight}px.`,
      FileUploadErrorType.InvalidDimensions
    );
  }

  if (image.width > maxWidth || image.height > maxHeight) {
    throw new FileUploadError(
      `The uploaded image is too large. Must be no more than ${maxWidth}px by ${maxHeight}px.`,
      FileUploadErrorType.InvalidDimensions
    );
  }

  return true;
};

export function humanFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}
