import React from 'react';

import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

class SignupDeleteSelfDialog extends React.Component {
  render() {
    let customActions = [
      <FlatButton
        key="1"
        label="Wróć"
        secondary={true}
        labelStyle={{ color: '#222222' }}
        hoverColor={'#EEE'}
        rippleColor={'#222222'}
        onClick={this.props.onHideDialog}
      />,
      <FlatButton
        key="2"
        label="Zwolnij miejsce"
        labelStyle={{ color: '#FF6600' }}
        hoverColor={'#EEE'}
        rippleColor={'#FF6600'}
        onClick={this.props.onSubmitDialog}
      />
    ];

    return (
      <Dialog
        open={this.props.show}
        ref="dialog"
        title="Opuść grupę"
        actions={customActions}
        bodyStyle={this.props.bodyStyle}
        onRequestClose={this.props.onHideDialog}
      >
        <p style={{ color: 'red' }}>
          Czy na pewno chcesz opuścić grupę? UWAGA! Rezygnujesz w takim przypadku z <strong>opłaconego zapisu</strong>,
          przez co zwolnisz tym samym w grupie miejsce dla innej osoby (Twój opłacony zapis pozostanie w grupie do
          dyspozycji kapitana). Jeśli będziesz chciał zapisać się raz jeszcze{' '}
          <strong>naliczona zostanie nowa opłata</strong>.
        </p>
      </Dialog>
    );
  }
}

export default SignupDeleteSelfDialog;
