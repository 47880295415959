import vars from '../assets/vars';
import styled from '@emotion/styled';

const Input = styled.input<{ error?: boolean }>`
  font-size: 15px;
  padding: 0 8px;
  border: 0 solid ${vars.borderColor};
  width: 100%;
  transition: border 0.2s ${vars.basicBezier};
  box-sizing: border-box;
  color: ${vars.text};
  display: inline-block;
  flex: 7;
  line-height: 38px;

  &[type='checkbox'] {
    flex-grow: 0;
    flex-basis: 30px;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 4px 0px ${vars.primaryColor};
  }

  &::placeholder {
    color: rgba(${vars.text}, 0.4) !important;
    opacity: 0.5;
  }

  ${props =>
    props.error
      ? `border: 1px solid $error-color;
      &:focus {
        border-color: $error-color;
      }`
      : ''};
`;

export default Input;
