export const createRequestErrorSelector = actions => state =>
  actions
    .reduce(
      (acc, action) =>
        state.requests[action] && state.requests[action].error ? [...acc, state.requests[action].error] : acc,
      []
    )
    .join(', ');

export const createRequestFetchingSelector = actions => state =>
  actions.some(action => state.requests[action] && state.requests[action].isFetching);
