import React from 'react';
import styled from '@emotion/styled';
import { WaveCard } from '../../routes/signup/components/WaveCard';
import { Modal, ModalPortal, ModalFooter } from '../Modals';
import { ButtonGroup, Button } from '../Button';
import { IWave } from 'store/events/interfaces';

const WavesWrapper = styled.div<{ small?: boolean }>`
  display: grid;
  grid-template-columns: ${props => !props.small && 'auto'} auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  margin-bottom: 40px;
  padding: 30px;

  @media (max-width: 600px) {
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
`;

interface IProps {
  currentWaveId: number;
  onSubmitDialog(newWaveId: number): void;
  onHideDialog(): void;
  waves: IWave[];
  date: string;
}

const GroupChangeWaveDialog: React.FC<IProps> = ({ currentWaveId, onSubmitDialog, onHideDialog, waves, date }) => {
  const [waveId, setWaveId] = React.useState(currentWaveId);

  const handleChangeGroup = React.useCallback(() => {
    onSubmitDialog(waveId);
  }, [waveId, onSubmitDialog]);

  return (
    <ModalPortal>
      <Modal title="Zmień falę, czyli godzinę startu grupy" closeModal={onHideDialog} width="900px">
        <WavesWrapper>
          {waves &&
            waves.map(wave => (
              <WaveCard
                isSelected={wave.id === waveId}
                handleSelect={wave => setWaveId(wave.id)}
                wave={wave}
                key={wave.id}
                date={date}
              />
            ))}
        </WavesWrapper>

        <ModalFooter sticky>
          <ButtonGroup>
            <Button onClick={onHideDialog}>Anuluj</Button>
            <Button primary type="submit" onClick={handleChangeGroup}>
              Zmień falę
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </ModalPortal>
  );
};

export default GroupChangeWaveDialog;
