import { take, all, put, takeEvery, call } from 'redux-saga/effects';
import { Api } from 'services';
import { setCookie } from 'components/helpers/cookies';
import { AuthActionsEnum, AuthActions, propertiesReady } from './actions';
import { push } from 'connected-react-router';
import { ProfileActions } from '../user-profile/actions';
import { NotificationActions } from 'store/ui/notification/actions';
import { trackEvent } from 'components/helpers/eventTracking';

export function* appReadySaga() {
  yield all([take('EVENTS/FETCH_EVENT_SUCCESS'), take('PROFILE/FETCH_USER_SUCCESS')]);

  const { result, error } = yield call(Api.propertiesApi.fetchProperties);

  if (error) {
    throw new Error('Błąd API');
  }

  yield put(propertiesReady(result.data));
}

function* loginSaga(action) {
  const { email, password } = action.payload;
  const user = yield call(Api.authApi.loginUser, { email, password });

  if (user.result) {
    yield put(AuthActions.loginUserSuccess({ ...user.result.data, source: 'form' }));
  }

  if (user.error) {
    yield put(NotificationActions.create(user.error.message));
    yield put(AuthActions.loginUserFailure(user.error));
  }
}

function* authSaga(data) {
  const { token, expires_in, source } = data.payload;

  yield call(setCookie, 'access_token', token, { days: expires_in / 60 / 24 });

  yield call(trackEvent, 'userLoggedIn', { registrationType: source });
  yield put(ProfileActions.fetchProfileRequest());
  yield put(push('/'));
}

export function* appSagas() {
  yield takeEvery(AuthActionsEnum.LOGIN_USER_REQUEST, loginSaga);
  yield takeEvery(AuthActionsEnum.LOGIN_USER_SUCCESS, authSaga);
}
