import { createRequestFetchingSelector } from '../request/selectors';
import { IStoreStateInterface } from 'store/reducers';

export function getIsFetchingUserProfile(state) {
  return createRequestFetchingSelector(['PROFILE/FETCH_USER'])(state);
}

export function getIsSavingUserProfile(state) {
  return createRequestFetchingSelector(['PROFILE/UPDATE_USER'])(state);
}

export function getTermsConditionsConfirmed(state: IStoreStateInterface) {
  return state.profile.terms_conditions_consent;
}

export function getIsEmailVerified(state: IStoreStateInterface) {
  return state.profile.email_verified;
}

export function getUserProfile(state: IStoreStateInterface) {
  return state.profile;
}
