import { IWave } from "store/events/interfaces";

export enum CouponType {
  Value = 'value',
  Percent = 'percent'
}

export interface ICouponCode {
  name: string;
  value: number;
  coupon_type: CouponType;
}

export interface ISignupState {
  // distance: number;
  event_id: number;
  wave: IWave;
  groupName: string;
  city_id: number;
  event_category_id: number;
  age: string;
  step: number;
  places: number;
  codes: ICouponCode[];
}
