import React from 'react';
import styled from '@emotion/styled';
import ReduxQuerySync from 'redux-query-sync';

import EventTypeSelect from './components/EventTypeSelect';
import CitySelect from './components/CitySelect';
import DistanceSelect from './components/DistanceSelect';
import WaveSelect from './components/WaveSelect';

import GroupMetadata from './components/GroupMetadata';
import SignupSummary from './components/SignupSummary';
import SignupWrapper from './SignupWrapper';
import store from '../../store';

import { Button } from '../../components/Button/Button';
import { ISignupState } from 'store/ui/signup/interfaces';
import { trackEvent } from 'components/helpers/eventTracking';
import { SignupActions } from 'store/ui/signup/actions';

const stepEventMapper = {
  0: 'chooseRunStepDisplayed',
  1: 'chooseCityStepDisplayed',
  2: 'chooseDistanceStepDisplayed',
  3: 'chooseWaveStepDisplayed',
  4: 'chooseSeatsStepDisplayed',
  5: 'Cart Displayed'
};

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    align-items: center;

    & > * {
      margin-bottom: 20px;
    }
  }
`;

const WavesWrapper = styled.div<{ small?: boolean }>`
  display: grid;
  grid-template-columns: ${props => !props.small && 'auto'} auto auto;
  grid-row-gap: 30px;
  grid-column-gap: 30px;

  @media (max-width: 600px) {
    grid-template-columns: auto auto;
    grid-row-gap: 10px;
    grid-column-gap: 10px;
  }
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  height: 130px;
  width: 100%;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: 801px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 0 1px #222, 0 0 0.75rem rgba(0, 0, 0, 0.75);
    backdrop-filter: saturate(180%) blur(10px);
  }

  @media (max-width: 800px) {
    padding: 20px 10px 20px;
    height: 100px;
  }
`;

const ButtonSpacer = styled.div`
  margin-right: 15px;
`;

const defaultButtons = (disabled: boolean, firstStep = false) => (nextStep, previousStep) => (
  <ButtonWrapper>
    {!firstStep && (
      <ButtonSpacer>
        <Button onClick={previousStep}>Wstecz</Button>
      </ButtonSpacer>
    )}

    <Button onClick={nextStep} primary disabled={disabled}>
      Przejdź dalej
    </Button>
  </ButtonWrapper>
);

interface IProps {
  signup: ISignupState;
  distance(distance): void;
  step(step): void;
}

export const SignupSteps: React.FC<IProps> = ({ signup, distance: onSetDistance, step: onSetStep }) => {
  const { step, event_id } = signup;

  React.useEffect(() => {
    const syncQueryParams = ReduxQuerySync({
      store,
      params: {
        step: {
          selector: state => state.ui.signup.step,
          action: step => SignupActions.step(step),
          stringToValue: string => Number.parseInt(string) || 0,
          defaultValue: 0
        },
        city: {
          selector: state => state.ui.signup.city_id,
          action: city => SignupActions.city(city),
          stringToValue: string => Number.parseInt(string) || null
        },
        event: {
          selector: state => state.ui.signup.event_id,
          action: event => SignupActions.type(event),
          stringToValue: string => Number.parseInt(string) || null
        }
      },
      initialTruth: 'location'
    });

    return syncQueryParams;
  });

  React.useEffect(() => {
    if (stepEventMapper[step]) {
      trackEvent(stepEventMapper[step]);
    }
  }, [step]);

  if (step === 0) {
    return (
      <SignupWrapper title="Wybierz swój bieg" buttons={defaultButtons(!event_id, true)}>
        <DescriptionWrapper>
          Chcesz pobiec w klasycznym SR, zmierzyć się z najlepszymi podczas wydarzenia Legend, czy sprawić frajdę
          swojemu dziecku?
        </DescriptionWrapper>

        <StepWrapper>
          <EventTypeSelect />
        </StepWrapper>
      </SignupWrapper>
    );
  }

  if (step === 1) {
    return (
      <SignupWrapper title="Wybierz miasto" buttons={defaultButtons(!signup.city_id)}>
        <DescriptionWrapper>W którym mieście chcesz wystartować?</DescriptionWrapper>
        <WavesWrapper small>
          <CitySelect signup={signup} />
        </WavesWrapper>
      </SignupWrapper>
    );
  }

  if (step === 2) {
    return (
      <SignupWrapper title="Wybierz dystans biegu" buttons={defaultButtons(!signup.event_category_id)}>
        <DescriptionWrapper>
          Na jakim dystansie chcesz spróbować swoich sił? Zwróć uwagę na dni weekendu!
        </DescriptionWrapper>
        <WavesWrapper>
          <DistanceSelect signup={signup} />
        </WavesWrapper>
      </SignupWrapper>
    );
  }

  if (step === 3) {
    return (
      <SignupWrapper title="Wybierz falę" buttons={defaultButtons(!signup.wave)}>
        <DescriptionWrapper>
          Startujemy w falach po 100 osób co 15 min. Po rejestracji możesz zmieniać falę (godzinę startu) w swoim
          profilu. Fale KIDS startują w grupach po 30 dzieciaków.
        </DescriptionWrapper>
        <WavesWrapper>
          <WaveSelect signup={signup} />
        </WavesWrapper>
      </SignupWrapper>
    );
  }

  if (step === 4) {
    return (
      <SignupWrapper title="Wpisz ile miejsc rezerwujesz" buttons={defaultButtons(false)}>
        <DescriptionWrapper>
          Jeśli zapisujesz całą drużynę - automatycznie zostaniesz jej Kapitanem. Zgodnie z cennikiem ceny dla grup
          powyżej 5 osób spadają!
        </DescriptionWrapper>

        <StepWrapper>
          <GroupMetadata signup={signup} />
        </StepWrapper>
      </SignupWrapper>
    );
  }

  return (
    <SignupWrapper title="Podsumowanie">
      <DescriptionWrapper>Po dokonaniu opłaty aktualizacja statusu może potrwać kilka minut.</DescriptionWrapper>
      <SignupSummary signup={signup} />
    </SignupWrapper>
  );
};
