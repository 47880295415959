import React from 'react';
import styled from '@emotion/styled';

interface IProps {
  isOpen: boolean;
}

const StyledContent = styled.div<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 13000;
`;

export const ModalPresenter = ({ isOpen, onClick, children }) => (
  <StyledContent isOpen={isOpen} onClick={onClick}>
    {children}
  </StyledContent>
);
