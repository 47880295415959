import React from 'react';

import { Button, ButtonSize } from '../Button';
import styled from '@emotion/styled';
import { Api } from 'services';
import PaymentModal from 'components/group/PaymentModal';
import { humanizeFutureToNow } from 'components/helpers/date';

const CenterWrapper = styled.div`
  width: 100%;
  padding: 0 10px 20px;
  display: flex;
  font-size: 15px;
  color: #fff;

  @media screen and (max-width: 600px) {
    order: 3;
  }
`;

const TableStyles = styled('table')`
  margin: 0 auto;
  width: 60%;

  td {
    padding-bottom: 8px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
}

`;
export interface IPayment {
  payment_id: number;
  payment_url: string;
  price: number;
  participants: number;
  expires_at: Date;
}

interface IProps {
  groupId: number;
  placesToPay: number;
}

export const ClockCountdown: React.FC<IProps> = ({ placesToPay, groupId }) => {
  const [payments, setPayments] = React.useState({} as IPayment[]);
  const [modalShown, setModalShown] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState(0);
  const [expiresIn, setExpiresIn] = React.useState(humanizeFutureToNow(new Date()));

  React.useEffect(() => {
    async function fetchData() {
      const { result } = (await Api.signupApi.getPendingPayments(groupId)) as any;

      if (result) {
        setPayments(result.data.pending_payments);
      }
    }

    fetchData();
  }, [groupId]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const expirationTime = new Date(payments[0] && payments[0].expires_at);
      setExpiresIn(humanizeFutureToNow(expirationTime));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [payments]);

  const places = placesToPay === 1 ? 'biegacza' : 'biegaczy';
  return (
    <>
      <div className="mesr-group__clock">
        <div className="mesr-group__clock--text">
          Masz nieopłacone rezerwacje! Aktualizacja statusu powinna być natychmiastowa. Jeśli opłaciłeś udział,
          skontaktuj się z nami. Jeśli jeszcze nie opłaciłeś udziału pośpiesz się, bo rezerwacja przepadnie po 48
          godzinach od zapisania.
        </div>

        <div className="mesr-group__innerClock">
          <h2>
            Twoja rezerwacja dla {placesToPay} {places} przepadnie za:
          </h2>
          <strong>
            {Math.max(0, expiresIn.day)}
            <em>dni</em>
          </strong>
          <strong>
            {Math.max(0, expiresIn.hour)}
            <em>godzin</em>
          </strong>
          <strong>
            {Math.max(0, expiresIn.minute)}
            <em>minut</em>
          </strong>
          <strong>
            {Math.max(0, expiresIn.second)}
            <em>sekund</em>
          </strong>
        </div>
        <CenterWrapper>
          <TableStyles>
            <tbody>
              {payments.length > 0 &&
                payments.map(p => (
                  <tr key={p.payment_id}>
                    <td>
                      Płatność nr {p.payment_id} - {p.price} zł
                    </td>
                    <td>
                      <Button
                        size={ButtonSize.Slim}
                        onClick={() => {
                          setSelectedPayment(p.payment_id);
                          setModalShown(true);
                        }}
                      >
                        Zapłać teraz {p.price} zł
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableStyles>
        </CenterWrapper>
      </div>
      {modalShown && <PaymentModal paymentId={selectedPayment} show onHideDialog={() => setModalShown(false)} />}
    </>
  );
};
