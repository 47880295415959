import { put, takeEvery, delay } from 'redux-saga/effects';
import { NotificationActions, NOTIFICATION } from './actions';

function* handleNotification(action) {
  const { payload } = action;
  const id = Math.random().toString(36);

  yield put(
    NotificationActions.display({
      id,
      ...payload
    })
  );

  yield delay(payload.timeout ? payload.timeout : 3500);
  yield put(NotificationActions.dismiss({ id }));
}

export function* notificationSaga() {
  yield takeEvery(NOTIFICATION.CREATE, handleNotification);
}
