import { createAction } from '../helpers';

export enum EventActionsEnum {
  FETCH_DATA_REQUEST = 'EVENTS/FETCH_EVENT_REQUEST',
  FETCH_DATA_SUCCESS = 'EVENTS/FETCH_EVENT_SUCCESS',
  FETCH_DATA_FAILURE = 'EVENTS/FETCH_EVENT_FAILURE',
  FETCH_WAVES_REQUEST = 'EVENTS/FETCH_WAVES_REQUEST',
  FETCH_WAVES_SUCCESS = 'EVENTS/FETCH_WAVES_SUCCESS',
  FETCH_WAVES_FAILURE = 'EVENTS/FETCH_WAVES_FAILURE'
}

export const EventActions = {
  fetchEventDataRequest: () => createAction(EventActionsEnum.FETCH_DATA_REQUEST),
  fetchEventDataSuccess: data => createAction(EventActionsEnum.FETCH_DATA_SUCCESS, data),
  fetchEventDataFailure: data => createAction(EventActionsEnum.FETCH_DATA_FAILURE, data),
  fetchWavesRequest: () => createAction(EventActionsEnum.FETCH_WAVES_REQUEST),
  fetchWavesSuccess: data => createAction(EventActionsEnum.FETCH_WAVES_SUCCESS, data),
  fetchWavesFailure: data => createAction(EventActionsEnum.FETCH_WAVES_FAILURE, data)
};
