import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { SignupActions } from '../../../store/ui/signup/actions';
import { EventCard } from './Selectable';
import { ISignupState } from 'store/ui/signup/interfaces';
import { ICategory } from 'store/events/interfaces';
import { getAvailableEventCategories } from 'store/events/selectors';
import { IStoreStateInterface } from 'store/reducers';
import { Dispatch, AnyAction } from 'redux';

const StyledWrapper = styled.div`
  text-align: center;
`;

interface IOwnProps {
  signup: ISignupState;
}
interface IStateProps {
  event_categories: ICategory[];
}

interface IDispatchProps {
  onSelectDistance(distance: ICategory): void;
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

const DistanceSelect: React.FC<IProps> = ({ onSelectDistance, signup, event_categories }) => (
  <>
    {event_categories.map(item => (
      <StyledWrapper key={item.category_name}>
        <EventCard selected={signup.event_category_id === item.id} onClick={() => onSelectDistance(item)} key={item.id}>
          {item.category_name} / {item.distance} KM
          <small>{item.event_category_date}</small>
        </EventCard>
      </StyledWrapper>
    ))}
  </>
);

const mapState = (state: IStoreStateInterface, ownProps: IOwnProps): IStateProps => ({
  event_categories: getAvailableEventCategories(state, ownProps.signup.event_id, ownProps.signup.city_id)
});

const mapDispatch = (dispatch: Dispatch<AnyAction>): IDispatchProps => ({
  onSelectDistance(category: ICategory) {
    dispatch(SignupActions.distance(category));
  }
});

export default connect(
  mapState,
  mapDispatch
)(DistanceSelect);
